export const CPRS_MAIN_CONTENT = `cprsMainContent {
  cprsMainContents(sort: "id:ASC"){
        data {
          id
          attributes {
            title
            description
          }
        }
    }
}
`;

export const OUR_INTRODUTION = `ourIntrduction {
  ourIntroductions(sort: "id:ASC"){
        data {
          id
          attributes {
            label
            QA_content
            content
            button {
              label
              url
            }
            image {
              data {
                id
                attributes {
                  name
                  url
                }
              }
            }
            sub_image {
              data {
                id
                attributes {
                  name
                  url
                }
              }
            }
          }
        }
    }
}
`;
