export const APPLICATION_PAGES_QUERY = `ApplicationPages {  
  applicationPages{
      data {
        id
        attributes {
          parent_slug
          form_name
          eligibility_criteria
          buttons{
            label
            url
            slug
          }
          Other_content {
            heading
            description
            buttons{
              label
              url
              slug
            }
          }
          header_image {
            data {
                id
                attributes {
                    name
                    url
                }
            }
        }
        approved_trainings
        unapproved_trainings
        }
      }
    }
  }`;
