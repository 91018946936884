import { Component, Vue } from "vue-property-decorator";
import { ApplicationsDashboardInterface } from "@/models/reviewer-home-page/reviewer-home-page.interface";
import { commonService } from "@/providers/services/common-service";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { User } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "reviewer-home-page",
  components: {
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class ReviewerHomePage extends Vue {
  public applicationDashboard: ApplicationsDashboardInterface[] = [];
  public isLoading = true;
  public userDetails: User = {} as User;
  public errorMessage = "";

  public created() {
    this.userDetails = utils.getUserDetails();
    commonService
      .adminDashboard()
      .then((result) => {
        this.isLoading = false;
        delete result["inActive"];
        if (!this.userDetails.roles.includes("REVIEW_CERTIFICATE")) {
          delete result["partTwo"];
          delete result["renewal"];
        }
        if (!this.userDetails.roles.includes("REVIEW_STATE_TO_STATE")) {
          delete result["stateToState"];
        }
        for (const innerProperty in result) {
          result[innerProperty].applicationName = innerProperty;
          this.applicationDashboard.push(result[innerProperty]);
        }
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public filterData(form: string, status: string) {
    let formName = "";
    switch (form) {
      case "partOne":
        formName = "part-one";
        break;
      case "partTwo":
        formName = "part-two";
        break;
      case "renewal":
        formName = "renewal";
        break;
      case "stateToState":
        formName = "state-to-state";
        break;
      default:
        break;
    }
    this.$router.push({
      path: formName,
      query: {
        status: status,
      },
    });
  }
}
