import Vue from "vue";
import VueRouter from "vue-router";
import BaseLayoutComponent from "@/layouts/base-layout/base-layout.component.vue";
import HomePage from "@/layouts/home-page/home-page.vue";
import LoginPageComponent from "@/components/login-page/login-page.component.vue";
import ForgetPageComponenemailverifyt from "@/components/forget-password-emailverify/forget-password-emailverify-component.vue";
import ForgetPageComponent from "@/components/forget-password/forget-password.component.vue";
import UserDasboardComponent from "@/components/user-dasboard/user-dasboard.component.vue";
import UserHomePageComponent from "@/components/user-home-page/user-home-page.component.vue";
import UserExplorePageComponent from "@/components/user-explore-page/user-explore-page.component.vue";
import userTrainingSummaryPage from "@/components/user-training-summary-page/user-training-summary-page.component.vue";
import UserAgencyComponent from "@/components/user-agency-page/user-agency-page.component.vue";
import ApplicationSubmittedComponent from "@/components/application-submitted/application-submitted.component.vue";
import CprsStateToStateApplicationComponent from "@/components/cprs-state-to-state-application/cprs-state-to-state-application.component.vue";
import CprsEmploymentSummariesComponent from "@/components/cprs-employment-summaries/cprs-employment-summaries.component.vue";
import CprsRenewalApplicationComponent from "@/components/cprs-renewal-application/cprs-renewal-application.component.vue";
import CertificationPartOneFormComponent from "@/components/certification-part-one-form/certification-part-one-form.component.vue";
import CertificationPartTwoFormComponent from "@/components/certification-part-two-form/certification-part-two-form.component.vue";
import ReviewerHomePage from "@/components/reviewer-home-page/reviewer-home-page.component.vue";
import ReviewerPartOnePage from "@/components/reviewer-part-one-page/reviewer-part-one-page.component.vue";
import ReviewerPartTwoPage from "@/components/reviewer-part-two-page/reviewer-part-two-page.component.vue";
import ReviewerRenewalPage from "@/components/reviewer-renewal-page/reviewer-renewal-page.component.vue";
import ReviewerCertificationPartOnePreviewForm from "@/components/reviewer-certification-part-one-preview-form/reviewer-certification-part-one-preview-form.component.vue";
import ReviewerCertificationPartTwoPreviewForm from "@/components/reviewer-certification-part-two-preview-form/reviewer-certification-part-two-preview-form.component.vue";
import LetterOfReference from "@/components/letter-of-reference/letter-of-reference.component.vue";
import AboutUsComponent from "@/components/about-us/about-us.component.vue";
import ApplicationPagesComponent from "@/components/application-pages/application-pages.component.vue";
import RenewalEmploymentSummaryForm from "@/components/renewal-employment-summary-form/renewal-employment-summary-form.component.vue";
import ReviewerRenewalPreview from "@/components/reviewer-renewal-preview/reviewer-renewal-preview.component.vue";
import ReviewerStatetoStatePage from "@/components/reviewer-state-to-state-page/reviewer-state-to-state-page.component.vue";
import ReviewerStatetoStatePreview from "@/components/reviewer-state-to-state-preview/reviewer-state-to-state-preview.component.vue";
import UserApplicationsComponent from "@/components/users-application/user-applications.component.vue";
import UserEducationComponent from "@/components/user-continuing-education/user-continuing-education.component.vue";
import UserDocumentLibraryComponent from "@/components/user-document-library/user-document-library.component.vue";
import UserConversationsComponent from "@/components/user-conversations/user-conversations.component.vue";
import AdditionalReferenceFormComponent from "@/components/additional-reference-form/additional-reference-form.component.vue";
import AdditionalSupervisorFormComponent from "@/components/additional-supervisor-form/additional-supervisor-form.component.vue";
import AdditionalRenewalSupervisorFormComponent from "@/components/additional-renewal-supervisor-form/additional-renewal-supervisor-form.component.vue";
import ReviewerPeersPage from "@/components/reviewer-peers-page/reviewer-peers-page.component.vue";
import ReviewerPeersPreview from "@/components/reviewer-peers-preview/reviewer-peers-preview.component.vue";
import ReviewerPeersPreviewApplication from "@/components/reviewer-peers-preview-application/reviewer-peers-preview-application.component.vue";
import EmailVerifyComponent from "@/components/email-verify-popup/email-verify.component.vue";
import ReviewerReportsPage from "@/components/reviewer-reports-page/reviewer-reports-page.component.vue";
import ForgetPasswordVerifyPopup from "@/components/forgetpassword-verify-popup/forget-password-popup-component.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
    component: BaseLayoutComponent,
    children: [
      {
        path: "/home",
        name: "home-page",
        component: HomePage,
      },
      {
        path: "/about-us",
        name: "about-us",
        component: AboutUsComponent,
      },
      {
        path: "/application",
        name: "application-pages",
        component: ApplicationPagesComponent,
      },
      {
        path: "/form/certification-part-one-form",
        name: "certification-part-one-form",
        component: CertificationPartOneFormComponent,
      },
      {
        path: "/form/certification-part-two-form",
        name: "certification-part-two-form",
        component: CertificationPartTwoFormComponent,
      },
      {
        path: "/form/letter-of-reference",
        name: "letter-of-reference",
        component: LetterOfReference,
      },
      {
        path: "/form/employment-summaries",
        name: "cprs-employment-summaries",
        component: CprsEmploymentSummariesComponent,
      },
      {
        path: "/form/renewal-application",
        name: "cprs-renewal-application",
        component: CprsRenewalApplicationComponent,
      },
      {
        path: "/form/renewal-employment-summaries",
        name: "renewal-employment-summary-form",
        component: RenewalEmploymentSummaryForm,
      },
      {
        path: "/form/state-to-state-application",
        name: "cprs-state-to-state-application",
        component: CprsStateToStateApplicationComponent,
      },
      {
        path: "/form/add-additional-reference",
        name: "additional-reference",
        component: AdditionalReferenceFormComponent,
      },
      {
        path: "/form/add-additional-supervisor",
        name: "additional-supervisor",
        component: AdditionalSupervisorFormComponent,
      },
      {
        path: "/form/submitted",
        name: "application-submitted",
        component: ApplicationSubmittedComponent,
      },
      {
        path: "/form/renewal-add-additional-supervisor",
        name: "additional-renewal-supervisor",
        component: AdditionalRenewalSupervisorFormComponent,
      },
    ],
  },
  {
    path: "/login",
    name: "login-page",
    component: LoginPageComponent,
  },
  {
    path: "/forgot-password",
    name: "forget-password",
    component: ForgetPageComponent,
  },
  {
    path: "/form/forgot/email-verify",
    name: "forget-password",
    component: ForgetPageComponenemailverifyt,
  },
  {
    path: "/user-dashboard",
    redirect: "/user-dashboard/home",
    component: UserDasboardComponent,
    children: [
      {
        path: "home",
        name: "user-home-page",
        component: UserHomePageComponent,
      },
      {
        path: "my-application",
        name: "user-application",
        component: UserApplicationsComponent,
      },
      {
        path: "education",
        name: "user-continuing-education",
        component: UserEducationComponent,
      },
      {
        path: "document-library",
        name: "user-document-library",
        component: UserDocumentLibraryComponent,
      },
      {
        path: "conversations",
        name: "user-conversations",
        component: UserConversationsComponent,
      },
      {
        path: "explore",
        name: "user-explore-page",
        component: UserExplorePageComponent,
      },
      {
        path: "training-summary",
        name: "user-training-summary-page",
        component: userTrainingSummaryPage,
      },
      {
        path: "agency",
        name: "user-agency-page",
        component: UserAgencyComponent,
      },
    ],
  },
  {
    path: "/reviewer-dashboard",
    redirect: "/reviewer-dashboard/home",
    component: UserDasboardComponent,
    children: [
      {
        path: "home",
        name: "admin-home-page",
        component: ReviewerHomePage,
      },
      {
        path: "part-one",
        name: "reviewer-part-one-page",
        component: ReviewerPartOnePage,
      },
      {
        path: "part-one/:applicantId",
        name: "reviewer-certification-part-one-preview-form",
        component: ReviewerCertificationPartOnePreviewForm,
      },
      {
        path: "part-two",
        name: "reviewer-part-one-page",
        component: ReviewerPartTwoPage,
      },
      {
        path: "part-two/:applicantId",
        name: "reviewer-certification-part-two-preview-form",
        component: ReviewerCertificationPartTwoPreviewForm,
      },
      {
        path: "renewal",
        name: "reviewer-renewal-page",
        component: ReviewerRenewalPage,
      },
      {
        path: "renewal/:applicantId",
        name: "reviewer-renewal-preview",
        component: ReviewerRenewalPreview,
      },
      {
        path: "state-to-state",
        name: "reviewer-state-to-state-page",
        component: ReviewerStatetoStatePage,
      },
      {
        path: "state-to-state/:applicantId",
        name: "reviewer-state-to-state-preview",
        component: ReviewerStatetoStatePreview,
      },
      {
        path: "peers",
        name: "reviewer-peers-page",
        component: ReviewerPeersPage,
      },
      {
        path: "peers-preview",
        name: "reviewer-peers-preview",
        component: ReviewerPeersPreview,
      },
      {
        path: "peers-preview/:peerId",
        name: "reviewer-peers-preview-application",
        component: ReviewerPeersPreviewApplication,
      },
      {
        path: "reports",
        name: "reviewer-reportd-page",
        component: ReviewerReportsPage,
      },
    ],
  },
  {
    path: "/form/email-verify",
    name: "email-verify",
    component: EmailVerifyComponent,
  },
  {
    path: "/forgot/password-verify",
    name: "forget-possword",
    component: ForgetPasswordVerifyPopup,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
