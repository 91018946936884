var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"scope-of-activty-main-container"},[_c('div',{staticClass:"scope-of-activty-container"},[(!_vm.isPreviewForm)?[_c('div',{staticClass:"card-main-container renewal-card-main-container"},[_vm._l((_vm.applicationData),function(application,index){return _c('div',{key:index,staticClass:"card-lists-container"},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card-img-text-container",on:{"click":function($event){return _vm.showModelPopup(
                  application,
                  index,
                  application.attributes.requirement
                )}}},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"update-icon",attrs:{"src":application.attributes.icon_image.data.attributes.url}})]),_c('div',{staticClass:"card-title-container"},[_c('p',{staticClass:"card-title"},[_vm._v(" "+_vm._s(application.attributes.title)+" ")])])])]),(_vm.filledKeyName.includes(application.attributes.requirement))?_c('div',{staticClass:"tick-wrapper-icon"},[_c('svg',{staticClass:"checkmark",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 52 52"}},[_c('circle',{staticClass:"checkmark__circle",attrs:{"cx":"26","cy":"26","r":"25","fill":"none"}}),_c('path',{staticClass:"checkmark__check",class:_vm.filledKeyName.length ? 'stroke-none' : '',attrs:{"fill":"none","d":"M14.1 27.2l7.1 7.2 16.7-16.8"}})])]):_vm._e()])}),(_vm.isShowErrorMsg)?_c('span',{staticClass:"is-invalid position-change"},[_vm._v("Please read and fill the required fields")]):_vm._e()],2),_c('b-modal',{staticClass:"main-content-scope",attrs:{"id":"active-modal-center","scrollable":"","size":"xl","centered":"","hide-footer":"","title":_vm.applicationData &&
          _vm.applicationData[_vm.activeContentIndex]?.attributes?.title}},[(_vm.popupContentData)?_c('div',{staticClass:"popup-body-content model-popup"},[_c('div',{staticClass:"content-div"},[_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.applicationData[_vm.activeContentIndex]?.attributes?.description
              )}})]),_c('div',{staticClass:"form-div"},[_c('div',{staticClass:"input-container"},_vm._l((_vm.applicationData[
                  _vm.activeContentIndex
                ]?.attributes?.form_fields?.fields?.formInputs),function(formInputData,indexi){return _c('div',{key:indexi,staticClass:"margin",class:[
                  formInputData.length == 2
                    ? 'double-input'
                    : formInputData.length == 3
                    ? 'multi-input'
                    : '',
                  formInputData[0].mainClass,
                ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,class:formInput.fieldClass},[(formInput.label)?_c('div',{class:formInput.label !== null
                        ? 'label-containe'
                        : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label-text",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                        formInput.label &&
                        formInput?.validate?.required === true
                      )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.softRefersh,tag:"component",class:formInput.class,attrs:{"autocomplete":"off","label":formInput.label,"editForm":_vm.editForm,"formType":_vm.formType},model:{value:(_vm.SOAPayloadData[_vm.formName][formInput.name]),callback:function ($$v) {_vm.$set(_vm.SOAPayloadData[_vm.formName], formInput.name, $$v)},expression:"SOAPayloadData[formName][formInput.name]"}},'component',{
                      ...formInput.propBind,
                      ...formInput.validate,
                      disabled: _vm.isDisable,
                    },false)),(formInput.subLabel)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(_vm.$validator.errors.has(formInput?.validate?.name))?_c('span',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors.first(formInput?.validate?.name) : "This field is required"))]):_vm._e()],1)}),0)}),0),_c('div',{staticClass:"button-container"},[_c('div',{staticClass:"right-btn-div"},[_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.nextButton(_vm.formName)}}},[_vm._v(" Next ")])],1)])])]):_vm._e()])]:_vm._e(),(_vm.isPreviewForm)?_vm._l((_vm.applicationData),function(application,index){return _c('div',{key:index,staticClass:"SOA-preview-container"},[_c('div',{staticClass:"content-div"},[_c('h5',{staticClass:"title"},[_vm._v(" "+_vm._s(application.attributes.title)+" ")]),_c('div',{staticClass:"form-div"},_vm._l((application.attributes
                ?.form_fields?.fields?.formInputs),function(formInputData,indexi){return _c('div',{key:indexi,staticClass:"margin",class:[
                formInputData.length == 2
                  ? 'double-input'
                  : formInputData.length == 3
                  ? 'multi-input'
                  : '',
                formInputData[0].mainClass,
              ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,class:formInput.fieldClass},[(formInput.label)?_c('div',{class:formInput.label !== null
                      ? 'label-containe'
                      : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label-text",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                      formInput.label &&
                      formInput?.validate?.required === true
                    )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.softRefersh,tag:"component",class:[formInput.class],attrs:{"autocomplete":"off","label":formInput.label,"editForm":_vm.editForm,"formType":_vm.formType},model:{value:(
                    _vm.SOAPayloadData[
                      application.attributes?.form_fields.parent_slug
                    ][formInput.name]
                  ),callback:function ($$v) {_vm.$set(_vm.SOAPayloadData[
                      application.attributes?.form_fields.parent_slug
                    ], formInput.name, $$v)},expression:"\n                    SOAPayloadData[\n                      application.attributes?.form_fields.parent_slug\n                    ][formInput.name]\n                  "}},'component',{
                    ...formInput.propBind,
                    ...formInput.validate,
                    disabled: _vm.isDisable,
                  },false)),(formInput.subLabel)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(_vm.$validator.errors.has(formInput?.validate?.name))?_c('span',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors.first(formInput?.validate?.name) : "This field is required"))]):_vm._e()],1)}),0)}),0)])])}):_vm._e()],2),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }