import { http } from "@/providers/http";
import { utils } from "@/utils/utils";
export class RenewalFormApi {
  private static INSTANCE = new RenewalFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  public RenewalApplicationForm(fillStatus: string, renewalPayLoadData: any) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const data = {
      fillStatus: renewalPayLoadData.fillStatus,
      data: {
        renewalApplicationInformation:
          renewalPayLoadData.renewalApplicationInformation,
        renewalTrainingInformation:
          renewalPayLoadData.renewalTrainingInformation,
        renewalSupervisorInformation:
          renewalPayLoadData.renewalSupervisorInformation,
        renewalHandbook: renewalPayLoadData.renewalHandbook,
        renewalSignatureSubmission:
          renewalPayLoadData.renewalSignatureSubmission,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const endpoint = "cprs/certificate/renewal";
    // const token = "";
    const headers = http.getTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public getApplicantDetials(email: string) {
    const endpoint = `cprs/training/details`;
    const params = {
      email: email,
    };
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  public uploadFile(file: any) {
    const formdata = new FormData();
    formdata.append("file", file);
    const endpoint = "upload";
    const headers = {
      ...http.getTokenHeaders(),
      "Content-Type": "multipart/form-data",
    };
    return http.post(endpoint, headers, formdata, true).then((response) => {
      return response;
    });
  }

  public getRenewalnApplicationForm(applicationID?: string) {
    const endpoint = `cprs/certificate/renewal`;
    const params = {
      id: applicationID,
    };
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  public updateRenewalForm(renewalPayLoadData: any, id: string) {
    const endpoint = `cprs/certificate/renewal?id=${id}`;
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const data = {
      id: id,
      fillStatus: renewalPayLoadData.fillStatus,
      data: {
        renewalApplicationInformation:
          renewalPayLoadData.renewalApplicationInformation,
        renewalTrainingInformation:
          renewalPayLoadData.renewalTrainingInformation,
        renewalSupervisorInformation:
          renewalPayLoadData.renewalSupervisorInformation,
        renewalHandbook: renewalPayLoadData.renewalHandbook,
        renewalSignatureSubmission:
          renewalPayLoadData.renewalSignatureSubmission,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public updateReviewerRenewalForm(payLoadData: any, id: string) {
    const endpoint = `cprs/certificate/renewal/application`;

    const payload = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        renewalApplicationInformation:
          payLoadData.renewalApplicationInformation,
        renewalTrainingInformation: payLoadData.renewalTrainingInformation,
        renewalSupervisorInformation: payLoadData.renewalSupervisorInformation,
        renewalHandbook: payLoadData.renewalHandbook,
        renewalSignatureSubmission: payLoadData.renewalSignatureSubmission,
        History: {},
        editedComments: {},
      },
      supervisorEmails: payLoadData?.supervisorEmails,
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http
      .put(endpoint, headers, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
  public updatePeerReviewerRenewalForm(payLoadData: any, id: string) {
    const endpoint = `cprs/certificate/renewal/applicant/edit`;

    const payload = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        renewalApplicationInformation:
          payLoadData.renewalApplicationInformation,
        renewalTrainingInformation: payLoadData.renewalTrainingInformation,
        renewalSupervisorInformation: payLoadData.renewalSupervisorInformation,
        renewalHandbook: payLoadData.renewalHandbook,
        renewalSignatureSubmission: payLoadData.renewalSignatureSubmission,
        History: {},
        editedComments: {},
      },
      supervisorEmails: payLoadData?.supervisorEmails,
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }

  public getSupervisorLists(token: string) {
    const endpoint = `cprs/certificate/renewal/employee/summary/list`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.get(endpoint, headers).then((response) => {
      return response.data;
    });
  }

  public addAdditionalSupervisor(additionalSupervisor: any[], token: string) {
    const endpoint = `cprs/certificate/renewal/supervisor`;
    const payLoad = {
      data: additionalSupervisor,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.put(endpoint, headers, payLoad).then((response) => {
      return response.data;
    });
  }

  public informRejectedSupervisor(
    rejectedEmails: string[],
    applicantionId: string
  ) {
    const endpoint = `cprs/certificate/renewal/employee/summary/reject/intimate`;
    const data = {
      emailIds: rejectedEmails,
      applicationId: applicantionId,
    };
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then(
      (response) => {
        return response.data;
      },
      () => Promise.resolve()
    );
  }

  public updateSupervisorStatus(supervisorPayLoadData: any) {
    const endpoint = `cprs/certificate/renewal/employee/summary/status`;
    const data = {
      id: supervisorPayLoadData.id,
      email: supervisorPayLoadData.email,
      status: supervisorPayLoadData.status,
    };
    const headers = http.getTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }
  public employeeRenewalResendEmail(payLoad: any) {
    const endpoint = `cprs/certificate/renewal/employee/summary/email/resend`;
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payLoad).then((response) => {
      return response.data;
    });
  }
}

export const renewalFormApi = RenewalFormApi.instance;
