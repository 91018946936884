import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { MAIN_PAGE_BANNER_QUERY } from "@/graphql/main-page-banner-query";
import { CarousalData, carousalImage } from "@/models/banner/banner.interface";

@Component
export default class BannerComponent extends Vue {
  public carousels: CarousalData[] = [];
  public carousalImg: carousalImage[] = [];
  public carousalTitle = { title: "", subTitle: "" };
  public slideInterval = 5000;

  constructor() {
    super();
  }

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${MAIN_PAGE_BANNER_QUERY}`,
      })
      .then((result) => {
        this.carousels = result.data.banners?.data;
        for (
          let i = 0;
          i < this.carousels[0].attributes.carousel_images.length;
          i++
        ) {
          if (this.carousalImg.length) {
            this.carousalImg.push({
              name: this.carousels[0].attributes.carousel_images[i].image.data
                .attributes.name,
              url: this.carousels[0].attributes.carousel_images[i].image.data
                .attributes.url,
              description:
                this.carousels[0].attributes.carousel_images[i].description,
            });
          } else {
            this.carousalImg = [
              {
                name: this.carousels[0].attributes.carousel_images[i].image.data
                  .attributes.name,
                url: this.carousels[0].attributes.carousel_images[i].image.data
                  .attributes.url,
                description:
                  this.carousels[0].attributes.carousel_images[i].description,
              },
            ];
          }
        }
      });
  }
}
