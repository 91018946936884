export const CPRS_RENEWAL_APPLICATION_FORM_QUERY = `CprsRenewalApplications {  
    cprsRenewalApplications(sort: "id:ASC"){
        data{
            id
            attributes{
                programTitle
                formTitle
                RenewalNavbar{
                    id
                    name
                    slug    
                },
                form_fields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
