import { gql } from "graphql-tag";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { CPRS_STATE_APPLICATION_QUERY } from "@/graphql/cprs-state-to-state-application-query";
import {
  CprsStateApplication,
  FormFieldContents,
  FormNavbar,
} from "@/models/cprs-state-to-state-application/cprs-state-to-state-application.interface";
import DropdownListComponent from "../dropdown-list/dropdown-list.component.vue";
import { stateToStateFormApi } from "@/providers/apis/state-to-state-form";
import { User } from "@/models/common-interface/common.interface";
import EditHistoryComponent from "../edit-history/edit-history.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import { utils } from "@/utils/utils";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "b-state-to-state-preview-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-dropdown": DropdownListComponent,
    "b-form-textarea": InputTextAreaComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    EditHistoryComponent,
    ErrorPopupComponent,
    AppLoaderComponent,
  },
})
export default class StateToStatePreviewFormComponent extends Vue {
  // ----------------------------------------------------------------
  // Properties
  @Prop()
  public stateToStatepayLoadDataAll: any;
  @Prop()
  public previewData: any;
  @Prop()
  public tabSelect!: string;
  @Prop()
  public isReviewerPreviewForm!: string;
  @Prop()
  public progressStatus: any;
  @Prop()
  public isUserApplication!: boolean;
  @Prop()
  public isMigrationForm!: boolean;

  public formData: FormFieldContents[] = [];
  public applicationData: Array<CprsStateApplication> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public limitationList: any;
  public previewPayload: any = {};
  public currentRoute = "";
  public isEditReviewerForm = false;
  public editIndex!: number;
  public disabled = true;
  public checkValidation = false;
  public vaildFile = true;
  public isFileValid = false;
  public isLargeLoading = false;
  public hideDiv: string[] = ["highSchoolNotListed"];
  public checkField: string[] = ["highSchool"];
  public errorMessage = "";
  public notFilesUploadedKeyNames: string[] = [];
  public editApplication = false;
  public userDetails: User = {} as User;
  public isClickSavebtn = "false";
  public isConfirm = false;
  public originalData: any = {};
  public isMobileView = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public isLoading = false;
  @Prop()
  public applicantPreviewForm!: any;

  public async created() {
    this.userDetails = utils.getUserDetails();
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.isLoading = true;
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("stateToStatepayLoadData");
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.previewPayload = { ...payloadStorage };
      this.previewPayload.stateToStateBasicDetails.fullName = `${payloadStorage.stateToStateBasicDetails.firstName} ${payloadStorage.stateToStateBasicDetails.lastName}`;
      this.previewPayload.stateToStateBasicDetails.otherFullName = `${payloadStorage.stateToStateBasicDetails.otherFirstName} ${payloadStorage.stateToStateBasicDetails.otherLastName}`;
      const fullAddress = utils.addressBuilder(
        payloadStorage,
        "stateToStateBasicDetails",
        "default"
      );
      this.previewPayload.stateToStateBasicDetails.fullAddress = fullAddress;
      this.previewPayload.stateToStateCertificationDetails.contactPersonFullName = `${payloadStorage.stateToStateCertificationDetails.contactPersonFirstName} ${payloadStorage.stateToStateCertificationDetails.contactPersonLastName}`;
    } else {
      this.previewPayload = this.previewData;
    }
    this.$apollo
      .query({
        query: gql`query ${CPRS_STATE_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.cprsStateToStateApplications.data;
        this.applicationNavData =
          this.applicationData[0].attributes.state_application_navbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.limitationList = this.formData.length - 1;
        for (let index = 0; index < this.checkField.length; index++) {
          this.fieldUpdate(this.checkField[index], true);
        }
        this.isLoading = false;
      });
    this.originalData = JSON.parse(JSON.stringify(this.previewPayload));
  }

  public fieldHideAndShow(inputName: string) {
    if (!this.isEditReviewerForm && !this.isReviewerPreviewForm) {
      const nameNotVisiable = [
        "firstName",
        "lastName",
        "otherFirstName",
        "otherLastName",
        "addressLine1",
        "addressLine2",
        "state",
        "city",
        "zip",
        "contactPersonFirstName",
        "contactPersonLastName",
      ];
      return nameNotVisiable.includes(inputName);
    } else {
      const nameNotVisiable = !this.isEditReviewerForm
        ? [
            "firstName",
            "lastName",
            "otherFirstName",
            "otherLastName",
            "addressLine1",
            "addressLine2",
            "state",
            "city",
            "zip",
            "contactPersonFirstName",
            "contactPersonLastName",
          ]
        : ["fullName", "otherFullName", "fullAddress", "contactPersonFullName"];
      return nameNotVisiable.includes(inputName);
    }
  }

  public fieldUpdate(groupName = "", initial = false) {
    switch (groupName) {
      case "highSchool":
        if (
          !this.previewPayload.stateToStateCertificationDetails.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed") && !initial) {
            this.hideDiv.push("highSchoolNotListed");
            this.previewPayload.stateToStateCertificationDetails.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
      default:
        break;
    }
  }

  public updatePreview() {
    this.$emit("payloadData", this.previewPayload);
  }

  public editForm(slug: string) {
    this.$emit("applicationSlug", slug);
  }

  public onCilckEdit(index: number) {
    this.editIndex = index;
    this.isEditReviewerForm = true;
    this.disabled = false;
    this.$emit("isEditForm", true);
  }

  public onCancelEdit() {
    this.notFilesUploadedKeyNames = [];
    this.isEditReviewerForm = false;
    this.disabled = true;
    this.editApplication = false;
    if (this.currentRoute.split("/")[2] === "my-application") {
      this.$emit("getPeerUpdatedData", false);
    } else {
      this.$emit("getUpdatedData", true);
      this.$emit("isEditForm", false);
    }
  }

  public onCilckSave() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    this.$validator.validate().then(async (validate) => {
      if (validate && this.vaildFile && !this.notFilesUploadedKeyNames.length) {
        if (
          this.previewPayload.stateToStateBasicDetails.email ===
          this.previewPayload.stateToStateCertificationDetails
            .contactPersonEmail
        ) {
          this.errorMessage =
            "Applicant email id and contact person email id must be different *";
          return;
        }
        if (this.currentRoute.split("/")[2] === "my-application") {
          const id = this.applicantPreviewForm.id;
          this.isLargeLoading = true;
          stateToStateFormApi
            .updateReviewerStateToStateForm(this.previewPayload, id)
            .then(() => {
              this.isLargeLoading = false;
              this.isEditReviewerForm = false;
              const data = {
                isUpdateData: true,
                isUpdateEmail: false,
              };
              this.$emit("getPeerUpdatedData", data);
              this.$emit("onClickEdit", this.isEditReviewerForm);
            })
            .catch((error: any) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLargeLoading = false;
            });
        } else {
          this.editApplication = true;
          this.isClickSavebtn = this.changeStateForFieldUpdate(
            this.isClickSavebtn
          );
        }
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  @Watch("checkFormValidation")
  public validation() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    this.$validator.validateAll().then((validate: any) => {
      if (validate && this.vaildFile && !this.notFilesUploadedKeyNames.length) {
        this.$emit("isValidForm", true);
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
        this.$emit("isValidForm", false);
      }
    });
  }

  public isRevertedChanges(event: boolean) {
    if (event === true) {
      this.$emit("getUpdatedData", true);
      this.$emit("isEditForm", false);
    }
  }

  public editedPreviewPayloadData(event: any) {
    if (event) {
      this.previewPayload = event;
    }
  }

  public isConfirmChanges(event: boolean) {
    this.isConfirm = event;
    if (this.isConfirm) {
      const id = this.$route.path.split("/")[3];
      this.isLargeLoading = true;
      this.checkValidation = false;
      this.previewPayload.stateToStateBasicDetails.fullName = "";
      this.previewPayload.stateToStateBasicDetails.fullAddress = "";
      stateToStateFormApi
        .updateReviewerStateToStateForm(this.previewPayload, id)
        .then(() => {
          this.isLargeLoading = false;
          this.isEditReviewerForm = false;
          this.disabled = true;
          this.$emit("getUpdatedData", true);
          this.$emit("isEditForm", false);
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLargeLoading = false;
          this.isLoading = false;
        });
    }
  }

  public fileValid(event: any) {
    this.vaildFile = event;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state" || fieldName === "currentState") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
