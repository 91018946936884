import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { filters } from "@/models/common-interface/common.interface";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import { utils } from "@/utils/utils";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "user-document-library",
  components: {
    PreviewPopupComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class UserDocumentLibraryComponent extends Vue {
  public tableFields = [
    {
      key: "originalFileName",
      label: "Document Name",
      sortable: true,
    },
    {
      key: "type",
      label: "Type",
      sortable: true,
      thClass: "application_id",
      tdClass: "type",
    },
    {
      key: "date",
      label: "Date",
      sortable: true,
      thClass: "application_id",
    },
    {
      key: "application",
      label: "Application Name",
      sortable: false,
      thClass: "application_id pointer-events",
    },
  ];
  public currentPage = 1;
  public previewFile: any[] = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public showPreviewPopup = false;
  public S3Path = "";
  public limit = 10;
  public offset = 0;
  public totalItem = 0;
  public page = 1;
  public filterDetails: filters = {} as filters;
  public isLoading = true;
  public isDataEmpty = false;
  public libraryData: any[] = [];
  public errorMessage = "";

  public created() {
    this.S3Path = appConfigService.getS3FilesPathUrl();
    this.getLibraryData();
  }

  public getLibraryData() {
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.isLoading = true;
    applicantDetialsApi
      .getUserLibrary(this.filterDetails)
      .then((libraryData: any) => {
        this.libraryData = libraryData.data.documents;
        this.libraryData.map((data: any) => {
          data.type = data.uploadedFileName.split(".")[1];
          data.date = moment.utc(data.uploadedAt).local().format("MM-DD-YYYY");
        });
        this.libraryData = this.libraryData.sort((a: any, b: any) => {
          a.uploadedAt = moment(a.uploadedAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          b.uploadedAt = moment(b.uploadedAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          return (
            new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime()
          );
        });
        this.libraryData.reverse();
        if (this.libraryData.length) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
        this.isLoading = false;
        this.totalItem = libraryData.data.totalCount;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isDataEmpty = true;
        this.isLoading = false;
      });
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.getLibraryData();
  }

  public previewForm(rowData: any) {
    const previewData = rowData;
    if (this.imgType.includes(previewData.uploadedFileName.split(".")[1])) {
      const img = new Image();
      img.src = `${this.S3Path}/${previewData.uploadedFileName}`;
      previewData.width = img.width;
      previewData.height = img.height;
    }
    this.previewFile = [previewData];
    this.showPreviewPopup = true;
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
