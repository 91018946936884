import { utils } from "@/utils/utils";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-dropdown",
})
export default class DropdownListComponent extends Vue {
  @Prop()
  public options: any;
  @Prop()
  public value: any;
  @Prop()
  public isReviewerPreviewForm!: boolean;
  @Prop()
  public isEditEnable!: boolean;
  @Prop()
  public formType!: string;
  @Prop()
  public editForm!: boolean;
  @Prop()
  public isForm!: boolean;
  @Prop()
  public placeholder!: string;

  public selectedValue = "";
  public isDisable = false;

  public check(selectedValue: any) {
    if (!this.isForm) {
      this.selectedValue = selectedValue.label;
      this.$emit("input", selectedValue.value);
    } else {
      this.$emit("input", this.selectedValue);
    }
  }
  public created() {
    this.selectedValue = this.value;
    this.isDisable = utils.disableField(this.editForm, this.formType);
  }
}
