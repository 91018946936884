import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CPRS_EMPLOYMENT_SUMMARIES_QUERY } from "@/graphql/cprs-employment-summaries-query";
import {
  CprsEmploymentSummaries,
  FormFieldContents,
  FormNavbar,
} from "@/models/cprs-employment-summarie/cprs-employment-summaries.interface";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import { employmentVolunteerFormApi } from "@/providers/apis/employment-volunteer-form";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { utils } from "@/utils/utils";
import moment from "moment";
import { docLibraryData } from "@/models/common-interface/common.interface";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import SingleCheckboxComponent from "../single-checkbox-button/single-checkbox-button.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";
import EligibilityPopupComponent from "../eligibility-qsn-valid-popup/eligibility-qsn-valid-popup.vue";

@Component({
  name: "cprs-employment-summaries",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    "b-form-single-checkbox": SingleCheckboxComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    PreviewPopupComponent,
    ErrorPopupComponent,
    EligibilityPopupComponent,
  },
})
export default class CprsEmploymentSummariesComponent extends Vue {
  public isScroll = false;
  public applicationData: Array<CprsEmploymentSummaries> = [];
  public formData: Array<FormFieldContents> = [];
  public isValid = false;
  public isNavLoad = false;
  public tokenEmailArray: any = [];
  public token: any;
  public routeQuery: any;
  public applicationDetials: any;
  public applicationDataDetials: any;
  public supervisorOfInformation: any;
  public supervisorData: any;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public formTitle = {
    employmentSummaryTitle: "",
    employmentSummarySubTitle: "",
  };
  public currentForm: any;
  public formPagination = 0;
  public employmentPayLoadData: any = {
    employmentApplicantInformation: {
      applicantFirstName: "",
      applicantLastName: "",
      applicantPositionTitle: "",
      applicantAgencyOrOrganization: "",
      peerSupportServices: "",
      hoursPeerSupportServices: "",
      violatedCodeOfEthcs: "",
      startDate: "",
      assignedHours: "",
    },
    employmentVolunteerQuestions: {
      volunteerResponsibilities: "",
      supervisionInteractions: "",
      applicantGoals: "",
      supervisionTraining: [],
    },
    supervisorContactInformation: {
      firstName: "",
      lastName: "",
      crendtials: "",
      agency: "",
      agencyOrOrganization: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
    },
    employmentSignature: {
      isSignature: "",
      submissionDate: "",
    },
  };
  public isLoading = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidForm = false;
  public errorStatus = 0;
  public getAllDoc: docLibraryData[] = [];
  public showPreviewPopup = false;
  public previewFile: any[] = [];
  public errorMessage = "";
  public eligibilityPopup = false;
  public eligibilityPopupMsg = "";

  public async created() {
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });
    let payloadStorage: any = localStorage.getItem("employmentPayLoadData");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.employmentPayLoadData = { ...payloadStorage };
    }
    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.routeQuery = this.$route.query;
    this.token = this.routeQuery?.token;
    this.$apollo
      .query({
        query: gql`query ${CPRS_EMPLOYMENT_SUMMARIES_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.cprsEmploymentSummaries.data;
        this.formTitle = {
          employmentSummaryTitle:
            this.applicationData[0].attributes.employmentSummaryTitle,
          employmentSummarySubTitle:
            this.applicationData[0].attributes.employmentSummarySubTitle,
        };
        this.formData = this.applicationData[0].attributes.employmentFormFields;
      });
    if (this.token) {
      this.isLoading = true;
      employmentVolunteerFormApi
        .getEmploymentVolunteerForm(this.token)
        .then((result) => {
          this.applicationDetials = result.data;
          this.applicationDataDetials =
            this.applicationDetials.data.certificationPartTwoApplicationForm;
          const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
          const emailData = decodeToken.email;
          if (this.applicationDetials) {
            this.employmentPayLoadData.employmentApplicantInformation.applicantFirstName =
              this.applicationDataDetials.firstName;
            this.employmentPayLoadData.employmentApplicantInformation.applicantLastName =
              this.applicationDataDetials.lastName;
            this.employmentPayLoadData.employmentApplicantInformation.applicantPositionTitle =
              this.applicationDataDetials.positionOrTitle;
            this.employmentPayLoadData.employmentApplicantInformation.applicantAgencyOrOrganization =
              this.applicationDataDetials.agencyOrOrganization;
            this.employmentPayLoadData.employmentApplicantInformation.startDate =
              this.applicationDataDetials.startDate;

            this.supervisorOfInformation =
              result.data.data.certificationPartTwoSupervisorInformation;
            this.supervisorOfInformation.map((data: any) => {
              this.supervisorData = data;
              if (data.supervisorEmail == emailData) {
                this.employmentPayLoadData.supervisorContactInformation.firstName =
                  this.supervisorData.supervisorFirstName;
                this.employmentPayLoadData.supervisorContactInformation.lastName =
                  this.supervisorData.supervisorLastName;
                this.employmentPayLoadData.supervisorContactInformation.agencyOrOrganization =
                  this.supervisorData.supervisorAgencyOrganization;
                this.employmentPayLoadData.supervisorContactInformation.phone =
                  this.supervisorData.supervisorPhone;
                this.employmentPayLoadData.supervisorContactInformation.email =
                  this.supervisorData.supervisorEmail;
                localStorage.setItem(
                  "employmentPayLoadData",
                  JSON.stringify(this.employmentPayLoadData)
                );
              }
            });
          }
          this.employmentPayLoadData.employmentSignature.submissionDate =
            moment().format("MM-DD-YYYY");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error, true);
          this.isLoading = false;
        });
    }
  }

  // Number field validation for numeric values
  public numberSpecialCharacterValidation(event: any, formInput: any) {
    utils.preventNumeric(event, formInput);
  }

  onChangeInput() {
    localStorage.setItem(
      "employmentPayLoadData",
      JSON.stringify(this.employmentPayLoadData)
    );
  }
  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "employmentPayLoadData",
      JSON.stringify(this.employmentPayLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.$validator.validate().then((validate) => {
      this.employmentPayLoadData.supervisorContactInformation.state =
        this.employmentPayLoadData.supervisorContactInformation.state.toLocaleUpperCase();
      if (this.employmentPayLoadData.employmentSignature.isSignature) {
        this.signatureInvalid = false;
      } else {
        this.signatureInvalid = true;
      }
      const content: any = this.formData.find(
        (form) => form.parent_slug === "employmentApplicantInformation"
      );
      if (
        this.employmentPayLoadData.employmentApplicantInformation
          .violatedCodeOfEthcs
      ) {
        this.eligibilityPopupMsg = content.fields.violatedPopupMsg;
        this.eligibilityPopup = true;
        return;
      }
      if (validate && !this.signatureInvalid) {
        this.isLoading = true;
        this.checkValidForm = false;
        window.scrollTo(0, 0);
        let payloadStorage: any = localStorage.getItem("employmentPayLoadData");
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.employmentPayLoadData = { ...payloadStorage };
        }
        employmentVolunteerFormApi
          .postEmploymentApplicationForm(this.token, this.employmentPayLoadData)
          .then(() => {
            this.isLoading = false;
            const someData = {
              title: "Certified Peer Recovery Specialist Application",
              SubTitle: "Employment / Volunteer Form",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            localStorage.removeItem("employmentPayLoadData");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
      }
    });
  }

  public fieldUpdate(inputName: string) {
    const nameNotVisiable = ["fullName", "fullAddress"];
    return nameNotVisiable.includes(inputName);
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public updated() {
    window.document.onclick = (e) => {
      if (e.target !== null) {
        const getElement: HTMLElement = e.target as HTMLElement;
        const tagId = getElement.id;
        const getDocElement: HTMLAnchorElement = document.getElementById(
          tagId
        ) as HTMLAnchorElement;
        if (getDocElement) {
          const docDetials = utils.filterDoc(this.getAllDoc, tagId);
          if (docDetials) {
            this.showPreviewPopup = true;
            this.previewFile = [
              {
                fileName: "",
                width: 0,
                height: 0,
                type: docDetials.mime,
                originalFileName: docDetials.name,
                url: docDetials.url,
              },
            ];
          }
        }
      }
    };
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
  public closedEligibilityPopup(event: any) {
    this.eligibilityPopup = false;
    if (event) {
      this.$router.push({
        path: `/home`,
      });
    }
  }
}
