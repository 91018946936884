import { http } from "@/providers/http";
export class EmploymentVolunteerFormApi {
  private static INSTANCE = new EmploymentVolunteerFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  // GET for Renewal application

  public getEmploymentVolunteerForm(token: string, isRenewalSumarry = false) {
    let endpoint = "";
    if (isRenewalSumarry) {
      endpoint = `cprs/certificate/renewal/applicant/details?formName=employeeSummary`;
    } else {
      endpoint = `cprs/certificate/applicant/details?formName=employeeSummary`;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }

  public postEmploymentApplicationForm(
    token: string,
    employmentPayLoadData: any,
    isRenewalSumarry = false
  ) {
    let endpoint = "";
    if (isRenewalSumarry) {
      endpoint = `cprs/certificate/renewal/employee/summary`;
    } else {
      endpoint = `cprs/certificate/employee/summary`;
    }
    const data = {
      data: {
        employmentApplicantInformation:
          employmentPayLoadData.employmentApplicantInformation,
        supervisorContactInformation:
          employmentPayLoadData.supervisorContactInformation,
        employmentVolunteerQuestions:
          employmentPayLoadData.employmentVolunteerQuestions,
        employmentSignature: employmentPayLoadData.employmentSignature,
      },
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }
}

export const employmentVolunteerFormApi = EmploymentVolunteerFormApi.instance;
