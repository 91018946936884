export const CPRS_ADDITIONAL_CARDS_QUERY = `CprsAdditionalCard {  
  cprsAdditionalCards(sort: "id:ASC"){
      data {
        id
        attributes {
          additional_cards {
            title
            description
            url
            slug
            docKeyName
          }
        }
      }
    }
  }`;
