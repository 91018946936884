import { radioOption } from "@/models/radio-button/radio-button.interface";
import { utils } from "@/utils/utils";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-radio-group",
})
export default class RadioButtonComponent extends Vue {
  // ----------------------------------------------------------------
  // Properties
  @Prop()
  public options!: radioOption[];
  @Prop()
  public formIndex: any;
  @Prop()
  private value!: any[];
  @Prop()
  public formType!: string;
  @Prop()
  public editForm!: boolean;

  public selectedValue: any[] = [];
  public isDisable = false;

  check() {
    this.$emit("input", this.selectedValue);
  }
  public created() {
    this.selectedValue = this.value;
    this.isDisable = utils.disableField(this.editForm, this.formType);
  }
}
