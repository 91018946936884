export const trainingSummaryLists = [
  {
    title: "Lorem Ipsum Training 1",
    certification: "Certified Peer Specialist",
    issuedDate: "May 2022",
  },
  {
    title: "Lorem Ipsum Training 2",
    certification: "Certified Peer Specialist",
    issuedDate: "May 2022",
  },
  {
    title: "Lorem Ipsum Training 3",
    certification: "Certified Peer Specialist",
    issuedDate: "May 2022",
  },
  {
    title: "Lorem Ipsum Training 4",
    certification: "Certified Peer Specialist",
    issuedDate: "May 2022",
  },
  {
    title: "Lorem Ipsum Training 5",
    certification: "Certified Peer Specialist",
    issuedDate: "May 2022",
  },
  {
    title: "Lorem Ipsum Training 6",
    certification: "Certified Peer Specialist",
    issuedDate: "May 2022",
  },
];

export const peerSupportLists = [
  {
    title: "Peer Support",
    agency: "Lorem Ipsum",
    duration: "2 Hours",
    date: "22 March 2022",
  },
  {
    title: "Peer Support",
    agency: "Lorem Ipsum",
    duration: "2 Hours",
    date: "22 March 2022",
  },
  {
    title: "Peer Support",
    agency: "Lorem Ipsum",
    duration: "2 Hours",
    date: "22 March 2022",
  },
  {
    title: "Peer Support",
    agency: "Lorem Ipsum",
    duration: "2 Hours",
    date: "22 March 2022",
  },
  {
    title: "Peer Support",
    agency: "Lorem Ipsum",
    duration: "2 Hours",
    date: "22 March 2022",
  },
  {
    title: "Peer Support",
    agency: "Lorem Ipsum",
    duration: "2 Hours",
    date: "22 March 2022",
  },
];

export const trainingNameLists = [
  {
    name: "Training 1",
    slug: "training_1",
  },
  {
    name: "Training 2",
    slug: "training_2",
  },
  {
    name: "Training 3",
    slug: "training_3",
  },
];

export const trainingFormatLists = [
  {
    name: "In-Person",
    slug: "in_person",
  },
  {
    name: "Virtual",
    slug: "virtual",
  },
];
