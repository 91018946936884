export const CPRS_APPLICATIONS_QUERY = `cprsApplication {  
  cprsApplications(sort: "id:ASC"){
      data {
        id
        attributes {
            title
            description
            url
            slug
        }
      }
    }
  }`;
