import { utils } from "@/utils/utils";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { ADDITIONAL_REFERENCE_FORM_QUERY } from "@/graphql/additional-reference-form-query";
import { addReference } from "@/models/certification-part-two-form/certification-part-two-form.interface";
import { STATUS, User } from "@/models/common-interface/common.interface";
import { certificationFormApi } from "@/providers/apis/certification-part-two-form";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "additional-reference",
  components: {
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class AdditionalReferenceFormComponent extends Vue {
  public isScroll = false;
  public formTitle = {
    program_title: "",
    application_name: "",
  };
  public isLoading = true;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public mainId = "app";
  public applicationData: any = {};
  public referencePayLoadData: any[] = [];
  public referenceEmailDuplicateIndexs: number[] = [];
  public checkValidForm = false;
  public referenceFormFields: addReference[] = [];
  public userDetails: User = {} as User;
  public rejectedEmails: string[] = [];
  public routeQuery: any;
  public token = "";
  public errorMessage = "";
  public alreadySubmittedEmails: string[] = [];
  public alreadySubmittedEmailIdx: number[] = [];

  public async created() {
    this.userDetails = utils.getUserDetails();
    this.routeQuery = this.$route.query;
    this.token = this.routeQuery?.token;
    await certificationFormApi
      .getReferenceLists(this.token)
      .then(async (referenceLists: any) => {
        referenceLists.references.map((data: any) => {
          this.alreadySubmittedEmails.push(data.email.toLowerCase());
          if (
            data.status === STATUS.notAccepted &&
            data.notificationStatus === "NOTIFICATION_SENT"
          ) {
            this.rejectedEmails.push(data.email.toLowerCase());
          }
        });
        await this.$apollo
          .query({
            query: gql`query ${ADDITIONAL_REFERENCE_FORM_QUERY}`,
          })
          .then((result) => {
            this.applicationData = result.data.additionalReferenceForms.data;
            this.formTitle = {
              program_title: this.applicationData[0].attributes.program_title,
              application_name:
                this.applicationData[0].attributes.application_name,
            };
            for (let index = 0; index < this.rejectedEmails.length; index++) {
              if (this.referencePayLoadData.length) {
                this.referenceFormFields.push(
                  this.applicationData[0].attributes.form_fields.multiForm[0]
                );
                this.referencePayLoadData.push({
                  firstName: "",
                  lastName: "",
                  referencePhoneNumber: "",
                  referenceEmailAddress: "",
                });
              } else {
                this.referencePayLoadData = [
                  {
                    firstName: "",
                    lastName: "",
                    referencePhoneNumber: "",
                    referenceEmailAddress: "",
                  },
                ];
                this.referenceFormFields = [
                  this.applicationData[0].attributes.form_fields.multiForm[0],
                ];
              }
            }
            this.isLoading = false;
          });
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error, true);
        this.isLoading = false;
      });
  }

  public addAnother() {
    this.referencePayLoadData.push({
      firstName: "",
      lastName: "",
      referencePhoneNumber: "",
      referenceEmailAddress: "",
      isNewAdded: true,
    });
    this.referenceFormFields.push(
      this.applicationData[0].attributes.form_fields.multiForm[0]
    );
  }

  public removeAddedReference() {
    if (
      this.referencePayLoadData[this.referencePayLoadData.length - 1].isNewAdded
    ) {
      this.referencePayLoadData.splice(this.referencePayLoadData.length - 1);
      this.referenceFormFields.splice(this.referenceFormFields.length - 1);
    }
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.$validator.validate().then(async (validate) => {
      // Email duplicate validation

      this.referenceEmailDuplicateIndexs = utils.duplicateValueIdx(
        this.referencePayLoadData,
        "",
        true,
        "referenceEmailAddress"
      );

      if (this.referenceEmailDuplicateIndexs.length) {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
        return;
      }

      // check already submitted email validation
      this.referencePayLoadData.map((data, idx) => {
        if (
          this.alreadySubmittedEmails.includes(
            data.referenceEmailAddress.toLowerCase()
          )
        ) {
          if (!this.alreadySubmittedEmailIdx.includes(idx)) {
            this.alreadySubmittedEmailIdx.push(idx);
          }
        } else {
          if (this.alreadySubmittedEmailIdx.includes(idx)) {
            const position = this.alreadySubmittedEmailIdx.findIndex(
              (data) => data === idx
            );
            this.alreadySubmittedEmailIdx.splice(position);
          }
        }
      });

      const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
      const duplicateEmail = this.referencePayLoadData.filter(
        (reference: any) => {
          if (
            reference.referenceEmailAddress.toLowerCase() ===
            decodeToken.email.toLowerCase()
          ) {
            return reference;
          }
        }
      );
      if (duplicateEmail.length) {
        this.errorMessage =
          "Applicant email id and reference email id must be different *";
        return;
      }

      if (
        this.referenceEmailDuplicateIndexs.length === 0 &&
        validate &&
        this.alreadySubmittedEmailIdx.length === 0
      ) {
        this.isLoading = true;
        for (let index = 0; index < this.referencePayLoadData.length; index++) {
          if (this.referencePayLoadData[index].isNewAdded) {
            delete this.referencePayLoadData[index].isNewAdded;
          }
        }
        certificationFormApi
          .addAdditionalReference(this.referencePayLoadData, this.token)
          .then((data) => {
            const someData = {
              title: "Certified Peer Recovery Specialist Application",
              SubTitle: "Additional Letter Of Reference",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            window.scrollTo(0, 0);
            this.isLoading = false;
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
      }
    });
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
