import { http } from "@/providers/http";
import { utils } from "@/utils/utils";

export class StateToStateFormApi {
  private static INSTANCE = new StateToStateFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  // POST for state to state application
  public StateToStateApplicationForm(
    fillStatus: string,
    payLoadData: any,
    form = ""
  ) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const data = {
      fillStatus: payLoadData.fillStatus,
      data: {
        stateToStateBasicDetails: payLoadData.stateToStateBasicDetails,
        stateToStateCertificationDetails:
          payLoadData.stateToStateCertificationDetails,
        stateToStateSignatureSubmission:
          payLoadData.stateToStateSignatureSubmission,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const endpoint = "cprs/statetostate";
    let headers = {};
    if (form === "state-to-state-application") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  // GET for state to state application

  public getStateToStateApplicationForm(applicationID?: string, form = "") {
    const endpoint = `cprs/statetostate`;
    const params = {
      id: applicationID,
    };
    let headers = {};
    if (form === "state-to-state-application") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  // PUT for state to state application

  public updateStateToStateForm(payLoadData: any, id: string, form = "") {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const endpoint = `cprs/statetostate?id=${id}`;
    const data = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        stateToStateBasicDetails: payLoadData.stateToStateBasicDetails,
        stateToStateCertificationDetails:
          payLoadData.stateToStateCertificationDetails,
        stateToStateSignatureSubmission:
          payLoadData.stateToStateSignatureSubmission,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    let headers = {};
    if (form === "state-to-state-application") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public updateReviewerStateToStateForm(payLoadData: any, id: string) {
    const endpoint = `cprs/statetostate/application`;
    const payload = {
      id: id,
      data: {
        stateToStateBasicDetails: payLoadData.stateToStateBasicDetails,
        stateToStateCertificationDetails:
          payLoadData.stateToStateCertificationDetails,
        stateToStateSignatureSubmission:
          payLoadData.stateToStateSignatureSubmission,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
  public updateReviewerPeerStateToStateForm(payLoadData: any, id: string) {
    const endpoint = `cprs/statetostate/applicant/edit`;
    const payload = {
      id: id,
      data: {
        stateToStateBasicDetails: payLoadData.stateToStateBasicDetails,
        stateToStateCertificationDetails:
          payLoadData.stateToStateCertificationDetails,
        stateToStateSignatureSubmission:
          payLoadData.stateToStateSignatureSubmission,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
}

export const stateToStateFormApi = StateToStateFormApi.instance;
