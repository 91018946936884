import { utils } from "@/utils/utils";
import { http } from "../http";

export class GetApplicationDetails {
  private static INSTANCE = new GetApplicationDetails();

  static get instance() {
    return this.INSTANCE;
  }

  public getPartOneApplicationList(filters: any) {
    const filterKeys: string[] = [];
    const keys = Object.keys(filters);
    for (let i = 0; i < keys.length; i++) {
      if (!utils.emptyValues.includes(filters[keys[i]])) {
        const filterKey = `${keys[i]}=${filters[keys[i]]}`;
        filterKeys.push(filterKey);
      }
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cprs/training/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }

  public getPartTwoApplicationList(filters: any) {
    const filterKeys: string[] = [];
    const keys = Object.keys(filters);
    for (let i = 0; i < keys.length; i++) {
      if (!utils.emptyValues.includes(filters[keys[i]])) {
        const filterKey = `${keys[i]}=${filters[keys[i]]}`;
        filterKeys.push(filterKey);
      }
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cprs/certificate/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }

  public getRenewalApplicationList(filters: any) {
    const filterKeys: string[] = [];
    const keys = Object.keys(filters);
    for (let i = 0; i < keys.length; i++) {
      if (!utils.emptyValues.includes(filters[keys[i]])) {
        const filterKey = `${keys[i]}=${filters[keys[i]]}`;
        filterKeys.push(filterKey);
      }
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cprs/certificate/renewal/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }

  public getStatetoStateApplicationList(filters: any) {
    const filterKeys: string[] = [];
    const keys = Object.keys(filters);
    for (let i = 0; i < keys.length; i++) {
      if (!utils.emptyValues.includes(filters[keys[i]])) {
        const filterKey = `${keys[i]}=${filters[keys[i]]}`;
        filterKeys.push(filterKey);
      }
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cprs/statetostate/list?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }
  public getPeersApplicationList(filters: any) {
    const filterKeys: string[] = [];
    const keys = Object.keys(filters);
    for (let i = 0; i < keys.length; i++) {
      if (!utils.emptyValues.includes(filters[keys[i]])) {
        const filterKey = `${keys[i]}=${filters[keys[i]]}`;
        filterKeys.push(filterKey);
      }
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cprs/peers?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }
  public getPeersPreviewApplicationList(data: any) {
    const endPoint = `cprs/peers/applications`;
    const headers = http.getTokenHeaders();
    return http.post(endPoint, headers, data).then((response) => {
      return response.data;
    });
  }
  public getReportPeersApplicationList(filters: any) {
    const filterKeys: string[] = [];
    const keys = Object.keys(filters);
    for (let i = 0; i < keys.length; i++) {
      if (!utils.emptyValues.includes(filters[keys[i]])) {
        const filterKey = `${keys[i]}=${filters[keys[i]]}`;
        filterKeys.push(filterKey);
      }
    }
    const filterStr = filterKeys.join("&");
    const endPoint = `cprs/peers/report?${filterStr}`;
    const headers = http.getTokenHeaders();
    return http.get(endPoint, headers).then((response) => {
      return response.data;
    });
  }
  public getRegionList() {
    const endpoint = `cprs/region`;
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return response;
    });
  }
  public getImpersonate(data: any) {
    const endPoint = `cprs/peers/impersonate`;
    const headers = http.getTokenHeaders();
    return http.post(endPoint, headers, data).then((response) => {
      return response.data;
    });
  }
  public getPeersProfilePicDelete(data: any) {
    const endPoint = `cprs/peers/profile-pic`;
    const headers = http.getTokenHeaders();
    return http.delete(endPoint, headers, data).then((response) => {
      return response;
    });
  }
}

export const getApplicationDetails = GetApplicationDetails.instance;
