export const LETTER_OF_REFERENCE_QUERY = `CprsLetterOfReferences {  
  cprsLetterOfReferences(sort: "id:ASC"){
    data{
      id
      attributes{
          referenceTitle
          referenceSubTitle
          referenceNavBar{
              id
              name
              slug    
          },
          referenceFormField{
              id
              parent_slug
              fields
          }
      }
    }
  }
}`;
