import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class EligibilityPopupComponent extends Vue {
  @Prop()
  public popupMsg!: string;

  public created() {
    this.$nextTick(() => {
      if (this.popupMsg) {
        this.$bvModal.show("elligibility-popup-modal");
      }
    });
  }

  public closePopup() {
    this.$emit("closedEligibilityPopup", false);
  }

  public hide(event: any) {
    if (event) {
      this.$emit("closedEligibilityPopup", true);
    }
  }
  public onSubmit() {
    this.$emit("closedEligibilityPopup", true);
  }

  public onResume() {
    this.$emit("closedEligibilityPopup", false);
  }
}
