import { http } from "@/providers/http";
import moment from "moment";

export class LoginApi {
  private static INSTANCE = new LoginApi();

  static get instance() {
    return this.INSTANCE;
  }

  public logInWithCredential(
    usernameOrEmail: string,
    password: string,
    loginType: string
  ) {
    const data = {
      grantType: loginType,
    };
    const endpoint = "user/signin";
    const token = `${usernameOrEmail}:${password}`;
    const headers = http.getBasicHeaders(token);
    return http.post(endpoint, headers, data).then((response) => {
      return {
        userDetails: {
          displayName: response.data.first_name + " " + response.data.last_name,
          email: response.data.email,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          tenantId: response.data.tenant_id,
          userId: response.data.user_id,
          roles: response.data?.permissions,
          thumbnail: response.data?.thumbnail,
        },
        access_token: response.data?.access_token,
      };
    });
  }

  public getUser() {
    const endpoint = "user/details";
    const headers = http.getTokenHeaders();
    return http.get(endpoint, headers).then((response) => {
      return {
        addressLine1: response.data.address_line_1,
        addressLine2: response.data.address_line_2,
        city: response.data.city,
        state: response.data.state,
        zip: response.data.zip,
        displayName: response.data.first_name + " " + response.data.last_name,
        email: response.data.email,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        tenantId: response.data.tenant_id,
        userId: response.data.user_id,
        roles: response.data.permissions,
        phoneNumber: response.data.phone_number,
        dob: response.data.dob
          ? moment(response.data.dob).format("MM-DD-YYYY")
          : null,
        thumbnail: response.data.thumbnail,
        optIn: response.data.opt_in,
        optInSetting: response.data.opt_in_setting,
      };
    });
  }

  public forgetpassword(data: any) {
    const endpoint = "user/cprs/forgot/password";
    const headers = http.getAnonymousTokenHeaders();
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public logOut() {
    const endpoint = "user/signout";
    const headers = http.getTokenHeaders();
    return http.delete(endpoint, headers).then((response) => {
      return response.status;
    });
  }

  public passwordUpdate(data: any) {
    const endpoint = "user/change/password";
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }
  public emailVerification(token: any) {
    const endpoint = "user/email/verify";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http
      .put(endpoint, headers)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
  public forgetPasswordVerify(token: any) {
    const endpoint = "user/cprs/forgot/verify";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http
      .put(endpoint, headers)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
  public resetPassword(data: any, token: any) {
    const endpoint = "user/cprs/reset/password";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
    };
    return http
      .put(endpoint, headers, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
}

export const loginApi = LoginApi.instance;
