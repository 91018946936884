import { CPRS_STATE_APPLICATION_QUERY } from "@/graphql/cprs-state-to-state-application-query";
import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import {
  CprsStateApplication,
  FormFieldContents,
  FormNavbar,
} from "@/models/cprs-state-to-state-application/cprs-state-to-state-application.interface";
import StateToStatePreviewFormComponent from "../cprs-state-to-state-preview-form/cprs-state-to-state-preview.component.vue";
import { utils } from "@/utils/utils";
import moment from "moment";
import DropdownListComponent from "../dropdown-list/dropdown-list.component.vue";
import { loginApi } from "@/providers/apis/login";
import { stateToStateFormApi } from "@/providers/apis/state-to-state-form";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "cprs-state-to-state-application",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-state-to-state-preview-form": StateToStatePreviewFormComponent,
    "b-form-dropdown": DropdownListComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class CprsStateToStateApplicationComponent extends Vue {
  public isScroll = false;
  public stateApplicationData: Array<CprsStateApplication> = [];
  public applicationNavData: FormNavbar[] = [];
  public formData: FormFieldContents[] = [];
  public formPagination = 0;
  public isValid = false;
  public isNavLoad = false;
  public saveDataDraft = false;
  public currentForm: any;
  public formName = "stateToStateBasicDetails";
  public formTitle = {
    main_title: "",
    sub_title: "",
  };
  public stateToStatepayLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    stateToStateBasicDetails: {
      firstName: "",
      lastName: "",
      otherFirstName: "",
      otherLastName: "",
      gender: "",
      dob: "",
      socialSecurityNumber: "",
      veteranStatus: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      workEmail: null,
    },
    stateToStateCertificationDetails: {
      currentlyCertifiedOtherThanTennessee: "",
      currentState: "",
      certificateFiles: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonPhone: "",
      contactPersonEmail: "",
      selfDirectedRecovery: "",
      disclosedToPeers: "",
      highSchool: [],
      highSchoolNotListed: "",
    },
    stateToStateSignatureSubmission: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public hideDiv: string[] = ["highSchoolNotListed"];
  public checkField: string[] = ["highSchool"];
  public signatureInvalid = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public isEdit = false;
  public checkValidForm = false;
  public formCompletedIndex: number[] = [];
  public mainId = "app";
  public currentRoute = "";
  public isFileValid = false;
  public isLoading = true;
  public formApplicationId = "";
  public currentDMY = "";
  public notFilesUploadedKeyNames: string[] = [];
  public errorMessage = "";

  public async created() {
    this.currentRoute = this.$route.path;
    const currentMonth = `${moment().month() + 1}`;
    this.currentDMY = `${moment().year()}-${
      currentMonth.length == 1 ? "0" : ""
    }${currentMonth}-${moment().date()}`;

    loginApi
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        this.isLoading = false;
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
    let payloadStorage: any = localStorage.getItem("stateToStatepayLoadData");
    const formFilledIndex: any = localStorage.getItem(
      "stateToStateFilledIndex"
    );
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.stateToStatepayLoadData = { ...payloadStorage };
    }
    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${CPRS_STATE_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.stateApplicationData =
          result.data.cprsStateToStateApplications.data;
        this.formTitle = {
          main_title: this.stateApplicationData[0].attributes.main_title,
          sub_title: this.stateApplicationData[0].attributes.sub_title,
        };
        this.applicationNavData =
          this.stateApplicationData[0].attributes.state_application_navbar;
        this.formData = this.stateApplicationData[0].attributes.form_fields;
        for (let index = 0; index < this.checkField.length; index++) {
          this.fieldUpdate(this.checkField[index], true);
        }
      });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }
  public mounted() {
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "stateToStatepayLoadData",
      JSON.stringify(this.stateToStatepayLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public nextButton() {
    this.checkValidForm = true;
    this.isFileValid = !this.isFileValid;
    this.stateToStatepayLoadData.stateToStateBasicDetails.state =
      this.stateToStatepayLoadData.stateToStateBasicDetails.state.toLocaleUpperCase();

    //signature validation
    if (this.formPagination == 2) {
      if (
        this.stateToStatepayLoadData.stateToStateSignatureSubmission.isSignature
      ) {
        this.signatureInvalid = false;
      } else {
        this.checkValidForm = true;
        this.signatureInvalid = true;
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
        return;
      }
    }

    this.$validator.validate().then(async (validate) => {
      if (validate && !this.notFilesUploadedKeyNames.length) {
        if (this.formPagination == 1) {
          if (
            this.stateToStatepayLoadData.stateToStateBasicDetails.email ===
            this.stateToStatepayLoadData.stateToStateCertificationDetails
              .contactPersonEmail
          ) {
            this.errorMessage =
              "Applicant email id and contact person email id must be different *";
            return;
          }
        }
        this.checkValidForm = false;
        this.isLoading = true;
        if (
          this.stateToStatepayLoadData.stateToStateBasicDetails.workEmail === ""
        ) {
          this.stateToStatepayLoadData.stateToStateBasicDetails.workEmail =
            null;
        }
        if (this.stateToStatepayLoadData.id == "") {
          await stateToStateFormApi
            .StateToStateApplicationForm(
              this.stateToStatepayLoadData.fillStatus,
              this.stateToStatepayLoadData,
              this.currentRoute.split("/")[2]
            )
            .then((result: any) => {
              this.isLoading = false;
              this.formApplicationId = result.headers.location;
              localStorage.setItem(
                "stateToStateApplicationId",
                this.formApplicationId
              );
              this.stateToStatepayLoadData.id = localStorage.getItem(
                "stateToStateApplicationId"
              );
              localStorage.setItem(
                "stateToStatepayLoadData",
                JSON.stringify(this.stateToStatepayLoadData)
              );
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          const id = this.stateToStatepayLoadData.id;
          if (this.formPagination === 0) {
            await stateToStateFormApi
              .StateToStateApplicationForm(
                this.stateToStatepayLoadData.fillStatus,
                this.stateToStatepayLoadData,
                this.currentRoute.split("/")[2]
              )
              .then()
              .catch((err) => {
                this.formName = "stateToStateBasicDetails";
                this.formPagination = 0;
                this.formCompletedIndex.splice(1, 1);
                localStorage.setItem(
                  "stateToStateFilledIndex",
                  this.formCompletedIndex.toString()
                );
                this.errorMessage = err.response.data.message;
              });
          }
          await stateToStateFormApi
            .updateStateToStateForm(
              this.stateToStatepayLoadData,
              id,
              this.currentRoute.split("/")[2]
            )
            .then((data) => {
              this.isLoading = false;
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });

          localStorage.setItem(
            "stateToStatepayLoadData",
            JSON.stringify(this.stateToStatepayLoadData)
          );
          if (this.isEdit) {
            this.formPagination = 3;
          }
        }
        if (!this.formCompletedIndex.includes(this.formPagination)) {
          this.formCompletedIndex.push(this.formPagination);
        }
        localStorage.setItem(
          "stateToStateFilledIndex",
          this.formCompletedIndex.toString()
        );
        this.currentForm = this.stateToStatepayLoadData[this.formName];
        localStorage.setItem(
          "stateToStatepayLoadData",
          JSON.stringify(this.stateToStatepayLoadData)
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public nextPaginationUpdate() {
    if (this.formPagination < this.formData.length - 1) {
      this.isNavLoad = true;
      const page = this.formPagination + 1;
      if (page == 0) {
        this.formName = "stateToStateBasicDetails";
      } else if (page == 1) {
        this.formName = "stateToStateCertificationDetails";
      } else if (page == 2) {
        this.formName = "stateToStateSignatureSubmission";
      }
      this.formPagination = page;
    }
  }

  public previousButton() {
    this.notFilesUploadedKeyNames = [];
    this.isEdit = false;
    this.checkValidForm = false;
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "stateToStateBasicDetails";
      } else if (this.formPagination == 1) {
        this.formName = "stateToStateCertificationDetails";
      } else if (this.formPagination == 2) {
        this.formName = "stateToStateSignatureSubmission";
      }
    }
  }

  public onSubmit() {
    this.$validator.validate().then((validate: any) => {
      if (validate) {
        let payloadStorage: any = localStorage.getItem(
          "stateToStatepayLoadData"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.stateToStatepayLoadData = { ...payloadStorage };
        }
        this.stateToStatepayLoadData.stateToStateBasicDetails.state =
          this.stateToStatepayLoadData.stateToStateBasicDetails.state.toLocaleUpperCase();
        this.isLoading = true;
        this.stateToStatepayLoadData.fillStatus = "COMPLETED";
        if (localStorage.getItem("stateToStateApplicationId")) {
          this.stateToStatepayLoadData.id = localStorage.getItem(
            "stateToStateApplicationId"
          );
        }
        const id = this.stateToStatepayLoadData.id;
        stateToStateFormApi
          .updateStateToStateForm(
            this.stateToStatepayLoadData,
            id,
            this.currentRoute.split("/")[2]
          )
          .then(() => {
            stateToStateFormApi
              .getStateToStateApplicationForm(
                id,
                this.currentRoute.split("/")[2]
              )
              .then((result: any) => {
                localStorage.removeItem("anonymousToken");
                const applicationCode = result.data.code;
                const applicationNameData = {
                  title: "Certified Peer Recovery Specialist Application",
                  SubTitle: "State to state application",
                };
                this.$router.push({
                  path: "/form/submitted",
                  query: {
                    subTitle: applicationNameData.SubTitle,
                    applicationId: applicationCode,
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                this.errorMessage = utils.errorMessage(error);
                this.isLoading = false;
              });

            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.removeItem("stateToStatepayLoadData");
            localStorage.removeItem("stateToStateApplicationId");
            localStorage.removeItem("stateToStateFilledIndex");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    });
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state" || fieldName === "currentState") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "stateToStateBasicDetails") {
      this.formPagination = 0;
    } else if (slug == "stateToStateCertificationDetails") {
      this.formPagination = 1;
    } else if (slug == "stateToStateSignatureSubmission") {
      this.stateToStatepayLoadData.stateToStateSignatureSubmission.isSignature =
        "";
      localStorage.setItem(
        "stateToStatepayLoadData",
        JSON.stringify(this.stateToStatepayLoadData)
      );
      this.formPagination = 2;
    }
    const el = document.getElementById(this.mainId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  public fieldUpdate(groupName = "", initial = false) {
    switch (groupName) {
      case "highSchool":
        if (
          !this.stateToStatepayLoadData.stateToStateCertificationDetails.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed") && !initial) {
            this.hideDiv.push("highSchoolNotListed");
            this.stateToStatepayLoadData.stateToStateCertificationDetails.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
      default:
        break;
    }
  }

  public navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      if (index > this.formPagination) {
        this.checkValidForm = true;

        this.isFileValid = !this.isFileValid;

        //signature validation
        if (this.formPagination == 2) {
          if (
            this.stateToStatepayLoadData.stateToStateSignatureSubmission
              .isSignature
          ) {
            this.signatureInvalid = false;
          } else {
            this.checkValidForm = true;
            this.signatureInvalid = true;
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 10);
            return;
          }
        }

        this.$validator.validateAll().then((validate: any) => {
          if (validate && !this.notFilesUploadedKeyNames.length) {
            if (index > 1) {
              if (
                this.stateToStatepayLoadData.stateToStateBasicDetails.email ===
                this.stateToStatepayLoadData.stateToStateCertificationDetails
                  .contactPersonEmail
              ) {
                this.errorMessage =
                  "Applicant email id and contact person email id must be different *";
                return;
              }
              this.formPagination = 1;
              this.formName = "stateToStateCertificationDetails";
            }
            if (
              this.stateToStatepayLoadData.stateToStateBasicDetails
                .workEmail === ""
            ) {
              this.stateToStatepayLoadData.stateToStateBasicDetails.workEmail =
                null;
            }
            localStorage.setItem(
              "stateToStatepayLoadData",
              JSON.stringify(this.stateToStatepayLoadData)
            );

            if (index === 3) {
              this.formPagination = index;
            } else {
              this.formName = slug;
              this.formPagination = index;
            }
          } else {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }
        });
      } else {
        this.notFilesUploadedKeyNames = [];
        this.isEdit = false;
        this.checkValidForm = false;
        if (index === 3) {
          this.formPagination = index;
        } else {
          this.formName = slug;
          this.formPagination = index;
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
  public goBack() {
    this.$router.push({
      path: `/application`,
      query: {
        form: "state-to-state",
      },
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
}
