export const CPRS_TRAINING_APPLICATION_QUERY = `CprsTrainingApplicationForms {  
    cprsTrainingApplicationForms(sort: "id:ASC"){
        data{
            id
            attributes{
                main_title
                sub_title
                training_application_navbar{
                    id
                    name
                    slug    
                },
                form_fields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
