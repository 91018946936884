import { CPRS_TRAINING_APPLICATION_QUERY } from "@/graphql/cprs-training-application-query";
import { gql } from "graphql-tag";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import {
  CertificationPartOneForm,
  FormFieldContents,
  FormNavbar,
} from "@/models/certification-part-one-form/certification-part-one-form.interface";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import { utils } from "@/utils/utils";
import { trainingFormApi } from "@/providers/apis/certification-part-one-form";
import MultiRadioButton from "../multi-radio-button/multi-radio-button.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import DropdownListComponent from "../dropdown-list/dropdown-list.component.vue";
import EditHistoryComponent from "../edit-history/edit-history.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "b-preview-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-multi-radio": MultiRadioButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    "b-form-textarea": InputTextAreaComponent,
    "b-form-dropdown": DropdownListComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    EditHistoryComponent,
    ErrorPopupComponent,
  },
})
export default class CertificationPartOnePreviewFormComponent extends Vue {
  // ----------------------------------------------------------------
  // Properties
  @Prop()
  public payloadDataAll: any;
  @Prop()
  public checkFormValidation!: any;
  @Prop()
  public previewData: any;
  @Prop()
  public tabSelect: any;
  @Prop()
  public isReviewerPreviewForm!: boolean;
  @Prop()
  public mostLastRecord: any;
  @Prop()
  public progressStatus: any;
  @Prop()
  public isUserApplication!: boolean;
  @Prop()
  public isMigrationForm!: boolean;
  @Prop()
  public applicantPreviewForm!: any;

  public editReviewerForm = false;
  public isEditReviewerForm = false;
  public fileData: File[] = [];
  public formData: FormFieldContents[] = [];
  public applicationData: Array<CertificationPartOneForm> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public limitationList: any;
  public previewPayload: any = {};
  public currentRoute = "";
  public isMobileView = false;
  public isTextAreaDisable = true;
  public hideDiv: string[] = [
    "likeAboutLed",
    "groupLeadFeel",
    "likeAboutTaught",
    "feelAboutTeaching",
    "recoveryFromSubstanceDisorderLastYear",
    "isRecoveryFromMentalDisorder",
    "ethnicHeritageNotListed",
    "highSchoolNotListed",
  ];
  public checkField: string[] = [
    "recoveryFromMentalDisorder",
    "recoveryFromSubstanceDisorder",
    "ledAGroup",
    "taughtAClass",
    "ethnicHeritage",
    "highSchool",
  ];
  public isLoading = false;
  public isLargeLoading = false;
  public editIndex!: number;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidation = false;
  public vaildFile = true;
  public isFileValid = false;
  public notFilesUploadedKeyNames: string[] = [];
  public isClickSavebtn = "false";
  public originalData: any = {};
  public editApplication = false;
  public isConfirm = false;
  public errorMessage = "";

  public async created() {
    this.isLoading = true;
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem("partOneCertificatePayload");
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.previewPayload = { ...payloadStorage };
      this.previewPayload.certificationPartOneBasicDetails.fullName = `${payloadStorage.certificationPartOneBasicDetails.firstName} ${payloadStorage.certificationPartOneBasicDetails.lastName}`;
      this.previewPayload.certificationPartOneBasicDetails.otherFullName = `${payloadStorage.certificationPartOneBasicDetails.otherFirstName} ${payloadStorage.certificationPartOneBasicDetails.otherLastName}`;
      this.previewPayload.certificationPartOneBasicDetails.fullAddress =
        utils.addressBuilder(
          this.previewPayload,
          "certificationPartOneBasicDetails",
          "default"
        );
    } else {
      this.previewPayload = this.previewData;
    }
    this.$apollo
      .query({
        query: gql`query ${CPRS_TRAINING_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.cprsTrainingApplicationForms.data;
        this.applicationNavData =
          this.applicationData[0].attributes.training_application_navbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.limitationList = this.formData.length - 1;
        if (!this.isMigrationForm) {
          for (let index = 0; index < this.checkField.length; index++) {
            this.fieldUpdated(this.checkField[index], true);
          }
        }
        this.isLoading = false;
      });
    this.originalData = JSON.parse(JSON.stringify(this.previewPayload));
  }
  editForm(slug: string) {
    this.$emit("applicationSlug", slug);
  }
  onChangeInput() {
    if (!this.isReviewerPreviewForm) {
      localStorage.setItem(
        "partOneCertificatePayload",
        JSON.stringify(this.previewPayload)
      );
    }
  }
  updatePreview() {
    this.$emit("payloadData", this.previewPayload);
  }

  public fileValid(event: any) {
    this.vaildFile = event;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public onCilckSave() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    this.$validator.validateAll().then(async (validate) => {
      if (validate && this.vaildFile && !this.notFilesUploadedKeyNames.length) {
        if (this.currentRoute.split("/")[2] === "my-application") {
          const id = this.applicantPreviewForm.id;
          this.isLargeLoading = true;
          trainingFormApi
            .updateReviewerPeerPartOneForm(this.previewPayload, id)
            .then(() => {
              this.isLargeLoading = false;
              this.isEditReviewerForm = false;
              const data = {
                isUpdateData: true,
                isUpdateEmail: false,
              };
              this.$emit("getPeerUpdatedData", data);
              this.$emit("onClickEdit", this.isEditReviewerForm);
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLargeLoading = false;
            });
        } else {
          this.editApplication = true;
          this.isClickSavebtn = this.changeStateForFieldUpdate(
            this.isClickSavebtn
          );
        }
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public isConfirmChanges(event: boolean) {
    this.isConfirm = event;
    if (this.isConfirm) {
      const id = this.$route.path.split("/")[3];
      this.isLargeLoading = true;
      this.checkValidation = false;
      if (
        this.previewPayload.certificationPartOneBasicDetails.workEmail === ""
      ) {
        this.previewPayload.certificationPartOneBasicDetails.workEmail = null;
      }
      trainingFormApi
        .updateReviewerPartOneForm(this.previewPayload, id)
        .then(() => {
          this.isLargeLoading = false;
          this.isEditReviewerForm = false;
          this.$emit("getUpdatedData", true);
          this.$emit("onClickEdit", this.isEditReviewerForm);
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLargeLoading = false;
        });
    }
  }

  public isRevertedChanges(event: boolean) {
    if (event === true) {
      this.$emit("getUpdatedData", true);
      this.$emit("onClickEdit", false);
    }
  }

  public editedPreviewPayloadData(event: any) {
    if (event) {
      this.previewPayload = event;
    }
  }

  onCilckEdit(index: number) {
    this.editIndex = index;
    this.isEditReviewerForm = true;
    this.$emit("onClickEdit", this.isEditReviewerForm);
  }

  onCancelEdit() {
    this.notFilesUploadedKeyNames = [];
    this.isEditReviewerForm = false;
    this.editApplication = false;
    if (this.currentRoute.split("/")[2] === "my-application") {
      this.$emit("getPeerUpdatedData", false);
    } else {
      this.$emit("getUpdatedData", true);
      this.$emit("onClickEdit", this.isEditReviewerForm);
    }
  }

  fieldUpdate(inputName: string) {
    if (!this.isEditReviewerForm) {
      const nameNotVisiable = [
        "firstName",
        "lastName",
        "addressLine1",
        "addressLine2",
        "state",
        "city",
        "zip",
        "confirmEmail",
        "otherFirstName",
        "otherLastName",
      ];
      return nameNotVisiable.includes(inputName);
    } else {
      const nameNotVisiable = [
        "fullName",
        "fullAddress",
        "confirmEmail",
        "otherFullName",
      ];
      return nameNotVisiable.includes(inputName);
    }
  }

  public fieldUpdated(groupName = "", initial = false) {
    switch (groupName) {
      case "ethnicHeritage":
        if (
          !this.previewPayload.certificationPartOneBasicDetails.ethnicHeritage.includes(
            "Not Listed (Please Specify)"
          )
        ) {
          if (!this.hideDiv.includes("ethnicHeritageNotListed") && !initial) {
            this.hideDiv.push("ethnicHeritageNotListed");
            this.previewPayload.certificationPartOneBasicDetails.ethnicHeritageNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("ethnicHeritageNotListed") >= 0) {
            this.hideDiv.splice(
              this.hideDiv.indexOf("ethnicHeritageNotListed"),
              1
            );
          }
        }
        break;
      case "highSchool":
        if (
          !this.previewPayload.certificationPartOneEducationExperience.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed") && !initial) {
            this.hideDiv.push("highSchoolNotListed");
            this.previewPayload.certificationPartOneEducationExperience.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
      case "recoveryFromMentalDisorder":
        if (
          this.previewPayload
            .certificationPartOneProgrammaticEligibilityQuestions
            .recoveryFromMentalDisorder === "Yes"
        ) {
          if (this.hideDiv.indexOf("isRecoveryFromMentalDisorder") >= 0) {
            this.hideDiv.splice(
              this.hideDiv.indexOf("isRecoveryFromMentalDisorder"),
              1
            );
          }
        } else {
          if (
            !this.hideDiv.includes("isRecoveryFromMentalDisorder") &&
            !initial
          ) {
            this.previewPayload.certificationPartOneProgrammaticEligibilityQuestions.isRecoveryFromMentalDisorder =
              [];
            this.hideDiv.push("isRecoveryFromMentalDisorder");
          } else {
            return;
          }
        }
        break;
      case "recoveryFromSubstanceDisorder":
        if (
          this.previewPayload
            .certificationPartOneProgrammaticEligibilityQuestions
            .recoveryFromSubstanceDisorder === "Yes"
        ) {
          if (
            this.hideDiv.indexOf("recoveryFromSubstanceDisorderLastYear") >= 0
          ) {
            this.hideDiv.splice(
              this.hideDiv.indexOf("recoveryFromSubstanceDisorderLastYear"),
              1
            );
          }
        } else {
          if (
            !this.hideDiv.includes("recoveryFromSubstanceDisorderLastYear") &&
            !initial
          ) {
            this.previewPayload.certificationPartOneProgrammaticEligibilityQuestions.recoveryFromSubstanceDisorderLastYear =
              [];
            this.hideDiv.push("recoveryFromSubstanceDisorderLastYear");
          } else {
            return;
          }
        }
        break;
      case "ledAGroup":
        if (
          this.previewPayload?.certificationPartOneRecoveryNarrative
            .ledAGroup === "Yes"
        ) {
          this.previewPayload.certificationPartOneRecoveryNarrative.likeAboutLed =
            initial
              ? this.previewPayload.certificationPartOneRecoveryNarrative
                  .likeAboutLed
              : "";
          this.previewPayload.certificationPartOneRecoveryNarrative.groupLeadFeel =
            "";
          if (this.hideDiv.indexOf("likeAboutLed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("likeAboutLed"), 1);
          }
          if (!this.hideDiv.includes("groupLeadFeel") && !initial) {
            this.hideDiv.push("groupLeadFeel");
          }
        } else if (
          this.previewPayload.certificationPartOneRecoveryNarrative
            .ledAGroup === "No"
        ) {
          this.previewPayload.certificationPartOneRecoveryNarrative.groupLeadFeel =
            initial
              ? this.previewPayload.certificationPartOneRecoveryNarrative
                  .groupLeadFeel
              : "";
          this.previewPayload.certificationPartOneRecoveryNarrative.likeAboutLed =
            "";
          if (this.hideDiv.indexOf("groupLeadFeel") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("groupLeadFeel"), 1);
          }
          if (!this.hideDiv.includes("likeAboutLed") && !initial) {
            this.hideDiv.push("likeAboutLed");
          }
        }
        break;
      case "taughtAClass":
        if (
          this.previewPayload.certificationPartOneRecoveryNarrative
            .taughtAClass === "Yes"
        ) {
          this.previewPayload.certificationPartOneRecoveryNarrative.likeAboutTaught =
            initial
              ? this.previewPayload.certificationPartOneRecoveryNarrative
                  .likeAboutTaught
              : "";
          this.previewPayload.certificationPartOneRecoveryNarrative.feelAboutTeaching =
            "";
          if (this.hideDiv.indexOf("likeAboutTaught") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("likeAboutTaught"), 1);
          }
          if (!this.hideDiv.includes("feelAboutTeaching") && !initial) {
            this.hideDiv.push("feelAboutTeaching");
          }
        } else if (
          this.previewPayload.certificationPartOneRecoveryNarrative
            .taughtAClass === "No"
        ) {
          this.previewPayload.certificationPartOneRecoveryNarrative.feelAboutTeaching =
            initial
              ? this.previewPayload.certificationPartOneRecoveryNarrative
                  .feelAboutTeaching
              : "";
          this.previewPayload.certificationPartOneRecoveryNarrative.likeAboutTaught =
            "";
          if (this.hideDiv.indexOf("feelAboutTeaching") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("feelAboutTeaching"), 1);
          }
          if (!this.hideDiv.includes("likeAboutTaught") && !initial) {
            this.hideDiv.push("likeAboutTaught");
          }
        }
        break;
      default:
        break;
    }
  }

  @Watch("checkFormValidation")
  public validation() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    this.$validator.validateAll().then((validate: any) => {
      if (validate && this.vaildFile && !this.notFilesUploadedKeyNames.length) {
        this.$emit("isValidForm", true);
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
        this.$emit("isValidForm", false);
      }
    });
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
