export const SCOPE_OF_ACTIVITY = `scopeOfActivitie {  
    scopeOfActivities(sort: "order_no:ASC"){
      data {
        id
        attributes{
            requirement
            description
            order_no
            form_fields{
              id
              parent_slug
              fields
          }
          title
          icon_image {
            data {
              id
              attributes {
              name
              alternativeText
              formats
              url
              }
            }
          }
        }
      }
    }
}`;
