import {
  attachment,
  fileValidation,
} from "@/models/common-interface/common.interface";
import { commonService } from "@/providers/services/common-service";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import { utils } from "@/utils/utils";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "b-form-upload-file",
  components: {
    PreviewPopupComponent,
    ErrorPopupComponent,
  },
})
export default class FileUploadComponent extends Vue {
  @Prop()
  public fileValidation!: fileValidation;
  @Prop()
  public isEditFile!: boolean;
  @Prop()
  public isPreviewForm!: boolean;
  @Prop()
  public attachmentData!: attachment;
  @Prop()
  public keyName!: string;
  @Prop()
  public checkFileValidation!: boolean;
  @Prop()
  public fileNoNeedUpload!: boolean;
  @Prop()
  public isSingleFileUpload!: boolean;
  @Prop()
  public hideAddMoreButton!: boolean;

  @Inject("$validator") public $validator: any;

  public fileData: any[] = [];
  public file: any;
  public fileName = "";
  public fileType = "";
  public fileNamePreview = "";
  public isFile = false;
  public fileNameUri: any;
  public fileLink: any;
  public currentRoute = "";
  public isForm = false;
  public fileEmpty = false;
  public preview: any;
  public payLoadData: any;
  public files: any[] = [];
  public S3Path = "";
  public isUpload = false;
  public uploadProgress = 0;
  public isUploadStart = false;
  public uploadMsgShow = false;
  public previewData: any[] = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public showPreviewPopup = false;
  public uploadInProgress = false;
  public errorMessage = "";

  public created() {
    this.S3Path = appConfigService.getS3FilesPathUrl();
    this.currentRoute = this.$route.path;
    if (this.attachmentData?.payloadName) {
      this.isForm = true;
      if (this.attachmentData?.files?.length) {
        this.files = this.attachmentData.files;
      }
    } else {
      this.isForm = false;
      if (this.attachmentData?.files.length) {
        this.files = this.attachmentData.files;
      }
    }
  }

  public handleFileSelect(evt: any) {
    evt.target.files.forEach((element: any) => {
      this.files.push(element);
    });
    evt.target.value = null;
    if (this.fileNoNeedUpload) {
      this.$emit("input", this.files);
    } else {
      this.isUpload = true;
    }
  }

  // This function used to preview a file when file is not upload to S3

  public previewFile(index: number) {
    this.file = this.files[index];
    if (this.file) {
      if (this.file.type.split("/")[0] === "application") {
        const file = new Blob([this.file], { type: this.file.type });
        const fileURL = URL.createObjectURL(file);
        this.previewData = [
          {
            url: fileURL,
            name: this.file.name,
            type: this.file.type,
          },
        ];
        this.showPreviewPopup = true;
      } else {
        const fileURL = URL.createObjectURL(this.file);
        const img = new Image();
        img.src = fileURL;
        this.previewData = [
          {
            width: img.width,
            height: img.height,
            url: fileURL,
            name: this.file.name,
            type: this.file.type,
          },
        ];
        this.showPreviewPopup = true;
      }
    }
  }

  public async uploadFile() {
    this.isUploadStart = true;
    this.isUpload = false;
    await commonService
      .uploadFile(
        this.files,
        (event: any) => {
          this.uploadProgress = Math.round((100 * event.loaded) / event.total);
        },
        this.currentRoute.split("/")[2]
      )
      .then((result: any) => {
        this.isUploadStart = false;
        const fileBlock = result.data;
        if (fileBlock.length) {
          this.files = this.files.filter(
            (data: any) => !(data instanceof File)
          );
          for (let index = 0; index < fileBlock.length; index++) {
            this.files.push(fileBlock[index]);
          }
          this.uploadProgress = 0;
          this.uploadMsgShow = false;
          this.uploadInProgress = false;
          this.$emit("input", this.files);
          this.$emit("resetFileKey", true);
        }
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
      });
  }

  public getType(type: string) {
    if (type.includes("pdf" || "doc" || "docx")) {
      return "application";
    } else {
      return "image";
    }
  }

  public deleteFile(file: any, index: number) {
    if (this.files.length) {
      this.files.splice(index, 1);
      if (file instanceof File) {
        this.isUpload = false;
        this.uploadMsgShow = false;
      }
      this.$emit("input", this.files);
      this.$emit("resetFileKey", true);
    }
  }

  @Watch("checkFileValidation")
  public async validation() {
    const checkFileValid = this.files.some((value) => {
      return value instanceof File;
    });
    if (!this.fileNoNeedUpload) {
      if (checkFileValid) {
        if (!this.isUploadStart) {
          this.isUpload = true;
          this.uploadMsgShow = true;
        } else {
          this.uploadInProgress = true;
        }
        this.$emit("isNotUploadedKeyName", this.keyName);
      }
    }

    this.$validator.validateAll().then((validate: any) => {
      if (validate && !this.isUploadStart) {
        this.$emit("isFileValid", true);
      }
    });
  }

  public async toggleModal(file: any) {
    if (this.imgType.includes(file.fileName.split(".")[1])) {
      const img = new Image();
      img.src = `${this.S3Path}/${file.fileName}`;
      file.width = img.width;
      file.height = img.height;
    }
    this.previewData = [file];
    this.showPreviewPopup = true;
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewData = [];
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
