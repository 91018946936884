import { Component, Vue } from "vue-property-decorator";
import CprsFooterComponent from "../footer/cprs-footer.component.vue";
import CprsHeader from "../header/cprs-header.vue";

@Component({
  components: {
    CprsHeader,
    CprsFooterComponent,
  },
})
export default class BaseLayoutComponent extends Vue {
  public headerHeight = 0;
  mounted() {
    const getHeaderElement = document.getElementById("header");
    this.headerHeight = getHeaderElement?.scrollHeight as number;
  }
}
