import { Component, Vue } from "vue-property-decorator";
import { loginApi } from "@/providers/apis/login";
import BannerComponent from "@/components/banner/main-banner.component.vue";
import MoreInformationComponent from "@/components/more-information/more-information.component.vue";
import MainContentAndIntroductionComponent from "@/components/main-content-and-introduction/main-content-and-introduction.component.vue";
import CprsApplicationsComponent from "@/components/cprs-applications/cprs-applications.component.vue";
import CprsAdditionalCardsComponent from "@/components/cprs-additional-cards/cprs-additional-cards.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
@Component({
  name: "home-page",
  components: {
    BannerComponent,
    MainContentAndIntroductionComponent,
    CprsApplicationsComponent,
    CprsAdditionalCardsComponent,
    MoreInformationComponent,
  },
})
export default class HomePage extends Vue {
  public created() {
    loginApi
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        console.log("ddddd", error);
      });
  }
}
