import { radioOption } from "@/models/radio-button/radio-button.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-multi-radio",
})
export default class MultiRadioButton extends Vue {
  // ----------------------------------------------------------------
  // Properties
  @Prop()
  public options!: radioOption[];
  @Prop()
  private value: any;

  public selectedValue: any = null;
  private selected = "";

  check() {
    this.$emit("input", this.selectedValue);
  }
  public created() {
    this.selected = this.value;
    this.selectedValue = this.value;
  }
}
