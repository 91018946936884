import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { gql } from "graphql-tag";
import { utils } from "@/utils/utils";
import DateRangePicker from "vue2-daterange-picker";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import { APPLICANT_CONTINUING_EDUCATION } from "@/graphql/user-continuing-education-query";
import { CountinuingEducation } from "@/models/user-continuing-education/user-continuing-education.interface";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import formInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";
import moment from "moment";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SuccessPopupComponent from "../success-popup/success-popup.component.vue";

@Component({
  name: "user-continuing-education",
  components: {
    AppLoaderComponent,
    DateRangePicker,
    PreviewPopupComponent,
    "b-form-upload-file": FileUploadComponent,
    ErrorPopupComponent,
    "b-form-input-datepicker": formInputDatepickerComponent,
    "b-form-radio-group": RadioButtonComponent,
    SuccessPopupComponent,
  },
})
export default class UserEducationComponent extends Vue {
  public addEducation = false;
  public isFileValid = false;
  public checkValidation = false;
  public vaildFile = false;
  public isLoading = true;
  public showPreviewPopup = false;
  public educationLists: any[] = [];
  public educationActiveLists: any[] = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public S3Path = "";
  public educationFormDetials: CountinuingEducation[] = [];
  public notFilesUploadedKeyNames: string[] = [];
  public files: File[] = [];
  public previewFile: any[] = [];
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public educationData: any;
  public addNewEducationForm: any = {
    title: "",
    trainingDate: "",
    description: "",
    proofOfAttendance: [],
  };
  public isDataEmpty = false;
  public errorMessage = "";
  public formSelected = "continuing education";
  public archivedData: any = {};
  public successMsg = "";

  public created() {
    this.S3Path = appConfigService.getS3FilesPathUrl();
    this.$apollo
      .query({
        query: gql`query ${APPLICANT_CONTINUING_EDUCATION}`,
      })
      .then((result) => {
        this.educationFormDetials = result.data.userContinueEducations.data;
        this.educationFormDetials = this.educationFormDetials.filter(
          (data) => data.attributes.form_name === "applicantcontinuingEducation"
        );
      });
    this.onGetContinuingEducation();
  }
  public addCertificationForm() {
    this.addEducation = true;
  }
  public closeSidebar() {
    this.addEducation = false;
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }

  public async applicationSubmit() {
    this.checkValidation = true;
    this.isFileValid = !this.isFileValid;
    this.$validator.validateAll().then((validate: any) => {
      if (validate && this.vaildFile) {
        this.isLoading = true;
        const formdata = new FormData();
        formdata.append("title", this.addNewEducationForm.title);
        formdata.append("description", this.addNewEducationForm.description);
        formdata.append("trainingDate", this.addNewEducationForm.trainingDate);
        for (
          let index = 0;
          index < this.addNewEducationForm.proofOfAttendance.length;
          index++
        ) {
          formdata.append(
            "files",
            this.addNewEducationForm.proofOfAttendance[index]
          );
        }
        formdata.append(
          "trainingHours",
          this.addNewEducationForm.trainingDuration
            ? this.addNewEducationForm.trainingDuration
            : ""
        );
        formdata.append(
          "trainingFormat",
          this.addNewEducationForm.trainingFormat
            ? this.addNewEducationForm.trainingFormat
            : ""
        );

        applicantDetialsApi
          .createContinuingEducation(formdata)
          .then((data) => {
            if (data) {
              this.onGetContinuingEducation();
              this.addEducation = false;
              this.addNewEducationForm = {
                title: "",
                trainingDate: "",
                description: "",
                proofOfAttendance: [],
              };
            }
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    });
  }

  public previewForm(rowData: any) {
    rowData.attachments.forEach((item: any) => {
      item.uploadedFileName = item.fileName;
      const temp = JSON.parse(JSON.stringify(item));
      const previewData = temp;
      if (this.imgType.includes(previewData.uploadedFileName.split(".")[1])) {
        const img = new Image();
        img.src = `${this.S3Path}/${previewData.uploadedFileName}`;
        previewData.width = img.width;
        previewData.height = img.height;
      }
      if (!this.previewFile.length) {
        this.previewFile = [previewData];
      } else {
        this.previewFile.push(previewData);
      }
      this.showPreviewPopup = true;
    });
  }
  public archiveForm(rowData: any, type: any) {
    this.archivedData = {
      title: rowData.title,
      id: rowData.id,
      archive: type,
    };
    this.$bvModal.show("conformation-popup");
  }

  public onGetContinuingEducation() {
    this.isLoading = true;
    applicantDetialsApi
      .getContinuingEducation()
      .then((result) => {
        this.educationLists = result.data.educations;
        this.educationLists.map((item) => {
          item.trainingDate = item.trainingDate
            ? moment(item.trainingDate).format("MM-DD-YYYY")
            : null;
        });
        this.educationActiveLists = this.educationLists.filter((list: any) => {
          return this.formSelected === "continuing education"
            ? list.isArchived === false
            : list.isArchived === true;
        });
        if (this.educationActiveLists.length) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.isDataEmpty = false;
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public numberValidation(evt: Event, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fileValid(event: any) {
    this.vaildFile = event;
  }
  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public formSelection(formSelect: string) {
    this.formSelected = formSelect;
    if (this.formSelected === "continuing education") {
      this.educationActiveLists = this.educationLists.filter((list: any) => {
        return list.isArchived === false;
      });
    } else {
      this.educationActiveLists = this.educationLists.filter((list: any) => {
        return list.isArchived;
      });
    }
    if (this.educationActiveLists.length) {
      this.isDataEmpty = false;
    } else {
      this.isDataEmpty = true;
    }
  }
  public cancelConfirmPopup() {
    this.$bvModal.hide("conformation-popup");
  }
  public submitConfirmCetrificate() {
    this.$bvModal.hide("conformation-popup");
    const data = {
      id: this.archivedData.id,
      archive: this.archivedData.archive,
    };
    applicantDetialsApi.archivedEducation(data).then(() => {
      const archivedMsg = this.archivedData.archive ? "archived" : "unarchived";
      this.successMsg = `${this.archivedData.title} has been ${archivedMsg}.`;
      this.onGetContinuingEducation();
    });
  }
  public closedSuccessPopup() {
    this.successMsg = "";
  }
}
