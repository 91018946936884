import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "app-loader",
})
export default class AppLoaderComponent extends Vue {
  @Prop()
  public type!: string;
  @Prop()
  public setLoadingCenterDiv!: boolean;

  public isSmallSpinner = true;

  mounted() {
    if (this.type === "large") {
      this.isSmallSpinner = false;
    } else {
      this.isSmallSpinner = true;
    }
  }
}
