import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CPRS_ADDITIONAL_CARDS_QUERY } from "@/graphql/cprs-additional-cards-query";
import {
  CprsAdditionalCardsData,
  fields,
} from "@/models/cprs-additional-cards/cprs-additional-cards.interface";
import { docLibraryData } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";

@Component({
  components: {
    PreviewPopupComponent,
  },
})
export default class CprsAdditionalCardsComponent extends Vue {
  public testimonialData: CprsAdditionalCardsData[] = [];
  public getAllDoc: docLibraryData[] = [];
  public showPreviewPopup = false;
  public previewFile: any[] = [];

  public async created() {
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });

    await this.$apollo
      .query({
        query: gql`query ${CPRS_ADDITIONAL_CARDS_QUERY}`,
      })
      .then((result) => {
        this.testimonialData = result.data.cprsAdditionalCards.data;
      });
  }

  public navigateFrom(data: fields) {
    if (data.docKeyName) {
      const docDetials = utils.filterDoc(this.getAllDoc, data.docKeyName);
      if (docDetials) {
        this.showPreviewPopup = true;
        this.previewFile = [
          {
            fileName: "",
            width: 0,
            height: 0,
            type: docDetials.mime,
            originalFileName: docDetials.name,
            url: docDetials.url,
          },
        ];
      }
    } else if (data.url) {
      if (data.url.includes("https")) {
        window.location.href = data.url;
      } else {
        this.$router.push(data.url);
      }
    }
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }
}
