import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import DateRangePicker from "vue2-daterange-picker";
import {
  addTraining,
  filters,
  ReviewerPartOneInterface,
} from "@/models/reviewer-part-one-page/reviewer-part-one.interface";
import EmailModalPopupComponent from "../email-modal-popup/email-modal-popup.component.vue";
import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import { utils } from "@/utils/utils";
import {
  OfflineFormData,
  User,
  dateRange,
  reviewerDataTable,
} from "@/models/common-interface/common.interface";
import gql from "graphql-tag";
import { OFFLINE_FORMS_UPLOAD } from "@/graphql/offline-form-upload-query";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import DropdownWithSearchComponent from "../dropdown-with-search/dropdown-with-search.component.vue";
import {
  FilterStateStorageNames,
  SortingKeyName,
  SortingOrderKey,
  TableSortEvent,
} from "@/models/constants/filters.interface";

@Component({
  name: "reviewer-part-one-page",
  components: {
    AppLoaderComponent,
    DateRangePicker,
    EmailModalPopupComponent,
    "b-form-upload-file": FileUploadComponent,
    ErrorPopupComponent,
    DropdownWithSearchComponent,
  },
})
export default class ReviewerPartOnePage extends Vue {
  public partOneData: ReviewerPartOneInterface[] = [];
  public limit = 10;
  public offset = 0;
  public currentPage = 1;
  public totalItem = 0;
  public page = 1;
  public filterDetails: filters = {} as filters;
  public isLoading = true;
  public isDataEmpty = false;
  public openModal = false;
  public startDate: any;
  public endDate: any;
  public opens = "right";
  public picker: any;
  public file: any = null;
  public partOneEmailData: any = null;
  public statusFilterData = "";
  public activeStatus = false;
  public dateFilter = "";
  public toogleStatus = false;
  public sort = SortingKeyName.updatedAt;
  public sortBy = SortingOrderKey.DESC;
  public showFilters = false;
  public filterIcon = "funnel";
  public localeDate = {
    format: "yyyy-m-dd",
    applyLabel: "Apply",
    firstDay: 0,
  };
  public dateRange: dateRange = {} as dateRange;
  public timePicker = false;
  public tableFields = [
    {
      key: "submittedAt",
      label: "Submission Date",
      sortable: false,
      thClass: "application_date pointer-events",
    },
    { key: "firstName", label: "Applicant First Name", sortable: true },
    { key: "lastName", label: "Applicant Last Name", sortable: true },

    {
      key: "email",
      label: "Email Address",
      sortable: true,
      thClass: "email",
      tdClass: "email",
    },
    {
      key: "progressStatus",
      label: "Approval Status",
      sortable: false,
      thClass: "application_status pointer-events",
      tdClass: "progress-status",
    },
    {
      key: "action",
      label: "Action",
      sortable: false,
      thClass: "application_action pointer-events",
      tdClass: "actions",
    },
  ];
  public applyStatusFilter: any = [];
  public selected: any = [];
  public options = [
    { text: "Received", value: "RECEIVED" },
    { text: "Need More Info", value: "NEED MORE INFO" },
    { text: "Processing", value: "PROCESSING" },
    { text: "Accepted", value: "ACCEPTED" },
    { text: "Not Accepted", value: "NOT ACCEPTED" },
    { text: "Completed Training", value: "COMPLETED TRAINING" },
    { text: "Waiting For Training", value: "WAITING FOR TRAINING" },
  ];
  public addApplication = false;
  public isSubmitted = false;
  public offlineFormData: OfflineFormData[] = [];
  public vaildFile = false;
  public isFileValid = false;
  public checkValidation = false;
  public addNewTrainingForm: any = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    workEmail: null,
    applicationDocument: [],
  };
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public notFilesUploadedKeyNames: string[] = [];
  public userDetails!: User;
  public errorMessage = "";
  public searchFieldOptions = [
    {
      name: "First Name",
      label: "First Name",
      value: "firstName",
    },
    {
      name: "Last Name",
      label: "Last Name",
      value: "lastName",
    },
    {
      name: "Email",
      label: "Email",
      value: "email",
    },
  ];
  public searchData: any;

  public created() {
    if (this.$route.query.status) {
      const status: string = this.$route.query.status as string;
      this.toogleStatus = !this.toogleStatus;
      this.selected = [status];
      this.onApplyFilter();
    } else {
      const partOneFilter = localStorage.getItem(
        FilterStateStorageNames.PART_ONE
      )
        ? JSON.parse(
            localStorage.getItem(FilterStateStorageNames.PART_ONE) || ""
          )
        : null;
      if (partOneFilter) {
        this.filterDetails = { ...this.filterDetails, ...partOneFilter };
        this.selected = this.filterDetails.status
          ? typeof this.filterDetails.status == "string"
            ? this.filterDetails.status?.split(",")
            : this.filterDetails.status
          : [];
        this.toogleStatus = !this.toogleStatus;
        localStorage.removeItem(FilterStateStorageNames.PART_ONE);
      } else {
        this.selected = this.options
          ?.map((status) => {
            if (!["Not Accepted", "Accepted"]?.includes(status?.text)) {
              return status?.value;
            }
          })
          ?.filter((value) => value !== undefined);
        this.toogleStatus = !this.toogleStatus;
      }
      this.applyExistingFilters();
    }
    this.userDetails = utils.getUserDetails();
    this.$apollo
      .query({
        query: gql`query ${OFFLINE_FORMS_UPLOAD}`,
      })
      .then((result) => {
        this.offlineFormData = result.data.offlineFormsUploads.data;
        this.offlineFormData = this.offlineFormData.filter(
          (data) => data.attributes.form_name === "training"
        );
      });
  }

  public applyExistingFilters() {
    this.toogleStatus = !this.toogleStatus;
    this.applyStatusFilter = this.selected;
    this.statusFilterData = this.applyStatusFilter.join(",");
    this.limit = this.filterDetails.limit || this.limit;
    this.offset = this.filterDetails.offset || this.offset;
    this.currentPage = (this.offset + this.limit) / 10;
    if (this.filterDetails?.from && this.filterDetails?.to) {
      this.dateRange.startDate = new Date(this.filterDetails?.from);
      this.dateRange.endDate = new Date(this.filterDetails.to);
      this.startDate = moment(new Date(this.dateRange.startDate)).format(
        "MM-DD-YYYY"
      );
      this.endDate = moment(new Date(this.dateRange.endDate)).format(
        "MM-DD-YYYY"
      );
      this.dateFilter = this.startDate + " - " + this.endDate;
    }
    this.getPartOneApplicationLists();
  }

  public updated() {
    window.document.onclick = (event) => {
      if (this.toogleStatus) {
        const getFilterElement =
          document.getElementsByClassName("filter-status")[0];
        if (
          getFilterElement &&
          !getFilterElement.contains(event.target as Node)
        ) {
          this.toogleStatus = !this.toogleStatus;
        }
      }
    };
  }

  public onClearFilter() {
    if (this.$route.query.status) {
      this.$router.replace("/reviewer-dashboard/part-one");
    }
    this.offset = 0;
    this.currentPage = 1;
    this.toogleStatus = !this.toogleStatus;
    this.selected = [];
    this.applyStatusFilter = [];
    this.isLoading = true;
    this.statusFilterData = "";
    this.getPartOneApplicationLists();
  }
  public onApplyFilter() {
    this.offset = 0;
    this.currentPage = 1;
    this.toogleStatus = !this.toogleStatus;
    this.applyStatusFilter = this.selected;
    this.statusFilterData = this.applyStatusFilter.join(",");
    this.getPartOneApplicationLists();
  }

  public onClearStatusFilter(isDateFilter = false, index: any) {
    if (this.$route.query.status) {
      this.$router.replace("/reviewer-dashboard/part-one");
    }
    if (isDateFilter) {
      this.dateFilter = "";
      this.startDate = "";
      this.endDate = "";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
    } else {
      this.applyStatusFilter.splice(index, 1);
      this.selected = this.applyStatusFilter;
      this.statusFilterData = this.applyStatusFilter;
    }
    this.offset = 0;
    this.currentPage = 1;
    this.getPartOneApplicationLists();
  }

  public onClearAllFilterStatus() {
    if (this.$route.query.status) {
      this.$router.replace("/reviewer-dashboard/part-one");
    }
    this.offset = 0;
    this.currentPage = 1;
    this.dateRange.startDate = null;
    this.dateRange.endDate = null;
    this.startDate = "";
    this.endDate = "";
    this.dateFilter = "";
    this.selected = [];
    this.applyStatusFilter = [];
    this.statusFilterData = "";
    this.filterDetails.firstName = "";
    this.filterDetails.lastName = "";
    this.filterDetails.email = "";
    this.sort = SortingKeyName.updatedAt;
    this.sortBy = SortingOrderKey.DESC;
    this.getPartOneApplicationLists();
  }
  public onOpenModal(row: any) {
    this.partOneEmailData = row;
    this.$bvModal.show("email-modal");
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.getPartOneApplicationLists();
  }

  public sortChanged(event: TableSortEvent) {
    if (event) {
      this.offset = 0;
      this.currentPage = 1;
    }
    if (
      event.sortBy &&
      SortingKeyName[event.sortBy as keyof typeof SortingKeyName] !== undefined
    ) {
      this.sort = SortingKeyName[event.sortBy as keyof typeof SortingKeyName];
      this.sortBy = event?.sortDesc
        ? SortingOrderKey.DESC
        : SortingOrderKey.ASC;
    }
    this.getPartOneApplicationLists();
  }

  public emailSentStatus(event: boolean) {
    if (event) {
      this.$bvModal.hide("email-modal");
    }
  }

  public showFilterInput() {
    this.showFilters = !this.showFilters;
    this.filterIcon = this.showFilters ? "funnel-fill" : "funnel";
  }

  public searchOnEnter() {
    this.getPartOneApplicationLists();
  }

  public getPartOneApplicationLists() {
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.from = this.startDate;
    this.filterDetails.to = this.endDate;
    this.filterDetails.status = this.statusFilterData;
    this.filterDetails.sort = this.sort;
    this.filterDetails.sortBy = this.sortBy;

    const email = this.filterDetails.email
      ? encodeURIComponent(this.filterDetails.email)
      : null;

    this.isLoading = true;
    if (this.searchData) {
      this.filterDetails[this.searchData.field] = this.searchData.value;
    }
    getApplicationDetails
      .getPartOneApplicationList({ ...this.filterDetails, email })
      .then((data) => {
        this.isLoading = false;
        this.partOneData = data.list;
        this.totalItem = data.totalCount;
        if (this.partOneData.length > 0) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
        this.partOneData.forEach((element: ReviewerPartOneInterface) => {
          element.submittedAt = moment(element.submittedAt).format(
            "MM-DD-YYYY"
          );
        });
      })
      .catch((error) => {
        this.isDataEmpty = true;
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public mailPopup() {
    this.openModal = true;
  }

  public previewForm(row: any, event: any) {
    localStorage.setItem(
      FilterStateStorageNames.PART_ONE,
      JSON.stringify(this.filterDetails)
    );
    this.$router.push({
      path: `part-one/${row.item.id}`,
    });
  }

  public dateChip() {
    if (this.dateRange) {
      this.startDate = moment(new Date(this.dateRange.startDate)).format(
        "MM-DD-YYYY"
      );
      this.endDate = moment(new Date(this.dateRange.endDate)).format(
        "MM-DD-YYYY"
      );
    }
    this.offset = 0;
    this.currentPage = 1;
    this.dateFilter = this.startDate + " - " + this.endDate;
  }

  public addTrainingForm() {
    this.addApplication = true;
  }

  public applicationSubmit() {
    this.isFileValid = !this.isFileValid;
    this.checkValidation = true;
    this.$validator.validateAll().then((validate) => {
      if (validate && this.vaildFile && !this.notFilesUploadedKeyNames.length) {
        this.checkValidation = false;
        //need to add API call
        this.addNewTrainingForm = {} as addTraining;
        if (this.addNewTrainingForm.workEmail === "") {
          this.addNewTrainingForm.workEmail = null;
        }
        this.isSubmitted = true;
      }
    });
  }

  public numberValidation(evt: Event, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public fileValid(event: any) {
    this.vaildFile = event;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public viewApplication() {
    this.$router.push({
      path: `#`,
      query: {
        formType: "offline",
      },
    });
  }

  public formTypeTableRowClass(item: reviewerDataTable, type: string) {
    return utils.formTypeTableRowClass(item, type);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public searchValue(data: any) {
    this.searchData = data;
    this.getPartOneApplicationLists();
  }
}
