import { Component, Prop, Vue } from "vue-property-decorator";
import DropdownListComponent from "../dropdown-list/dropdown-list.component.vue";

@Component({
  components: {
    DropdownListComponent,
  },
})
export default class DropdownWithSearchComponent extends Vue {
  @Prop()
  public options!: any[];

  public searchValue = "";
  public field = "";
  public isSelectedField = true;

  public selectedField(field: string) {
    this.field = field;
  }

  public search() {
    if (this.field) {
      this.isSelectedField = true;
      this.$emit("searchValue", {
        field: this.field,
        value: this.searchValue,
      });
    } else {
      this.isSelectedField = false;
    }
  }

  public clear() {
    this.searchValue = "";
  }
}
