import Vue from "vue";
import Component from "vue-class-component";
import { exploreModulesLists } from "./user-explore-store";

@Component({ name: "user-explore-page" })
export default class UserExplorePageComponent extends Vue {
  public exploreTrainingLists = [
    {
      title: "",
      time: 0,
      trainingType: "",
    },
  ];
  public searchText = "";

  public created() {
    this.exploreTrainingLists = exploreModulesLists;
  }

  public searchFilter(text: string) {
    const searchData = this.exploreTrainingLists.filter((data) => {
      if (
        data.title.toLowerCase().indexOf(text) > -1 ||
        data.trainingType.toLowerCase().indexOf(text) > -1
      ) {
        return data;
      }
    });
    this.exploreTrainingLists = searchData;
    if (text === "") {
      this.exploreTrainingLists = exploreModulesLists;
    }
  }
}
