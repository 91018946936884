import {
  addPeerSupport,
  addTraining,
  peerSupportList,
  trainingSummaryList,
} from "@/models/user-training-summary/user-training-summary.interface";
import { trainingFormApi } from "@/providers/apis/certification-part-one-form";
import { commonService } from "@/providers/services/common-service";
import { utils } from "@/utils/utils";
import Vue from "vue";
import Component from "vue-class-component";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import {
  trainingSummaryLists,
  peerSupportLists,
  trainingNameLists,
  trainingFormatLists,
} from "./user-training-summary.store";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "user-training-summary-page",
  components: {
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class userTrainingSummaryPage extends Vue {
  public trainingSummary: trainingSummaryList[] = [];
  public peerSupport: peerSupportList[] = [];
  public trainingNames = [
    {
      name: "",
      slug: "",
    },
  ];
  public trainingFormats = [
    {
      name: "",
      slug: "",
    },
  ];
  public addTrainingList = "training";
  public toogleTrainingName = false;
  public isAddNewOption = false;
  public addNewTrainingName = "";
  public addNewTrainingForm: addTraining = {} as addTraining;
  public addPeerSupportForm: addPeerSupport = {} as addPeerSupport;
  public trainingProof = {} as File;
  public peerSupportProof = {} as File;
  public isLoading = false;
  public showSidebar = false;
  public tabSelected = "my-trainings";
  public isMobileView = false;
  public headerHeight = 1;
  public errorMessage = "";

  public created() {
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.trainingSummary = trainingSummaryLists;
    this.peerSupport = peerSupportLists;
    this.trainingNames = trainingNameLists;
    this.trainingFormats = trainingFormatLists;
    setTimeout(() => {
      this.tagsModify();
    }, 100);
  }

  public chooseTrainingForm(training: string) {
    this.addTrainingList = training;
    this.showSidebar = true;
  }

  public selectTrainingName(name: string) {
    this.addNewTrainingForm.name = name;
  }

  public closeTrainingName() {
    this.addNewTrainingName = "";
    this.isAddNewOption = false;
  }

  public addTrainingName() {
    this.trainingNames.push({ name: this.addNewTrainingName, slug: "" });
    this.addNewTrainingName = "";
    this.isAddNewOption = false;
  }

  public selectTrainingFormat(event: any, selectFormat: string) {
    if (event.target.checked) {
      this.addNewTrainingForm.format = selectFormat;
    }
  }

  public getTrainingUploadedFile(event: any) {
    this.trainingProof = event.target.files[0];
  }

  public getPeerSupportUploadedFile(event: any) {
    this.peerSupportProof = event.target.files[0];
  }

  public onTrainingSubmit() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.isLoading = true;
        trainingFormApi.uploadFile(this.trainingProof).then(async (result) => {
          this.addNewTrainingForm.proof = result.headers.location;
          await commonService
            .addTraining(this.addNewTrainingForm)
            .then(() => {
              this.showSidebar = false;
              this.isLoading = false;
              this.addNewTrainingForm = {} as addTraining;
              this.trainingProof = {} as File;
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        });
      }
    });
  }

  public onPeerSupportSubmit() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.isLoading = true;
        trainingFormApi
          .uploadFile(this.peerSupportProof)
          .then(async (result) => {
            this.addPeerSupportForm.proof = result.headers.location;
            await commonService
              .addPeerSupport(this.addPeerSupportForm)
              .then(() => {
                this.showSidebar = false;
                this.isLoading = false;
                this.addPeerSupportForm = {} as addPeerSupport;
                this.peerSupportProof = {} as File;
              })
              .catch((error) => {
                this.errorMessage = utils.errorMessage(error);
                this.isLoading = false;
              });
          });
      }
    });
  }

  public handleScroll(scrollList: string) {
    let scrollDiv: any;
    if (scrollList === "training") {
      scrollDiv = document.getElementById("training-scroll-container");
    } else if (scrollList === "peer-support") {
      scrollDiv = document.getElementById("peer-scroll-container");
    }
    if (
      (scrollDiv &&
        scrollDiv.scrollHeight - scrollDiv.scrollTop ===
          scrollDiv.clientHeight) ||
      scrollDiv.scrollHeight - scrollDiv.scrollTop ===
        scrollDiv.clientHeight - 1 ||
      scrollDiv.scrollHeight - scrollDiv.scrollTop ===
        scrollDiv.clientHeight + 1
    ) {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    }
  }

  public tabChange(tab: string) {
    this.tabSelected = tab;
  }

  public tagsModify() {
    const getHeaderElement = document.querySelector(".header");
    this.headerHeight = getHeaderElement?.scrollHeight as number;
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
