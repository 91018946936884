export const CPRS_STATE_APPLICATION_QUERY = `CprsStateToStateApplications {  
    cprsStateToStateApplications(sort: "id:ASC"){
        data{
            id
            attributes{
                main_title
                sub_title
                state_application_navbar{
                    id
                    name
                    slug    
                }
                form_fields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
