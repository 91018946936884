import { http } from "@/providers/http";
import { utils } from "@/utils/utils";

export class TrainingFormApi {
  private static INSTANCE = new TrainingFormApi();

  static get instance() {
    return this.INSTANCE;
  }

  // POST for Part One application
  public TrainingApplicationForm(
    fillStatus: string,
    payLoadData: any,
    form = ""
  ) {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const data = {
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        certificationPartOneEducationExperience:
          payLoadData.certificationPartOneEducationExperience,
        certificationPartOneProgrammaticEligibilityQuestions:
          payLoadData.certificationPartOneProgrammaticEligibilityQuestions,
        certificationPartOneRecoveryNarrative:
          payLoadData.certificationPartOneRecoveryNarrative,
        certificationPartOneSignature:
          payLoadData.certificationPartOneSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    const endpoint = "cprs/training";
    let headers = {};
    if (form === "certification-part-one-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.post(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  // POST for Part One application file upload

  public uploadFile(file: any) {
    const formdata = new FormData();
    for (let index = 0; index < file.length; index++) {
      formdata.append("file", file[index]);
    }
    const endpoint = "upload";
    const headers = {
      ...http.getTokenHeaders(),
      "Content-Type": "multipart/form-data",
    };

    return http.post(endpoint, headers, formdata, true).then((response) => {
      return response;
    });
  }

  // GET for Part One application

  public getTrainingApplicationForm(applicationID?: string, form = "") {
    const endpoint = `cprs/training`;
    const params = {
      id: applicationID,
    };
    let headers = {};
    if (form === "certification-part-one-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.get(endpoint, headers, params).then((response) => {
      return response;
    });
  }

  // PUT for Part One application

  public updateTrainingForm(payLoadData: any, id: string, form = "") {
    const userDetails = utils.getUserDetails();
    let adminuserDetail: any = localStorage.getItem("admin_user_details");
    if (adminuserDetail) {
      adminuserDetail = JSON.parse(adminuserDetail);
    }
    const endpoint = `cprs/training?id=${id}`;
    const data = {
      id: id,
      fillStatus: payLoadData.fillStatus,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        certificationPartOneEducationExperience:
          payLoadData.certificationPartOneEducationExperience,
        certificationPartOneProgrammaticEligibilityQuestions:
          payLoadData.certificationPartOneProgrammaticEligibilityQuestions,
        certificationPartOneRecoveryNarrative:
          payLoadData.certificationPartOneRecoveryNarrative,
        certificationPartOneSignature:
          payLoadData.certificationPartOneSignature,
      },
      submittedBy:
        userDetails &&
        (!userDetails.roles || userDetails.roles === null) &&
        !localStorage.getItem("impersonateId")
          ? userDetails.userId || userDetails.user_id
          : adminuserDetail && adminuserDetail.userId
          ? adminuserDetail.userId
          : null,
    };
    let headers = {};
    if (form === "certification-part-one-form") {
      const anonymousToken = localStorage.getItem("anonymousToken");
      headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${anonymousToken}`,
      };
    } else {
      headers = http.getTokenHeaders();
    }
    return http.put(endpoint, headers, data).then((response) => {
      return response;
    });
  }

  public updateReviewerPartOneForm(payLoadData: any, id: string) {
    const endpoint = `cprs/training/application`;
    const payload = {
      id: id,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        certificationPartOneEducationExperience:
          payLoadData.certificationPartOneEducationExperience,
        certificationPartOneProgrammaticEligibilityQuestions:
          payLoadData.certificationPartOneProgrammaticEligibilityQuestions,
        certificationPartOneRecoveryNarrative:
          payLoadData.certificationPartOneRecoveryNarrative,
        certificationPartOneSignature:
          payLoadData.certificationPartOneSignature,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
  public updateReviewerPeerPartOneForm(payLoadData: any, id: string) {
    const endpoint = `cprs/training/applicant/edit`;
    const payload = {
      id: id,
      data: {
        certificationPartOneBasicDetails:
          payLoadData.certificationPartOneBasicDetails,
        certificationPartOneEducationExperience:
          payLoadData.certificationPartOneEducationExperience,
        certificationPartOneProgrammaticEligibilityQuestions:
          payLoadData.certificationPartOneProgrammaticEligibilityQuestions,
        certificationPartOneRecoveryNarrative:
          payLoadData.certificationPartOneRecoveryNarrative,
        certificationPartOneSignature:
          payLoadData.certificationPartOneSignature,
        History: {},
        editedComments: {},
      },
    };
    if (payLoadData.History && payLoadData.editedComments) {
      payload.data.History = payLoadData.History;
      payload.data.editedComments = payLoadData.editedComments;
    }
    const headers = http.getTokenHeaders();
    return http.put(endpoint, headers, payload).then((response) => {
      return response;
    });
  }
}

export const trainingFormApi = TrainingFormApi.instance;
