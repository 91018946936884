import { Component, Prop, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CPRS_EMPLOYMENT_SUMMARIES_QUERY } from "@/graphql/cprs-employment-summaries-query";
import {
  CprsEmploymentSummaries,
  FormFieldContents,
  FormNavbar,
} from "@/models/cprs-employment-summarie/cprs-employment-summaries.interface";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { utils } from "@/utils/utils";
import { certificationFormApi } from "@/providers/apis/certification-part-two-form";
import { STATUS } from "@/models/common-interface/common.interface";
import SingleCheckboxComponent from "../single-checkbox-button/single-checkbox-button.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "employment-summaries-preview",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    "b-form-textarea": InputTextAreaComponent,
    "b-form-single-checkbox": SingleCheckboxComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class EmploymentSummariesPreviewComponent extends Vue {
  @Prop()
  public previewData: any;
  @Prop()
  public isReviewerPreviewForm!: boolean;
  @Prop()
  public supervisorSelectedIdx: any;
  @Prop()
  public applicationId!: string;
  @Prop()
  public activeSupervisorEmail!: string;
  @Prop()
  public progressStatus!: string;

  public applicationData: Array<CprsEmploymentSummaries> = [];
  public formData: Array<FormFieldContents> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public storeSupervisorStatus: any[] = [];
  public currentRoute = "";
  public supervisorStatus = "";
  public employmentPayLoadData: any;
  public activeSupervisor: any;
  public isLoading = false;
  public isLargeLoading = false;
  public toogleStatus = false;
  public formTitle = {
    employmentSummaryTitle: "",
    employmentSummarySubTitle: "",
  };
  public status: string[] = [STATUS.accepted, STATUS.notAccepted];
  public checkField = [
    "applicantFirstName",
    "applicantLastName",
    "firstName",
    "lastName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zip",
  ];

  public created() {
    this.isLoading = true;
    this.$apollo
      .query({
        query: gql`query ${CPRS_EMPLOYMENT_SUMMARIES_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.cprsEmploymentSummaries.data;
        this.formTitle = {
          employmentSummaryTitle:
            this.applicationData[0].attributes.employmentSummaryTitle,
          employmentSummarySubTitle:
            this.applicationData[0].attributes.employmentSummarySubTitle,
        };
        this.formData = this.applicationData[0].attributes.employmentFormFields;
        this.isLoading = false;
      });
    for (let index = 0; index < this.previewData.length; index++) {
      if (this.storeSupervisorStatus.length) {
        this.storeSupervisorStatus.push({
          index: index,
          email: this.previewData[index].email,
          status: this.previewData[index].status,
        });
      } else {
        this.storeSupervisorStatus = [
          {
            index: index,
            email: this.previewData[index].email,
            status: this.previewData[index].status,
          },
        ];
      }
    }
    this.activeSupervisor = this.previewData.find((data: any) => {
      return data.email === this.activeSupervisorEmail;
    });
    this.referenceNameAndAddressBuild();
  }
  check(inputName: string) {
    if (!this.isReviewerPreviewForm && inputName === "fullName") {
      return true;
    } else if (
      this.isReviewerPreviewForm &&
      this.checkField.includes(inputName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public referenceNameAndAddressBuild() {
    this.activeSupervisor.data.employmentApplicantInformation.fullName = `${this.activeSupervisor.data.employmentApplicantInformation.applicantFirstName} ${this.activeSupervisor.data.employmentApplicantInformation.applicantLastName}`;
    this.activeSupervisor.data.supervisorContactInformation.fullName = `${this.activeSupervisor.data.supervisorContactInformation.firstName} ${this.activeSupervisor.data.supervisorContactInformation.lastName}`;
    const fullAddress = utils.addressBuilder(
      this.activeSupervisor.data,
      "supervisorContactInformation",
      "default"
    );
    this.activeSupervisor.data.supervisorContactInformation.fullAddress =
      fullAddress;
  }

  checkfieldRefrence(inputName: string) {
    if (!this.isReviewerPreviewForm && inputName === "fullName") {
      return true;
    } else if (
      this.isReviewerPreviewForm &&
      (inputName === "firstName" ||
        inputName === "lastName" ||
        inputName === "addressLine1" ||
        inputName === "addressLine2" ||
        inputName === "state" ||
        inputName === "city" ||
        inputName === "zip" ||
        inputName === "applicantFirstName" ||
        inputName === "applicantLastName")
    ) {
      return true;
    } else {
      return false;
    }
  }

  public async selectStatus(status: string, supervisorEmail: string) {
    this.supervisorStatus = status;
    this.isLargeLoading = true;
    const supervisorPayload = {
      id: this.applicationId,
      email: supervisorEmail,
      status: status,
    };

    this.toogleStatus = !this.toogleStatus;
    await certificationFormApi
      .updateSupervisorStatus(supervisorPayload)
      .then((data) => {
        this.$emit("supervisorUpdated", {
          email: supervisorEmail,
          idx: this.supervisorSelectedIdx,
        });
        this.isLargeLoading = false;
      })
      .catch((error) => {
        this.isLargeLoading = false;
      });
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
