import { Component, Vue } from "vue-property-decorator";
import { certificates } from "./user-home-store";
import { User } from "@/models/common-interface/common.interface";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { commonService } from "@/providers/services/common-service";
import moment from "moment";
import { utils } from "@/utils/utils";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import {
  applicantDetials,
  invalidCertification,
} from "@/models/user-home-page/user-home-page.interface";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import gql from "graphql-tag";
import { RenewalRemainders } from "@/models/renewal-remainder/renewal-remainder.interface";
import { RENEWAL_REMAINDER } from "@/graphql/renewal_remainder-query";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";
import { loginApi } from "@/providers/apis/login";
import SuccessPopupComponent from "../success-popup/success-popup.component.vue";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";

@Component({
  name: "user-home-page",
  components: {
    AppLoaderComponent,
    ErrorPopupComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    SuccessPopupComponent,
    PreviewPopupComponent,
  },
})
export default class UserHomePageComponent extends Vue {
  public userApplicantDetials: applicantDetials = {} as applicantDetials;
  public certificateLists = [
    {
      label: "",
      validUpto: "",
      renew: false,
      type: "",
    },
  ];
  public trainingLists = [
    {
      title: "",
      time: 0,
      trainingType: "",
      trainingTypeSlug: "",
    },
  ];
  public myStatus = {
    peerSupportHours: 0,
    trainingHours: 0,
  };
  public label: any = {
    cprs: "Certified Peer Recovery Specialist Program",
    cfss: "Certified Family Support Specialist",
    cyapss: "Certified Young Adult Peer Support Specialist",
  };
  public isTrainingSuccess = false;
  public currentTab = "";
  public isPopupShow = "";
  public userDetails: User = {} as User;
  public isLoading = true;
  public isEmptyCertificates = false;
  public partOneApplicationCurrentStatus = "";
  public getCyapssUrl = "";
  public getCprsUrl = "";
  public getCfssUrl = "";
  public invalidCertification: invalidCertification =
    {} as invalidCertification;
  public errorMessage = "";
  public renewalRemainder: RenewalRemainders = {} as RenewalRemainders;
  public isEditMode = false;
  public profileData = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    displayName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dob: "",
    thumbnail: "",
    optIn: "",
    optInSetting: {
      email: false,
      phoneNumber: false,
      city: false,
      region: false,
    },
  };
  public issuccessfullySaved = false;
  public isOptPeers = "";
  public files: any[] = [];
  public isVerifyEmailPopup = false;
  public S3Path = "";
  public isSave = false;
  public isErrFirstName = false;
  public isErrLastName = false;
  public isErrEmail = false;
  public isErrAddressLineone = false;
  public isErrCity = false;
  public isErrState = false;
  public isErrStatemustTwoletters = false;
  public isErrValidZip = false;
  public isErrDob = false;
  public isErrNumber = false;
  public isErrzip = false;
  public isValidEmail = false;
  public isValidMobile = false;
  public isMobileView = false;
  public getImageName = "";
  public isLoadings = false;
  public trashIconDisabled = false;
  public successMsg = "";
  public file: any = null;
  public showPreviewPopup = false;
  public previewData: any = [];

  public async created() {
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.S3Path = appConfigService.getS3FilesPathUrl();
    await this.$apollo
      .query({
        query: gql`query ${RENEWAL_REMAINDER}`,
      })
      .then((result) => {
        const remainders: any[] = result.data.renewalReminders.data;
        if (remainders.length) {
          const remaindersData = remainders.find(
            (data) => data.attributes.reminder_seq === 1
          );
          this.renewalRemainder = remaindersData.attributes;
        }
      });
    this.getUserDetail();
    if (this.userDetails) {
      commonService
        .applicationMyStatus()
        .then((result) => {
          this.myStatus = result;
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    }
    this.certificateLists = certificates;
    this.getCyapssUrl = appConfigService.getCyapssUrl();
    this.getCprsUrl = appConfigService.getStrapiApiUrl();
    this.getCfssUrl = appConfigService.getCfssUrl();
    this.onTabSelected("all");
    applicantDetialsApi
      .getApplicantCertificate()
      .then((result: any) => {
        const finalData: any = {};
        for (const property in result.data) {
          if (result.data[property] && result.data[property]?.length) {
            finalData[property] = result.data[property]?.map((item: any) => {
              item.certificateFile = JSON.parse(item.certificateFile);
              const expiryDate = moment(item.expiryDate).format("MM-DD-YYYY");
              const currentData = moment().format("MM-DD-YYYY");
              // Find Current Date Greater than Expiry Date or Current Date Equal to Expiry Date
              const findGreatestDate = moment(currentData).isSameOrAfter(
                moment(expiryDate)
              );
              if (findGreatestDate) {
                item.expired = findGreatestDate;
              } else {
                // Find Days count between Expiry Date and Current Date
                const startDate = moment(
                  moment(new Date()).format("MM-DD-YYYY")
                );
                const endDate = moment(
                  moment(item.expiryDate).format("MM-DD-YYYY")
                );
                const daysCount = Math.floor(endDate.diff(startDate, "days"));
                if (daysCount < this.renewalRemainder.days) {
                  item.expired = true;
                }
              }
              item.expiryDate = moment(item.expiryDate).format("MM-DD-YYYY");
              return item;
            });
          }
        }
        this.userApplicantDetials = finalData;
        if (!Object.keys(this.userApplicantDetials).length) {
          this.isEmptyCertificates = true;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }
  public onTabSelected(tabSelected: string) {
    this.currentTab = tabSelected;
  }

  public isLetter(e: any) {
    const char = String.fromCharCode(e.keyCode);
    if (/^[A-Za-z]+$/.test(char)) return true;
    else e.preventDefault();
  }

  public onClickRenewal(key: string, value: any, index: number) {
    if (value && value[index].certificateNumber !== null) {
      this.isRenewalApplication(key, value[index].id);
    } else {
      this.invalidCertification = {
        index: index,
        program: key,
      };
    }
  }

  public isRenewalApplication(key: string, applicantId: any) {
    switch (key) {
      case "cprs":
        window.location.href = `/form/renewal-application?id=${applicantId}`;
        break;
      case "cyapss":
        window.open(`/form/renewal-form?id=${applicantId}`, "_blank");
        break;
      case "cfss":
        window.open(`/form/renewal-form?id=${applicantId}`, "_blank");
        break;
      default:
        break;
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public editProfile() {
    this.isEditMode = true;
  }
  public checked() {
    this.isOptPeers = this.profileData.optIn;
    if (!this.profileData.optIn) {
      this.profileData.optInSetting.email = false;
      this.profileData.optInSetting.phoneNumber = false;
      this.profileData.optInSetting.city = false;
      this.profileData.optInSetting.region = false;
    }
  }
  public onSaveProfile() {
    if (
      this.profileData.firstName === "" ||
      this.profileData.lastName === "" ||
      this.profileData.email === "" ||
      this.profileData.addressLine1 === "" ||
      this.profileData.addressLine1 === null ||
      this.profileData.city === "" ||
      this.profileData.city === null ||
      this.profileData.state === "" ||
      this.profileData.state === null ||
      this.profileData.zip === "" ||
      this.profileData.zip === null ||
      this.profileData.dob === "" ||
      this.profileData.dob === null ||
      this.profileData.phoneNumber === "" ||
      this.profileData.phoneNumber === null
    ) {
      if (this.profileData.firstName === "") {
        this.isErrFirstName = true;
      }
      if (this.profileData.lastName === "") {
        this.isErrLastName = true;
      }
      if (this.profileData.email === "") {
        this.isErrEmail = true;
      }
      if (
        this.profileData.addressLine1 === "" ||
        this.profileData.addressLine1 === null
      ) {
        this.isErrAddressLineone = true;
      }
      if (this.profileData.city === "" || this.profileData.city === null) {
        this.isErrCity = true;
      }
      if (this.profileData.state === "" || this.profileData.state === null) {
        this.isErrState = true;
      }
      if (this.profileData.zip === "" || this.profileData.zip === null) {
        this.isErrzip = true;
      }
      if (this.profileData.dob === "" || this.profileData.dob === null) {
        this.isErrDob = true;
      }

      if (
        this.profileData.phoneNumber === "" ||
        this.profileData.phoneNumber === null
      ) {
        this.isErrNumber = true;
      }
      return;
    }
    if (
      this.profileData &&
      this.profileData.email &&
      !this.profileData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      this.isValidEmail = true;
      return;
    }
    if (
      this.profileData &&
      this.profileData.phoneNumber &&
      !this.profileData.phoneNumber.match(
        /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/
      )
    ) {
      this.isValidMobile = true;
      return;
    }
    if (
      this.profileData &&
      this.profileData.state &&
      this.profileData.state.length < 2
    ) {
      this.isErrStatemustTwoletters = true;
      return;
    }
    if (
      this.profileData &&
      this.profileData.zip &&
      this.profileData.zip.length < 5
    ) {
      this.isErrValidZip = true;
      return;
    }

    this.isLoading = true;
    this.profileData.thumbnail = this.userDetails.thumbnail;
    applicantDetialsApi.getUpdateProfile(this.profileData).then((response) => {
      this.isLoading = false;
      this.issuccessfullySaved = true;
      this.isEditMode = false;
      this.isVerifyEmailPopup = true;
      this.$nextTick(() => {
        this.$bvModal.show("success-modal-center");
      });

      if (this.profileData.email !== this.userDetails.email) {
        this.successMsg = `Please check your email inbox for a message sent to your new
        address, <a><b>${this.profileData.email}</b></a>. Open the email and click the link
        inside to confirm your new email.Once done, you can use it for logging in and receiving updates.`;
      } else {
        this.successMsg = "Profile updated successfully.";
      }
      if (this.isMobileView) {
        (this.$refs.profileAppSideNav as HTMLFormElement).style.display =
          "none";
        (this.$refs.profileAppSideNav as HTMLFormElement).style.position =
          "relative";
        (this.$refs.profileAppSideNav as HTMLFormElement).style.top = "initial";
      }
      this.getUserDetail();
    });
  }
  public getUserDetail() {
    loginApi.getUser().then((res: User) => {
      localStorage.setItem("profileData", JSON.stringify(res));
      this.profileData = {
        addressLine1: res.addressLine1,
        addressLine2: res.addressLine2,
        city: res.city,
        state: res.state,
        zip: res.zip,
        displayName: res.firstName + " " + res.lastName,
        firstName: res.firstName,
        lastName: res.lastName,
        phoneNumber: res.phoneNumber,
        email: res.email,
        dob: res.dob,
        thumbnail: res.thumbnail,
        optIn: res.optIn,
        optInSetting: {
          city: res?.optInSetting?.city || false,
          email: res?.optInSetting?.email || false,
          phoneNumber: res?.optInSetting?.phoneNumber || false,
          region: res?.optInSetting?.region || false,
        },
      };
      this.userDetails = res;
      this.userDetails.displayName = res.firstName + " " + res.lastName;
    });
  }
  public handleFileSelect(evt: any) {
    this.getImageName = evt.target.files[0].name;
    this.files = evt.target.files;
  }
  public async uploadFile() {
    this.isLoadings = true;
    this.trashIconDisabled = true;
    await commonService
      .uploadFile(this.files)
      .then((result: any) => {
        this.getImageName = "";
        this.isLoadings = false;
        this.trashIconDisabled = false;
        this.userDetails.thumbnail = result.data[0].fileName;
        this.files = [];
        this.isSave = true;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoadings = false;
        this.trashIconDisabled = false;
      });
  }
  public removeImage() {
    this.getImageName = "";
    this.files = [];
    this.userDetails.thumbnail = "";
    this.isSave = true;
  }
  public onCancel() {
    this.getImageName = "";
    if (this.userDetails.thumbnail == "") {
      this.userDetails.thumbnail == this.profileData.thumbnail;
    }
    this.userDetails.thumbnail =
      this.userDetails.thumbnail == this.profileData.thumbnail
        ? this.userDetails.thumbnail
        : this.profileData.thumbnail;
    this.profileData = {
      addressLine1: this.userDetails.addressLine1,
      addressLine2: this.userDetails.addressLine2,
      city: this.userDetails.city,
      state: this.userDetails.state,
      zip: this.userDetails.zip,
      displayName: this.userDetails.firstName + " " + this.userDetails.lastName,
      firstName: this.userDetails.firstName,
      lastName: this.userDetails.lastName,
      phoneNumber: this.userDetails.phoneNumber,
      email: this.userDetails.email,
      dob: this.userDetails.dob,
      thumbnail: this.userDetails.thumbnail,
      optIn: this.userDetails.optIn,
      optInSetting: {
        email: this.userDetails?.optInSetting?.email || false,
        city: this.userDetails?.optInSetting?.city || false,
        phoneNumber: this.userDetails?.optInSetting?.phoneNumber || false,
        region: this.userDetails?.optInSetting?.region || false,
      },
    };
    this.isEditMode = false;
    this.isSave = false;
    this.isErrState = false;
    this.isErrNumber = false;
    this.isErrDob = false;
    this.isErrAddressLineone = false;
    if (this.isMobileView) {
      (this.$refs.profileAppSideNav as HTMLFormElement).style.display = "none";
      (this.$refs.profileAppSideNav as HTMLFormElement).style.position =
        "relative";
      (this.$refs.profileAppSideNav as HTMLFormElement).style.top = "initial";
    }
  }
  public onChangeInput() {
    if (
      this.profileData.firstName !== this.userDetails.firstName ||
      this.profileData.lastName !== this.userDetails.lastName ||
      this.profileData.email !== this.userDetails.email ||
      this.profileData.phoneNumber !== this.userDetails.phoneNumber ||
      this.profileData.dob !== this.userDetails.dob ||
      this.profileData.addressLine1 !== this.userDetails.addressLine1 ||
      this.profileData.city !== this.userDetails.city ||
      this.profileData.state.toUpperCase() !== this.userDetails.state ||
      parseInt(this.profileData.zip) !== this.userDetails.zip ||
      this.profileData.addressLine2 !== this.userDetails.addressLine2 ||
      this.profileData.optIn !== this.userDetails.optIn ||
      this.profileData.optInSetting.email !==
        this.userDetails.optInSetting.email ||
      this.profileData.optInSetting.phoneNumber !==
        this.userDetails.optInSetting.phoneNumber ||
      this.profileData.optInSetting.city !==
        this.userDetails.optInSetting.city ||
      this.profileData.optInSetting.region !==
        this.userDetails.optInSetting.region
    ) {
      this.isSave = true;
    } else {
      this.isSave = false;
    }
    if (
      this.profileData.firstName !== "" ||
      this.profileData.lastName !== "" ||
      this.profileData.email !== "" ||
      this.profileData.addressLine1 !== "" ||
      this.profileData.city !== "" ||
      this.profileData.state !== "" ||
      this.profileData.zip !== "" ||
      this.profileData.dob !== "" ||
      this.profileData.phoneNumber !== ""
    ) {
      if (this.profileData && this.profileData.firstName !== "") {
        this.isErrFirstName = false;
      }
      if (this.profileData && this.profileData.lastName !== "") {
        this.isErrLastName = false;
      }
      if (this.profileData && this.profileData.email !== "") {
        this.isErrEmail = false;
      }
      if (this.profileData && this.profileData.addressLine1 !== "") {
        this.isErrAddressLineone = false;
      }
      if (this.profileData && this.profileData.city !== "") {
        this.isErrCity = false;
      }
      if (this.profileData && this.profileData.state !== "") {
        this.isErrState = false;
      }
      if (this.profileData && this.profileData.zip !== "") {
        this.isErrzip = false;
      }
      if (this.profileData && this.profileData.phoneNumber !== "") {
        this.isErrNumber = false;
      }
      if (this.profileData && this.profileData.dob !== "") {
        this.isErrDob = false;
      }
    }
    if (
      this.profileData.email &&
      this.profileData.email &&
      this.profileData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      this.isValidEmail = false;
    }
    if (
      this.profileData &&
      this.profileData.phoneNumber &&
      this.profileData.phoneNumber.match(/^[\d+ ]*$/)
    ) {
      this.isValidMobile = false;
    }
    if (this.profileData && this.profileData.state.length <= 2) {
      this.isErrStatemustTwoletters = false;
      return;
    }
    if (
      this.profileData &&
      this.profileData.zip.length > 1 &&
      this.profileData.zip.length <= 5
    ) {
      this.isErrValidZip = false;
      return;
    }
  }
  public onchangezipcode() {
    if (this.profileData.zip.length == 5) {
      this.isErrValidZip = false;
    }
  }
  public StateTextuppercase() {
    this.profileData.state = this.profileData.state.toUpperCase();
  }
  public editMobileViewProfile() {
    this.$bvModal.show("profile-edit-container");
    this.editProfile();
    (this.$refs.profileAppSideNav as HTMLFormElement).style.display = "block";
    (this.$refs.profileAppSideNav as HTMLFormElement).style.position =
      "absolute";
    (this.$refs.profileAppSideNav as HTMLFormElement).style.top = "60px";
  }
  public previewCertificationForm(content: any) {
    this.file = content.certificateFile;
    if (this.file) {
      const img = new Image();
      this.previewData = [
        {
          width: img.width,
          height: img.height,
          type: this.file.type,
          originalFileName: this.file.originalFileName,
          uploadedFileName: this.file.uploadedFileName,
        },
      ];
      this.showPreviewPopup = true;
    }
  }
  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewData = [];
    }
  }
}
