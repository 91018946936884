import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-date-time-picker",
})
export default class DateTimePickerComponent extends Vue {
  // ----------------------------------------------------------------
  // Properties

  public text!: string;

  @Prop()
  public value!: string;

  public created() {
    const currentdate = new Date();
    const datetime =
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      "  " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes();
    this.text = datetime;
  }
  onChangeInput() {
    this.$emit("input", this.text);
  }
}
