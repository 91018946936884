import {
  STATUS,
  User,
  applicationStatusHistory,
  editChangesData,
  editHistoryData,
} from "@/models/common-interface/common.interface";
import { commonService } from "@/providers/services/common-service";
import { utils } from "@/utils/utils";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import ScoreRubicFormComponent from "../score-rubic-form/score-rubic-form.component.vue";
import PDFViewerComponent from "../pdf-viewer/pdf-viewer.component.vue";
import RenewalPreviewFormComponent from "../renewal-preview-form/renewal-preview-form.component.vue";
import RenewalEmploymentSummariesPreviewComponent from "../renewal-employment-summaries-preview/renewal-employment-summaries-preview.component.vue";
import { ReviewerRenewalApplicationInterface } from "@/models/reviewer-renewal-page/reviewer-renewal.interface";
import { renewalFormApi } from "@/providers/apis/cprs-renewal-application";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "reviewer-renewal-preview",
  components: {
    AppLoaderComponent,
    ScoreRubicFormComponent,
    PDFViewerComponent,
    RenewalPreviewFormComponent,
    RenewalEmploymentSummariesPreviewComponent,
    ErrorPopupComponent,
  },
})
export default class ReviewerRenewalPreview extends Vue {
  @Prop()
  public applicationPreviewForm!: any;

  @Prop()
  public isPeerPreview!: any;
  public renewalApplicationId = "";
  public renewalPreviewForm: ReviewerRenewalApplicationInterface =
    {} as ReviewerRenewalApplicationInterface;
  public isLoading = true;
  public isMobileView = false;
  public tabSelected = "application-form";
  public applicationsTabSelect = "renewal-basic-details";
  public formsSelect = "application-form";
  public headerHeight = 0;
  public sectionTabHeight = 0;
  public mobileSectionTabHeight = 0;
  public historys: any;
  public toogleStatus = false;
  public approveStatus = "";
  public applicantFullName = "";
  public approveComment = "";
  public userDetails!: User;
  public commentEventNone = false;
  public mostLastRecord: any = null;
  public isAllApplicationSubmitted = false;
  public isFormEdit = false;
  public isApproveStatus = true;
  public isSubmit = false;
  public showCommentBox = false;
  public showCommentBoxWithDisable = false;
  public showRubricTab = false;
  public formType = "";
  public PDFApplicationPrintName = "";
  public source = "";
  public isUpdatePageNumber = false;
  public editedUserDetails: editHistoryData[] = [];
  public tooglebutton = false;
  public collapseIdx = "";
  public filesEdit = ["proofOfAttendance"];
  public isFormValid = false;
  public validForm = true;
  public isUserApplication = false;
  public applicationReceivedDate = "";
  public isMigrationForm = false;
  public errorMessage = "";
  public tabScrollMove: any = {};
  public supervisorIndex = 0;
  public totalRejectedSupervisorCount = 0;
  public activeSupervisor: any = [];
  public isSupervisorData = false;
  public newlyRejectedSupervisor: any[] = [];
  public refereshSupervisor: any;
  public isInformedApplicant = false;
  public finalizedStatus: string[] = [STATUS.accepted, STATUS.notAccepted];
  public isEmpResend = false;
  public updateReviewer: any;
  public editIndex: any = 0;

  public async created() {
    this.formType = this.$route.query.formType?.toString();
    this.userDetails = utils.getUserDetails();
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.renewalApplicationId = this.$route.path.split("/")[3];
    if (this.$route.path.split("/")[1] === "reviewer-dashboard") {
      this.isUserApplication = false;
      this.applicationsTabChange(
        this.applicationsTabSelect,
        "application",
        true
      );
      await this.getRenewalApplicationData();
    } else {
      this.isUserApplication = true;
      this.applicationsTabChange(
        this.applicationsTabSelect,
        "application",
        true
      );
      await this.getUserApplicationData();
    }
    if (this.$route.path.split("/")[2] === "my-application") {
      this.statusChange();
    }
  }
  public statusChange() {
    if (this.renewalPreviewForm.data.renewalSupervisorInformation?.length) {
      for (
        let idx = 0;
        idx < this.renewalPreviewForm.data.renewalSupervisorInformation.length;
        idx++
      ) {
        const checkStatus = this.renewalPreviewForm.employeeSummary.find(
          (ref: any) => {
            return (
              ref.email ===
              this.renewalPreviewForm.data.renewalSupervisorInformation[idx]
                .supervisorEmail
            );
          }
        );
        this.renewalPreviewForm.data.renewalSupervisorInformation[idx].status =
          checkStatus?.status;
      }
    }
  }
  public getUpdatedData(event: boolean) {
    if (event) {
      this.getRenewalApplicationData();
    }
  }
  public getPeerUpdatedData(event: any) {
    this.$emit("getPeerUpdatedData", true);
    if (event && !event?.isRemoveTraining) {
      this.collapseIdx = "";
      this.$emit("isUpdateForm", event);
    }
  }

  public getUserApplicationData() {
    if (this.applicationPreviewForm.submittedMode === "migration") {
      this.isMigrationForm = true;
    }
    this.renewalPreviewForm = this.applicationPreviewForm;
    this.applicantFullName = `${this.renewalPreviewForm.data.renewalApplicationInformation.firstName} ${this.renewalPreviewForm.data.renewalApplicationInformation.lastName}`;
    this.renewalPreviewForm.data.renewalApplicationInformation.fullName =
      this.applicantFullName;
    const fullAddress = utils.addressBuilder(
      this.renewalPreviewForm.data,
      "renewalApplicationInformation",
      "default"
    );
    this.renewalPreviewForm.data.renewalApplicationInformation.fullAddress =
      fullAddress;

    if (this.renewalPreviewForm.data.renewalSupervisorInformation) {
      for (
        let index = 0;
        index <
        this.renewalPreviewForm.data.renewalSupervisorInformation.length;
        index++
      ) {
        const supervisorName = `${this.renewalPreviewForm.data.renewalSupervisorInformation[index].supervisorFirstName} ${this.renewalPreviewForm.data.renewalSupervisorInformation[index].supervisorLastName}`;
        this.renewalPreviewForm.data.renewalSupervisorInformation[
          index
        ].supervisorFullName = supervisorName;
        const supervisorFullAddress = utils.addressBuilder(
          this.renewalPreviewForm.data,
          "renewalSupervisorInformation",
          "supervisor",
          true,
          index
        );
        this.renewalPreviewForm.data.renewalSupervisorInformation[
          index
        ].supervisorFullAddress = supervisorFullAddress;
      }
    }

    this.applicationReceivedDate = moment(
      this.renewalPreviewForm.data.renewalSignatureSubmission.submissionDate
    ).format("MM-DD-YYYY");
    this.isLoading = false;
    const previewClass = document.querySelector(
      "renewal-preview-main-container"
    );
    if (previewClass) {
      (previewClass as HTMLElement)?.focus();
    }
    setTimeout(() => {
      this.getHeaderHeight();
    }, 1000);
  }

  public getApplicantId() {
    this.isUpdatePageNumber = false;
    if (this.formsSelect === "application-form") {
      this.PDFApplicationPrintName = `Renewal-application-${this.renewalPreviewForm.code}`;
    } else if (this.formsSelect === "employment-summary") {
      this.PDFApplicationPrintName = `Employment-summary-application-${this.renewalPreviewForm.code}`;
    }
  }

  public getRenewalApplicationData() {
    this.editedUserDetails = [];
    this.totalRejectedSupervisorCount = 0;
    this.isLoading = true;
    let getAllEditedData: editChangesData[] = [];
    let temp: any;
    let trainingIdx: number;
    renewalFormApi
      .getRenewalnApplicationForm(this.renewalApplicationId)
      .then((result: any) => {
        const userId = this.userDetails.userId;
        const userTask = result.data?.userTask;
        this.historys = userTask.sort((a: any, b: any) => {
          a.createdAt = moment(a.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          b.createdAt = moment(b.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });
        this.historys.map((data: any) => {
          data.createdAt = moment
            .utc(data.createdAt)
            .local()
            .format("MM-DD-YYYY HH:mm A");
        });
        this.historys.reverse();

        // current userTask and findStatus
        const currentUserTask = userTask.filter(
          (task: any) => task.userId === userId
        );

        const status: string[] = [
          "ACCEPTED",
          "PROCESSING",
          "NOT ACCEPTED",
          "NEED MORE INFO",
          "INPROGRESS",
        ];

        const mostRecentHistory = currentUserTask.sort((a: any, b: any) => {
          return moment(a.createdAt, "MM-DD-YYYY HH:mm:ss:SSS").diff(
            moment(b.createdAt, "MM-DD-YYYY HH:mm:ss:SSS")
          );
        });
        this.mostLastRecord = mostRecentHistory[mostRecentHistory.length - 1];
        this.activeSupervisor = [result.data.employeeSummary[0]];
        result.data.createdAt = moment(result.data.createdAt).format(
          "MM-DD-YYYY"
        );
        this.applicationReceivedDate = moment(
          result.data.data.renewalSignatureSubmission.submissionDate
        ).format("MM-DD-YYYY");
        this.renewalPreviewForm = result.data;
        if (this.renewalPreviewForm.submittedMode === "migration") {
          this.isMigrationForm = true;
        }
        if (this.renewalPreviewForm.summaryStatus === "COMPLETED") {
          this.isAllApplicationSubmitted = true;
        } else {
          this.isAllApplicationSubmitted = false;
        }
        this.applicantFullName = `${this.renewalPreviewForm.data.renewalApplicationInformation.firstName} ${this.renewalPreviewForm.data.renewalApplicationInformation.lastName}`;
        this.renewalPreviewForm.data.renewalApplicationInformation.fullName =
          this.applicantFullName;
        const fullAddress = utils.addressBuilder(
          this.renewalPreviewForm.data,
          "renewalApplicationInformation",
          "default"
        );
        this.renewalPreviewForm.data.renewalApplicationInformation.fullAddress =
          fullAddress;

        if (this.renewalPreviewForm.data.renewalSupervisorInformation) {
          for (
            let index = 0;
            index <
            this.renewalPreviewForm.data.renewalSupervisorInformation.length;
            index++
          ) {
            const supervisorName = `${this.renewalPreviewForm.data.renewalSupervisorInformation[index].supervisorFirstName} ${this.renewalPreviewForm.data.renewalSupervisorInformation[index].supervisorLastName}`;
            this.renewalPreviewForm.data.renewalSupervisorInformation[
              index
            ].supervisorFullName = supervisorName;
            const supervisorFullAddress = utils.addressBuilder(
              this.renewalPreviewForm.data,
              "renewalSupervisorInformation",
              "supervisor",
              true,
              index
            );
            this.renewalPreviewForm.data.renewalSupervisorInformation[
              index
            ].supervisorFullAddress = supervisorFullAddress;

            const checkStatus = this.renewalPreviewForm.employeeSummary.find(
              (ref: any) => {
                return (
                  ref.email ===
                  this.renewalPreviewForm.data.renewalSupervisorInformation[
                    index
                  ].supervisorEmail
                );
              }
            );
            this.renewalPreviewForm.data.renewalSupervisorInformation[
              index
            ].status = checkStatus?.status;
          }
        }

        //supervisor rejection count

        this.renewalPreviewForm.employeeSummary.find((supervisor: any) => {
          if (supervisor.status === STATUS.notAccepted) {
            this.totalRejectedSupervisorCount++;
            if (supervisor.notificationStatus === "NOTIFICATION_PENDING") {
              const supervisorDetail = {
                name:
                  supervisor.data.supervisorContactInformation.firstName +
                  " " +
                  supervisor.data.supervisorContactInformation.lastName,
                email: supervisor.email,
              };
              if (this.newlyRejectedSupervisor.length) {
                const idx = this.newlyRejectedSupervisor.findIndex(
                  (element) => element.email === supervisorDetail.email
                );
                if (idx < 0) {
                  this.newlyRejectedSupervisor.push(supervisorDetail);
                }
              } else {
                this.newlyRejectedSupervisor = [supervisorDetail];
              }
            }
          }
        });

        if (this.formsSelect === "employment-summary") {
          this.supervisorClickable(
            this.renewalPreviewForm.data.renewalSupervisorInformation[
              this.refereshSupervisor ? this.refereshSupervisor.idx : 0
            ].supervisorEmail,
            this.refereshSupervisor ? this.refereshSupervisor.idx : 0
          );
        }

        if (
          this.renewalPreviewForm.progressStatus === STATUS.accepted ||
          this.renewalPreviewForm.progressStatus === STATUS.notAccepted ||
          this.renewalPreviewForm.progressStatus === STATUS.needMoreInfo
        ) {
          this.approveComment = this.renewalPreviewForm.comment;
          this.approveStatus = this.renewalPreviewForm.progressStatus;
        }
        this.statusCheck();
        setTimeout(() => {
          this.isLoading = false;
          this.getHeaderHeight();
        }, 1000);
        this.isLoading = false;
        for (const property in this.renewalPreviewForm.data) {
          if (this.renewalPreviewForm.data["History"]) {
            this.renewalPreviewForm.data["History"].map((historyData: any) => {
              let renewalPreviewData;
              if (property === "renewalSupervisorInformation") {
                renewalPreviewData =
                  this.renewalPreviewForm.data[property][this.editIndex];
              } else {
                renewalPreviewData = this.renewalPreviewForm.data[property];
              }
              if (property !== "renewalTrainingInformation") {
                for (const innerProperty in renewalPreviewData) {
                  if (innerProperty === "handbook") {
                    for (const SOAproperty in this.renewalPreviewForm.data[
                      property
                    ][innerProperty]) {
                      for (const SOAinnerProperty in this.renewalPreviewForm
                        .data[property][innerProperty][SOAproperty]) {
                        if (
                          historyData[SOAinnerProperty] &&
                          historyData[SOAinnerProperty].length
                        ) {
                          temp = JSON.parse(
                            JSON.stringify(historyData[SOAinnerProperty])
                          );
                          temp.forEach((element: any) => {
                            if (this.filesEdit.includes(SOAinnerProperty)) {
                              element.oldValue = element.oldValue.map(
                                (data: any) => {
                                  return data.originalFileName;
                                }
                              );
                              element.newValue = element.newValue.map(
                                (data: any) => {
                                  return data.originalFileName;
                                }
                              );
                            }
                            if (
                              typeof element.oldValue === "object" &&
                              typeof element.newValue === "object"
                            ) {
                              element.oldValue = element.oldValue.length
                                ? element.oldValue.join(", ")
                                : element.oldValue;
                              element.newValue = element.newValue.length
                                ? element.newValue.join(", ")
                                : element.newValue;
                            }
                            temp = element;
                            switch (property) {
                              case "renewalHandbook":
                                temp.mainLabel = "Code of Ethics";
                                break;
                              default:
                                break;
                            }
                            if (getAllEditedData.length) {
                              getAllEditedData.push(temp);
                            } else {
                              getAllEditedData = [temp];
                            }
                          });
                        }
                      }
                    }
                  } else {
                    if (
                      historyData[innerProperty] &&
                      historyData[innerProperty].length
                    ) {
                      temp = JSON.parse(
                        JSON.stringify(historyData[innerProperty])
                      );
                      temp.forEach((element: any) => {
                        if (this.filesEdit.includes(innerProperty)) {
                          element.oldValue = element.oldValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                          element.newValue = element.newValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                        }
                        if (
                          typeof element.oldValue === "object" &&
                          typeof element.newValue === "object"
                        ) {
                          element.oldValue = element.oldValue.length
                            ? element.oldValue.join(", ")
                            : element.oldValue;
                          element.newValue = element.newValue.length
                            ? element.newValue.join(", ")
                            : element.newValue;
                        }
                        temp = element;
                        switch (property) {
                          case "renewalApplicationInformation":
                            temp.mainLabel = "Basic Details";
                            break;
                          case "renewalTrainingInformation":
                            temp.mainLabel = "Training Information";
                            break;
                          case "renewalSupervisorInformation":
                            temp.mainLabel = "Supervisor Details";
                            break;
                          default:
                            break;
                        }
                        if (getAllEditedData.length) {
                          getAllEditedData.push(temp);
                        } else {
                          getAllEditedData = [temp];
                        }
                      });
                    }
                  }
                }
              } else {
                if (
                  historyData["removedTrainingIdx"] ||
                  historyData["newTrainingIdx"]
                ) {
                  if (historyData["removedTrainingIdx"]) {
                    historyData["removedTrainingIdx"].map(
                      (removedData: any) => {
                        removedData.isRemoved = true;
                        removedData.mainLabel = "Training Information";
                        if (getAllEditedData.length) {
                          getAllEditedData.push(removedData);
                        } else {
                          getAllEditedData = [removedData];
                        }
                      }
                    );
                  }
                  if (historyData["newTrainingIdx"]) {
                    historyData["newTrainingIdx"].map((removedData: any) => {
                      removedData.isNewlyAdded = true;
                      removedData.mainLabel = "Training Information";
                      if (getAllEditedData.length) {
                        getAllEditedData.push(removedData);
                      } else {
                        getAllEditedData = [removedData];
                      }
                    });
                  }
                } else {
                  for (
                    let index = 0;
                    index < this.renewalPreviewForm.data[property].length;
                    index++
                  ) {
                    for (const innerProperty in this.renewalPreviewForm.data[
                      property
                    ][index]) {
                      if (
                        historyData[innerProperty + "_" + index] &&
                        historyData[innerProperty + "_" + index].length
                      ) {
                        temp = JSON.parse(
                          JSON.stringify(
                            historyData[innerProperty + "_" + index]
                          )
                        );
                        temp.forEach((element: any) => {
                          if (
                            this.filesEdit.length &&
                            this.filesEdit.includes(innerProperty)
                          ) {
                            element.oldValue = element.oldValue.map(
                              (data: any) => {
                                return data.originalFileName;
                              }
                            );
                            element.newValue = element.newValue.map(
                              (data: any) => {
                                return data.originalFileName;
                              }
                            );
                          }
                          if (
                            typeof element.oldValue === "object" &&
                            typeof element.newValue === "object"
                          ) {
                            element.oldValue = element.oldValue.length
                              ? element.oldValue.join(", ")
                              : element.oldValue;
                            element.newValue = element.newValue.length
                              ? element.newValue.join(", ")
                              : element.newValue;
                          }
                          temp = element;
                          switch (property) {
                            case "renewalTrainingInformation":
                              temp.mainLabel = "Training Information";
                              break;
                            default:
                              break;
                          }
                          if (getAllEditedData.length) {
                            getAllEditedData.push(temp);
                          } else {
                            getAllEditedData = [temp];
                          }
                        });
                      }
                    }
                  }
                }
              }
            });
          }
        }
        getAllEditedData.filter((currentValue, currentIndex) => {
          return getAllEditedData.indexOf(currentValue) !== currentIndex;
        });
        const unique = [
          ...new Set(
            getAllEditedData.map((a) => {
              return a.editDataTime + "/" + a.editorDetails.userId;
            })
          ),
        ];

        unique.forEach((splitUnique: string) => {
          const dateTime = splitUnique.split("/")[0];
          const userId = splitUnique.split("/")[1];
          getAllEditedData.forEach((data: editChangesData) => {
            if (
              data.editDataTime === dateTime &&
              data.editorDetails.userId === userId
            ) {
              let splitIdx = "";
              if (
                data.isRemoved ||
                data.isNewlyAdded ||
                data.fieldName.includes("_")
              ) {
                if (data.fieldName) {
                  splitIdx = data.fieldName.split("_")[1];
                } else {
                  splitIdx = data.index.toString();
                }
              }
              if (this.editedUserDetails.length) {
                const editedIdx = this.editedUserDetails.findIndex(
                  (editDetail) => {
                    return (
                      editDetail.editorUserId === data.editorDetails.userId &&
                      editDetail.dateTime === data.editDataTime
                    );
                  }
                );
                if (editedIdx > -1) {
                  this.editedUserDetails[editedIdx].changesCount =
                    this.editedUserDetails[editedIdx].changesCount + 1;
                  if (this.editedUserDetails[editedIdx].data.length) {
                    const idx = this.editedUserDetails[
                      editedIdx
                    ].data.findIndex((a: any) => {
                      return a.mainLabel === data.mainLabel;
                    });

                    if (idx > -1) {
                      if (data.mainLabel === "Training Information") {
                        this.editedUserDetails[editedIdx].data.map(
                          (editedData: any) => {
                            if (
                              editedData.trainingLists &&
                              editedData.trainingLists.length
                            ) {
                              trainingIdx = editedData.trainingLists.findIndex(
                                (trainingList: any) => {
                                  return (
                                    trainingList.indexPosition === splitIdx
                                  );
                                }
                              );
                            }
                          }
                        );
                        if (trainingIdx > -1) {
                          const trainingDetails =
                            this.editedUserDetails[editedIdx]?.data[idx]
                              ?.trainingLists;
                          if (trainingDetails) {
                            trainingDetails[trainingIdx].changesData.push(data);
                          }
                        } else {
                          this.editedUserDetails[editedIdx].data[
                            idx
                          ].trainingLists?.push({
                            trainingName:
                              this.renewalPreviewForm.data
                                .renewalTrainingInformation[splitIdx]
                                .trainingName,
                            indexPosition: splitIdx,
                            isRemoved: data.isRemoved ? true : false,
                            isNewlyAdded: data.isNewlyAdded ? true : false,
                            changesData: [data],
                          });
                        }
                      } else {
                        this.editedUserDetails[editedIdx].data[idx].changesData
                          ?.length
                          ? this.editedUserDetails[editedIdx].data[
                              idx
                            ].changesData?.push(data)
                          : null;
                      }
                    } else {
                      this.editedUserDetails[editedIdx].data.push(
                        data.isRemoved ||
                          data.isNewlyAdded ||
                          data.fieldName.includes("_")
                          ? {
                              mainLabel: data.mainLabel,
                              trainingLists: [
                                {
                                  trainingName:
                                    this.renewalPreviewForm.data
                                      .renewalTrainingInformation[splitIdx]
                                      .trainingName,
                                  indexPosition: splitIdx,
                                  isRemoved: data.isRemoved ? true : false,
                                  isNewlyAdded: data.isNewlyAdded
                                    ? true
                                    : false,
                                  changesData: [data],
                                },
                              ],
                            }
                          : {
                              mainLabel: data.mainLabel,
                              changesData: [data],
                            }
                      );
                    }
                  }
                } else {
                  this.editedUserDetails.push({
                    editorUserId: data.editorDetails.userId,
                    data:
                      data.isRemoved ||
                      data.isNewlyAdded ||
                      data.fieldName.includes("_")
                        ? [
                            {
                              mainLabel: data.mainLabel,
                              trainingLists: [
                                {
                                  trainingName:
                                    this.renewalPreviewForm.data
                                      .renewalTrainingInformation[splitIdx]
                                      .trainingName,
                                  indexPosition: splitIdx,
                                  isRemoved: data.isRemoved ? true : false,
                                  isNewlyAdded: data.isNewlyAdded
                                    ? true
                                    : false,
                                  changesData: [data],
                                },
                              ],
                            },
                          ]
                        : [
                            {
                              mainLabel: data.mainLabel,
                              changesData: [data],
                            },
                          ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.renewalPreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  });
                }
              } else {
                this.editedUserDetails = [
                  {
                    editorUserId: data.editorDetails.userId,
                    data:
                      data.isRemoved ||
                      data.isNewlyAdded ||
                      data.fieldName.includes("_")
                        ? [
                            {
                              mainLabel: data.mainLabel,
                              trainingLists: [
                                {
                                  indexPosition: splitIdx,
                                  trainingName:
                                    this.renewalPreviewForm.data
                                      .renewalTrainingInformation[splitIdx]
                                      .trainingName,
                                  isRemoved: data.isRemoved ? true : false,
                                  isNewlyAdded: data.isNewlyAdded
                                    ? true
                                    : false,
                                  changesData: [data],
                                },
                              ],
                            },
                          ]
                        : [
                            {
                              mainLabel: data.mainLabel,
                              changesData: [data],
                            },
                          ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.renewalPreviewForm.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  },
                ];
              }
            }
          });
        });
        this.editedUserDetails = this.editedUserDetails.sort(
          (a: any, b: any) => {
            a.dateTime = moment(a.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            b.dateTime = moment(b.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            return (
              new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
            );
          }
        );
        this.editedUserDetails.map((data: any) => {
          data.dateTime = moment(data.dateTime).format(
            "MM-DD-YYYY [|] HH:mm A"
          );
        });
        this.editedUserDetails.reverse();
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public tabChange(tabSelect: string) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.tabSelected !== tabSelect && !this.isFormEdit) {
        this.tabSelected = tabSelect;
        this.getHeaderHeight();
      }
    }, 100);
  }

  public isValidForm(event: any) {
    this.validForm = event;
  }

  public applicationsTabChange(
    tabSelect: string,
    formName = "",
    initialLoad = false
  ) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.applicationsTabSelect !== tabSelect && this.validForm) {
        this.applicationsTabSelect = tabSelect;
        switch (formName) {
          case "application":
            this.source =
              "https://dno579h3gzw0n.cloudfront.net/files_upload/ee770a79-a1db-4fee-b1af-ef49c0261c9e.pdf";
            break;
          case "employment":
            this.source =
              "https://dno579h3gzw0n.cloudfront.net/files_upload/c4fdb506-75d5-4143-83c4-9b707736eb75.pdf";
            break;
          default:
            break;
        }
        if (!initialLoad) {
          this.getHeaderHeight();
        }
      }
    }, 50);
  }

  public formsTabChange(tabSelect: string, formName = "") {
    this.tabScrollMove = {};
    this.isFormValid = !this.isFormValid;
    if (!this.isFormEdit && this.validForm) {
      switch (formName) {
        case "application":
          this.applicationsTabChange("renewal-basic-details", "application");
          break;
        case "employment":
          this.applicationsTabChange("applicant-details", "employment");
          this.supervisorClickable(
            this.renewalPreviewForm.data.renewalSupervisorInformation[0]
              .supervisorEmail,
            0
          );
          break;
        default:
          break;
      }
      this.formsSelect = tabSelect;
    }
  }

  public getHeaderHeight() {
    const getHeaderElement = document.querySelector(".header");
    const getSectionTabElement = document.querySelector(".form-tab");
    this.headerHeight = getHeaderElement?.clientHeight as number;
    if (this.isMobileView) {
      const getMainHeaderElementHeight = document.querySelector(
        ".mobile-nav-container"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getMainHeaderElementHeight;
      const getMobileElement = document.querySelector(".mobile-section-tab");
      this.mobileSectionTabHeight = getMobileElement?.clientHeight as number;
    }
    this.sectionTabHeight = getSectionTabElement?.clientHeight as number;
    if (this.formsSelect === "employment-summary") {
      const getSummaryTabHeight = document.querySelector(
        ".supervisor-count-tab"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getSummaryTabHeight;
    }
  }

  public reloadPage(event: any) {
    if (event) {
      this.getRenewalApplicationData();
      if (
        this.formsSelect === "application-form" &&
        this.tabSelected === "scoring-rubric" &&
        this.isMobileView
      ) {
        this.tabSelected = "";
        this.formsSelect = "rubrics";
      }
    }
  }

  public statusUpdate(status: string) {
    this.approveStatus = status;
    this.toogleStatus = !this.toogleStatus;
  }

  public emitStatusAndComment(emitedData: any) {
    if (emitedData.status) {
      this.isLoading = true;
      this.isApproveStatus = true;
      const data = {
        id: this.renewalApplicationId,
        status: emitedData.status.toLocaleUpperCase(),
        comment: emitedData.comment,
        rubric: emitedData.rubric ? emitedData.rubric : "",
      };
      commonService
        .approveApplication(data, "certificate/renewal")
        .then(() => {
          this.getRenewalApplicationData();
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    }
  }

  public isEditForm(event: any) {
    this.isFormEdit = event;
  }

  public referencePageReferesh(event: any) {
    if (event) {
      this.getRenewalApplicationData();
    }
  }

  public statusCheck() {
    this.showRubricTab = false;
  }

  public isTrainingAlreadyExists(event: boolean) {
    if (event === true) {
      this.applicationsTabChange("renewal-training-information");
    }
  }

  public getComment(data: any, dateTime: string, editorTd: string) {
    let comment = "";
    for (const property in data) {
      if (property === "editedComments") {
        data[property].filter((data: any) => {
          if (
            data.dateTime === dateTime &&
            data.editorDetails.userId === editorTd
          ) {
            comment = data.comment;
          }
        });
      }
    }
    return comment;
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public informApplicant(form: string) {
    this.isLoading = true;

    const rejectedEmails = this.newlyRejectedSupervisor.map(
      (data) => data.email
    );
    renewalFormApi
      .informRejectedSupervisor(rejectedEmails, this.renewalPreviewForm.id)
      .then(() => {
        this.isInformedApplicant = true;
        this.newlyRejectedSupervisor = [];
        this.getRenewalApplicationData();
        this.$bvModal.hide("rejected-msg-sent-model");
      })
      .catch((error: any) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public cancelInform() {
    this.$bvModal.hide("rejected-msg-sent-model");
    this.isInformedApplicant = false;
  }

  public rightMoveTab() {
    const conent = document.getElementsByClassName("ref-lists")[0];
    if (conent) {
      this.tabScrollMove = utils.rightMoveTab(conent, 200);
    }
  }
  public leftMoveTab() {
    const conent = document.getElementsByClassName("ref-lists")[0];
    if (conent) {
      this.tabScrollMove = utils.leftMoveTab(conent, 200);
    }
  }

  public supervisorClickable(supervisorEmail: any, idx: number) {
    this.supervisorIndex = idx;
    this.activeSupervisor = this.renewalPreviewForm.employeeSummary.filter(
      (filter: any) => {
        if (filter.email === supervisorEmail) {
          return filter;
        }
      }
    );
    if (this.activeSupervisor[0].data) {
      this.isSupervisorData = true;
    } else {
      this.isSupervisorData = false;
    }
  }

  public namesJoin(data: any[], joinKey: string) {
    return utils.joinArrObj(data, joinKey);
  }

  public supervisorPageReferesh(event: any) {
    if (event) {
      this.refereshSupervisor = event;
      this.getRenewalApplicationData();
    }
  }

  public disableCommentandScoringSection(
    currentUserStatus: string,
    progressStatus: string,
    section: string
  ) {
    return utils.disableCommentandScoringSection(
      currentUserStatus,
      progressStatus,
      section
    );
  }

  public backUrl() {
    if (this.$route.query.isReport) {
      let filter = "";
      if (this.$route.query.ethnicity) {
        filter += `&ethnicity=${this.$route.query.ethnicity}`;
      }
      if (this.$route.query.gender) {
        filter += `&gender=${this.$route.query.gender}`;
      }
      if (this.$route.query.region) {
        filter += `&region=${this.$route.query.region}&regionId=${this.$route.query.regionId}`;
      }
      if (this.$route.query.age) {
        filter += `&age=${this.$route.query.age}&fromAge=${this.$route.query.fromAge}&toAge=${this.$route.query.toAge}`;
      }
      if (this.$route.query.education) {
        filter += `&education=${this.$route.query.education}`;
      }
      return `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}&type=${this.$route.query.type}&isReport=${this.$route.query.isReport}&fromDate=${this.$route.query.fromDate}&toDate=${this.$route.query.toDate}${filter}`;
    } else {
      return this.isPeerPreview
        ? `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}`
        : "/reviewer-dashboard/renewal";
    }
  }
  public onResend(activeUser: any) {
    this.isLoading = true;
    const payload = {
      email: activeUser.email,
      id: activeUser.applicationId,
    };
    renewalFormApi
      .employeeRenewalResendEmail(payload)
      .then(() => {
        this.isLoading = false;
        this.isEmpResend = true;
        this.$nextTick(() => {
          this.$bvModal.show("resend-email-center");
        });
      })
      .catch((error) => {
        this.isLoading = false;
      });
  }
  public getEditIndex(event: any) {
    if (event) {
      this.editIndex = event;
    }
  }
  public isUpdateForm(event: any) {
    this.updateReviewer = event;
    this.$nextTick(() => {
      this.$bvModal.show("update-email-center");
    });
  }
  public closePopup() {
    this.$bvModal.hide("update-email-center");
  }
}
