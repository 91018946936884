import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-signature-submission",
})
export default class SignatureSubmissionComponent extends Vue {
  $refs!: {
    signaturePad: HTMLFormElement;
  };
  @Prop()
  public signatureInvalid!: boolean;
  @Prop()
  public payloadData: any;
  @Prop()
  public certificatePayLoadData: any;
  @Prop()
  public signature: any;
  @Prop()
  public employmentPayLoadData: any;
  @Prop()
  public stateToStatepayLoadData: any;
  @Prop()
  public renewalPayLoadData: any;
  @Prop()
  public signatureType!: {
    payloadName: string;
    payloadSignatureKey: string;
    resetButton: boolean;
  };
  @Prop()
  public signatureLabelAndValidate!: {
    label: string;
    validate: boolean;
  };

  public currentRoute = "";
  public fileName: any;
  public isFile = false;
  public fileNameUri: any;
  public fileLink: any;
  public isForm = false;
  public initial_data = "";
  public visible = false;

  public created() {
    this.currentRoute = this.$route.path;
    if (this.currentRoute.split("/")[1] === "form") {
      this.isForm = true;
      let payloadStorage: any = localStorage.getItem(
        this.signatureType.payloadName
      );
      payloadStorage = JSON.parse(payloadStorage);
      this.fileName = { ...payloadStorage };
      this.fileNameUri =
        this.fileName[this.signatureType?.payloadSignatureKey]?.isSignature;
    } else {
      this.isForm = false;
      this.fileName = "";
      this.fileNameUri = this.signature.isSignature;
    }

    if (this.fileNameUri) {
      this.isFile = true;
    } else {
      this.isFile = false;
    }
  }

  public isEdit() {
    this.isFile = false;
    this.fileNameUri = "";
    this.fileName[this.signatureType.payloadSignatureKey].isSignature = "";
  }

  public reset() {
    this.$refs.signaturePad.clearSignature();
    this.$emit("input", "");
    this.fileNameUri = "";
    this.fileName[this.signatureType.payloadSignatureKey].isSignature = "";
  }

  public resetSign() {
    this.$emit("input", "");
    this.fileNameUri = "";
    this.isFile = false;
  }

  public onEnd() {
    const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
    if (!isEmpty) {
      this.$emit("input", data);
    }
  }
}
