import { LETTER_OF_REFERENCE_QUERY } from "@/graphql/letter-of-reference-query";
import { Component, Prop, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import {
  CprsLetterOfReference,
  FormFieldContents,
  FormNavbar,
} from "@/models/letter-of-reference/letter-of-reference.interface";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import MultiRadioButton from "../multi-radio-button/multi-radio-button.component.vue";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import { certificationFormApi } from "@/providers/apis/certification-part-two-form";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { STATUS } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "letter-of-reference-preview",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-multi-radio": MultiRadioButton,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    "b-form-textarea": InputTextAreaComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class LetterOfReferencePreviewComponent extends Vue {
  @Prop()
  public previewData!: any[];
  @Prop()
  public isReviewerPreviewForm: any;
  @Prop()
  public referenceSelectedIdx: any;
  @Prop()
  public applicationId!: string;
  @Prop()
  public activeReferralEmail!: string;
  @Prop()
  public progressStatus!: string;

  public applicationData: Array<CprsLetterOfReference> = [];
  public applicationNavData: FormNavbar[] = [];
  public formData: FormFieldContents[] = [];
  public formTitle = {
    referenceTitle: "",
    referenceSubTitle: "",
  };
  public isLoading = false;
  public toogleStatus = false;
  public referenceStatus = "";
  public storeReferenceStatus: any[] = [];
  public activeReferral: any;
  public isLargeLoading = false;
  public status: string[] = [STATUS.accepted, STATUS.notAccepted];
  public errorMessage = "";

  public created() {
    this.isLoading = true;
    this.$apollo
      .query({
        query: gql`query ${LETTER_OF_REFERENCE_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.cprsLetterOfReferences.data;
        this.formTitle = {
          referenceTitle: this.applicationData[0].attributes.referenceTitle,
          referenceSubTitle:
            this.applicationData[0].attributes.referenceSubTitle,
        };
        this.applicationNavData =
          this.applicationData[0].attributes.referenceNavBar;
        this.formData = this.applicationData[0].attributes.referenceFormField;
        this.isLoading = false;
      });

    for (let index = 0; index < this.previewData.length; index++) {
      if (this.storeReferenceStatus.length) {
        this.storeReferenceStatus.push({
          index: index,
          email: this.previewData[index].email,
          status: this.previewData[index].status,
        });
      } else {
        this.storeReferenceStatus = [
          {
            index: index,
            email: this.previewData[index].email,
            status: this.previewData[index].status,
          },
        ];
      }
    }
    this.activeReferral = this.previewData.find((data: any) => {
      return data.email === this.activeReferralEmail;
    });
    this.referenceNameAndAddressBuild();
  }

  public referenceNameAndAddressBuild() {
    //for Reference Details
    this.activeReferral.data.letterOfReferenceReferenceDetails.referenceFullName = `${this.activeReferral.data.letterOfReferenceReferenceDetails.referenceFirstName} ${this.activeReferral.data.letterOfReferenceReferenceDetails.referenceLastName}`;
    const fullAddress = utils.addressBuilder(
      this.activeReferral.data,
      "letterOfReferenceReferenceDetails",
      "default"
    );
    this.activeReferral.data.letterOfReferenceReferenceDetails.fullAddress =
      fullAddress;

    //Applicant Details
    this.activeReferral.data.letterOfReferenceApplicantDetails.applicantFullName = `${this.activeReferral.data.letterOfReferenceApplicantDetails.applicantFirstName} ${this.activeReferral.data.letterOfReferenceApplicantDetails.applicantLastName}`;
  }
  checkfieldRefrence(inputName: string) {
    if (!this.isReviewerPreviewForm && inputName === "referenceFullName") {
      return true;
    } else if (
      this.isReviewerPreviewForm &&
      (inputName === "referenceFirstName" ||
        inputName === "referenceLastName" ||
        inputName === "addressLine1" ||
        inputName === "addressLine2" ||
        inputName === "state" ||
        inputName === "city" ||
        inputName === "zip" ||
        inputName === "applicantFirstName" ||
        inputName === "applicantLastName")
    ) {
      return true;
    } else {
      return false;
    }
  }

  public async selectStatus(status: string, referenceEmail: string) {
    this.referenceStatus = status;
    this.isLargeLoading = true;
    const referencePayload = {
      id: this.applicationId,
      email: referenceEmail,
      status: status,
    };

    this.toogleStatus = !this.toogleStatus;
    await certificationFormApi
      .updateReferenceStatus(referencePayload)
      .then((data) => {
        this.$emit("referenceUpdated", {
          email: referenceEmail,
          idx: this.referenceSelectedIdx,
        });
        this.isLargeLoading = false;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLargeLoading = false;
      });
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
