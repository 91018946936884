import { Component, Vue } from "vue-property-decorator";
import ReviewerCertificationPartOnePreviewForm from "../reviewer-certification-part-one-preview-form/reviewer-certification-part-one-preview-form.component.vue";
import ReviewerCertificationPartTwoPreviewForm from "../reviewer-certification-part-two-preview-form/reviewer-certification-part-two-preview-form.component.vue";
import RenewalPreviewApplicationComponent from "../reviewer-renewal-preview/reviewer-renewal-preview.component.vue";
import ReviewerStatetoStatePreview from "../reviewer-state-to-state-preview/reviewer-state-to-state-preview.component.vue";
@Component({
  name: "reviewer-peers-preview-application",
  components: {
    ReviewerCertificationPartOnePreviewForm,
    ReviewerCertificationPartTwoPreviewForm,
    RenewalPreviewApplicationComponent,
    ReviewerStatetoStatePreview,
  },
})
export default class ReviewerPeersPreviewApplication extends Vue {
  public applicationName: any;

  public async created() {
    this.applicationName = this.$route.query.application;
  }
}
