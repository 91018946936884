export const certificates = [
  {
    label: "Certified Peer Recovery Specialist",
    validUpto: "26 July 2022",
    renew: true,
    type: "cprs",
  },
  {
    label: "Certified Young Adult Peer Support Specialist",
    validUpto: "26 March 2023",
    renew: false,
    type: "cyapss",
  },
];

export const trainingModulesLists = [
  {
    title: "Beginner CPRS Training  Module 1",
    time: 3,
    trainingType: "In-Person Training",
    trainingTypeSlug: "in-person-training",
  },
  {
    title: "Beginner CPRS Training  Module 2",
    time: 3,
    trainingType: "In-Person Training",
    trainingTypeSlug: "in-person-training",
  },
  {
    title: "Beginner CPRS Training  Module 3",
    time: 3,
    trainingType: "Online Training",
    trainingTypeSlug: "online-training",
  },
];

export const educationCertificate = [
  {
    title: "Building Resilient Families",
    programTitle: "Strategies for Family Support Specialists",
    description:
      "Learn effective strategies to help families facing various challenges build resilience and overcome adversity.",
    date: "26 March 2023",
    isCertificate: true,
  },
  {
    title: "Building Resilient Families",
    programTitle: "Strategies for Family Support Specialists",
    description:
      "Learn effective strategies to help families facing various challenges build resilience and overcome adversity.",
    date: "26 March 2023",
    isCertificate: true,
  },
];
