export const exploreModulesLists = [
  {
    title: "Beginner CPRS Training  Module 1",
    time: 3,
    trainingType: "In-Person Training",
  },
  {
    title: "Beginner CPRS Training  Module 2",
    time: 3,
    trainingType: "In-Person Training",
  },
  {
    title: "Beginner CPRS Training  Module 3",
    time: 3,
    trainingType: "Online Training",
  },
  {
    title: "Beginner CPRS Training  Module 4",
    time: 3,
    trainingType: "Online Training",
  },
  {
    title: "Beginner CPRS Training  Module 5",
    time: 3,
    trainingType: "In-Person Training",
  },
  {
    title: "Beginner CPRS Training  Module 6",
    time: 3,
    trainingType: "In-Person Training",
  },
  {
    title: "Beginner CPRS Training  Module 7",
    time: 3,
    trainingType: "Online Training",
  },
  {
    title: "Beginner CPRS Training  Module 8",
    time: 3,
    trainingType: "Online Training",
  },
];
