import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { ApplicationPagesData } from "./application-pages.interface";
import { APPLICATION_PAGES_QUERY } from "@/graphql/application-pages-query ";
import MoreInformationComponent from "../more-information/more-information.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";

@Component({
  components: {
    MoreInformationComponent,
    AppLoaderComponent,
  },
})
export default class ApplicationPagesComponent extends Vue {
  public applicationPagesData: ApplicationPagesData[] = [];
  public currentRoute = "";
  public param = "";
  public headerImage = {
    name: "",
    url: "",
  };
  public isLoading = true;
  public approvedVisible = false;
  public unApprovedVisible = false;

  public async created() {
    this.currentRoute = this.$route.path;
    this.param = this.$route.query.form.toString();
    await this.$apollo
      .query({
        query: gql`query ${APPLICATION_PAGES_QUERY}`,
      })
      .then((result) => {
        this.applicationPagesData = result.data.applicationPages.data;
        this.isLoading = false;
        this.headerImage = {
          name: this.applicationPagesData[0].attributes.header_image.data
            .attributes.name,
          url: this.applicationPagesData[0].attributes.header_image.data
            .attributes.url,
        };
      });
  }

  public navigateTo(url: string) {
    if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      this.$router.push(url);
    }
  }

  public buttonClick(collapseId: string) {
    if (collapseId === "collapse-1") {
      if (this.approvedVisible) {
        this.approvedVisible = false;
      } else {
        this.approvedVisible = true;
      }
      this.unApprovedVisible = false;
    } else {
      if (this.unApprovedVisible) {
        this.unApprovedVisible = false;
      } else {
        this.unApprovedVisible = true;
      }
      this.approvedVisible = false;
    }
  }
}
