export const CPRS_EMPLOYMENT_SUMMARIES_QUERY = `CprsEmploymentSummaries {  
    cprsEmploymentSummaries(sort: "id:ASC"){
        data{
            id
            attributes{
                employmentSummaryTitle
                employmentSummarySubTitle
                employmentFormFields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
