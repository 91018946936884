import Vue from "vue";
import App from "./app/app.vue";
import router from "./router/router";
import store from "./store";
import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import VeeValidate from "vee-validate";
import VueSignaturePad from "vue-signature-pad";
import "./filter";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { appConfigAPI } from "./providers/app/app-config";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApollo);
Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
Vue.use(VueSignaturePad);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

(async () => {
  await appConfigAPI.getAppConfiguration().then((appConfig: any) => {
    localStorage.setItem("APP_CONFIG", JSON.stringify(appConfig));
  });

  new Vue({
    router,
    store,
    provide: apolloProvider.provide(),
    render: (h) => h(App),
  }).$mount("#app");
})();
