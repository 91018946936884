import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-checkbox-input",
})
export default class InputOtherComponent extends Vue {
  // ----------------------------------------------------------------
  // Properties

  public text!: string;
  public previewPayLoadData: any;

  @Prop()
  public value!: string;
  @Prop()
  public payloadData: any;
  @Prop()
  public payloadDataAll: any;
  @Prop()
  public previewPayload: any;
  @Prop()
  public isTextAreaDisable: any;

  public get isShowInput() {
    if (this.payloadData) {
      const { ethnicHeritage = [], highSchool = [] } = this.payloadData;
      return (
        ethnicHeritage.indexOf("Not Listed (Please Specify)" || "Other") !==
          -1 || highSchool.indexOf("Other") !== -1
      );
    } else if (
      this.previewPayload.certificationPartOneBasicDetails &&
      this.previewPayload.certificationPartOneEducationExperience
    ) {
      const { ethnicHeritage = [] } =
        this.previewPayload.certificationPartOneBasicDetails;
      return ethnicHeritage.indexOf("Not Listed (Please Specify)") !== -1;
    }
  }

  public created() {
    this.text = this.value;
  }
  public updated() {
    if (!this.isShowInput) {
      this.text = "";
    }
    this.$emit("input", this.text);
  }
  onChangeInput() {
    this.$emit("input", this.text);
  }
}
