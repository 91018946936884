import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import {
  CertificationPartTwoForm,
  FormFieldContents,
  FormNavbar,
  addReference,
  addSupervisor,
} from "@/models/certification-part-two-form/certification-part-two-form.interface";
import { CPRS_CERTIFICATION_APPLICATION_QUERY } from "@/graphql/cprs-certification-application-query";
import { utils } from "@/utils/utils";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import { certificationFormApi } from "@/providers/apis/certification-part-two-form";
import {
  reference,
  summary,
} from "@/models/reviewer-part-two-page/reviewer-part-two-page.interface";
import MultiRadioButton from "../multi-radio-button/multi-radio-button.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import moment from "moment";
import { User } from "@/models/common-interface/common.interface";
import EditHistoryComponent from "../edit-history/edit-history.component.vue";
import ScopeOfActivity from "../scope-of-activity/scope-of-activity-component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
@Component({
  name: "certification-preview-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-multi-radio": MultiRadioButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    "b-form-textarea": InputTextAreaComponent,
    "code-of-ethics-and-scope-of-activity": ScopeOfActivity,
    AppLoaderComponent,
    EditHistoryComponent,
    ErrorPopupComponent,
  },
})
export default class CertificationPartTwoPreviewForm extends Vue {
  @Prop()
  public certificatePayLoadDataAll: any;
  @Prop()
  public previewData: any;
  @Prop()
  public tabSelect!: string;
  @Prop()
  public isReviewerPreviewForm!: string;
  @Prop()
  public referenceData!: reference[];
  @Prop()
  public supervisorData!: summary[];
  @Prop()
  public applicationId!: string;
  @Prop()
  public mostLastRecord: any;
  @Prop()
  public progressStatus: any;
  @Prop()
  public checkFormValidation!: boolean;
  @Prop()
  public isUserApplication!: boolean;
  @Prop()
  public isMigrationForm!: boolean;

  public applicationData: Array<CertificationPartTwoForm> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public formData: Array<FormFieldContents> = [];
  public limitationList: any;
  public previewPayload: any = {};
  public currentRoute = "";
  public referenceIndex = 0;
  public isMobileView = false;
  public isEditReviewerForm = false;
  public statusName = "";
  public toogleStatus = false;
  public isTextAreaDisable = true;
  public disabled = true;
  public buildReferenceObjcet: any = {};
  public storeReferenceEmail: string[] = [];
  public isLoading = false;
  public checkValidation = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public isLargeLoading = false;
  public editIndex!: number;
  public emailDuplicateIndexs: number[] = [];
  public addReferenceForm: addReference[] = [];
  public supervisorFormFields: addSupervisor[] = [];
  public isReferesh = "false";
  public hideDiv: string[] = ["startDate", "endDate"];
  public checkField: string[] = ["stillEmployed"];
  public originalData: any = {};
  public editApplication = false;
  public editParentSlug = "";
  public isInvalidComment = false;
  public userDetails: User = {} as User;
  public isClickSavebtn = "false";
  public isConfirm = false;
  public checkSOAValid = false;
  public validSOA = true;
  public referenceList: any[] = [];
  public supervisorList: any[] = [];
  public errorMessage = "";
  public isEditReference = false;
  public currentIndex!: number;
  public created() {
    this.userDetails = utils.getUserDetails();
    this.isLoading = true;
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.currentRoute = this.$route.path;
    let payloadStorage: any = localStorage.getItem(
      "partTwoCertificationPayload"
    );
    if (payloadStorage && this.currentRoute.split("/")[1] === "form") {
      payloadStorage = JSON.parse(payloadStorage);
      this.previewPayload = { ...payloadStorage };
      this.fullNameAndAddressBuild(this.previewPayload);
    } else {
      this.previewPayload = this.previewData;
      this.fullNameAndAddressBuild(this.previewPayload);
    }
    this.$apollo
      .query({
        query: gql`query ${CPRS_CERTIFICATION_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.applicationData =
          result.data.cprsCertificationApplicationForms.data;
        this.applicationNavData =
          this.applicationData[0].attributes.certificationNavbar;
        this.formData =
          this.applicationData[0].attributes.certification_form_fields;
        this.limitationList = this.formData.length - 1;

        for (let index = 0; index < this.checkField.length; index++) {
          this.fieldShowAndHide(this.checkField[index], true);
        }

        if (
          this.previewPayload.certificationPartTwoSupervisorInformation?.length
        ) {
          this.showForm("certificationPartTwoSupervisorInformation");
          const supervisorEmail: any[] = [];
          this.supervisorData &&
            this.supervisorData.forEach((data: any) => {
              supervisorEmail.push(data.email);
            });
          this.previewPayload.certificationPartTwoSupervisorInformation.map(
            (supervisor: any) => {
              const refIdx = supervisorEmail.findIndex(
                (supervisorEmail: string) => {
                  return supervisorEmail === supervisor.supervisorEmail;
                }
              );
              if (refIdx > -1) {
                this.supervisorList.push(this.supervisorData[refIdx]);
              }
            }
          );
        }

        if (
          this.previewPayload.certificationPartTwoRefererenceInformation?.length
        ) {
          this.showForm("certificationPartTwoRefererenceInformation");
          const referenceEmail: any[] = [];
          this.referenceData &&
            this.referenceData.forEach((data: any) => {
              referenceEmail.push(data.email);
            });

          this.previewPayload.certificationPartTwoRefererenceInformation.map(
            (reference: any) => {
              const refIdx = referenceEmail.findIndex((refEmail: string) => {
                return refEmail === reference.referenceEmailAddress;
              });
              if (refIdx > -1) {
                this.referenceList.push(this.referenceData[refIdx]);
              }
            }
          );
        }
      });
    this.isLoading = false;
    this.originalData = JSON.parse(JSON.stringify(this.previewPayload));
  }

  public onCilckSave() {
    this.checkValidation = true;
    this.checkSOAValid = !this.checkSOAValid;
    this.duplicateValueIdx(
      "certificationPartTwoRefererenceInformation",
      "referenceEmailAddress",
      true
    );

    if (this.isMigrationForm) {
      this.editApplication = true;
      this.isClickSavebtn = this.changeStateForFieldUpdate(this.isClickSavebtn);
    }
    if (
      this.previewPayload.certificationPartTwoRefererenceInformation &&
      this.previewPayload.certificationPartTwoRefererenceInformation.length
    ) {
      const referenceData: { oldEmail: any; newEmail: any }[] = [];
      if (
        this.previewPayload.certificationPartTwoRefererenceInformation[
          this.editIndex
        ]?.referenceEmailAddress !==
        this.originalData.certificationPartTwoRefererenceInformation[
          this.editIndex
        ]?.referenceEmailAddress
      ) {
        const data = {
          oldEmail:
            this.originalData.certificationPartTwoRefererenceInformation[
              this.editIndex
            ].referenceEmailAddress,
          newEmail:
            this.previewPayload.certificationPartTwoRefererenceInformation[
              this.editIndex
            ].referenceEmailAddress,
        };
        referenceData.push(data);
      }
      if (referenceData && referenceData.length) {
        this.previewPayload.referralEmails = referenceData;
      }
    }
    if (
      this.previewPayload.certificationPartTwoSupervisorInformation &&
      this.previewPayload.certificationPartTwoSupervisorInformation.length
    ) {
      const supervisorEmails = [];
      if (
        this.previewPayload.certificationPartTwoSupervisorInformation[
          this.editIndex
        ]?.supervisorEmail !==
        this.originalData.certificationPartTwoSupervisorInformation[
          this.editIndex
        ]?.supervisorEmail
      ) {
        const data = {
          oldEmail:
            this.originalData.certificationPartTwoSupervisorInformation[
              this.editIndex
            ].supervisorEmail,
          newEmail:
            this.previewPayload.certificationPartTwoSupervisorInformation[
              this.editIndex
            ].supervisorEmail,
        };
        supervisorEmails.push(data);
      }
      if (supervisorEmails && supervisorEmails.length) {
        this.previewPayload.supervisorEmails = supervisorEmails;
      }
    }
    this.$validator.validate().then((validate) => {
      if (validate && this.validSOA) {
        const duplicateSupervisorEmail =
          this.previewPayload.certificationPartTwoSupervisorInformation.filter(
            (supervisor: any) => {
              if (
                supervisor.supervisorEmail.toLowerCase() ===
                this.previewPayload.certificationPartTwoApplicationForm.email.toLowerCase()
              ) {
                return supervisor;
              }
            }
          );
        if (duplicateSupervisorEmail.length) {
          this.errorMessage =
            "Applicant and supervisor email addresses cannot be the same. Please provide distinct email addresses for supervisor and reference.";
          return;
        }

        const duplicateEmail =
          this.previewPayload.certificationPartTwoRefererenceInformation.filter(
            (reference: any) => {
              if (
                reference.referenceEmailAddress.toLowerCase() ===
                this.previewPayload.certificationPartTwoApplicationForm.email.toLowerCase()
              ) {
                return reference;
              }
            }
          );
        if (duplicateEmail.length) {
          this.errorMessage =
            "Applicant and reference email addresses cannot be the same. Please provide distinct email addresses for supervisor and reference.";
          return;
        }

        const referenceDuplicateEmail =
          this.previewPayload.certificationPartTwoRefererenceInformation.filter(
            (reference: any) => {
              return this.previewPayload.certificationPartTwoSupervisorInformation.find(
                (supervisor: any) => {
                  return (
                    supervisor.supervisorEmail.toLowerCase() ===
                    reference.referenceEmailAddress.toLowerCase()
                  );
                }
              );
            }
          );
        if (referenceDuplicateEmail.length) {
          this.errorMessage =
            "Supervisor and reference email addresses cannot be the same. Please provide distinct email addresses for supervisor and reference.*";
          return;
        }

        if (
          this.tabSelect === "partTwo-references" ||
          this.tabSelect === "partTwo-supervisor-details"
        ) {
          const originalData =
            this.tabSelect === "partTwo-references"
              ? this.originalData.certificationPartTwoRefererenceInformation
              : this.originalData.certificationPartTwoSupervisorInformation;

          const previewPayload =
            this.tabSelect === "partTwo-references"
              ? this.previewPayload.certificationPartTwoRefererenceInformation
              : this.previewPayload.certificationPartTwoSupervisorInformation;
          const duplicateReferenceEmail = originalData.filter(
            (reference: any) => {
              if (this.tabSelect === "partTwo-references") {
                if (
                  this.previewPayload &&
                  this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails[0] &&
                  reference.referenceEmailAddress.toLowerCase() ===
                    this.previewPayload.referralEmails[0].newEmail.toLowerCase()
                ) {
                  return reference;
                }
              } else {
                if (
                  this.previewPayload &&
                  this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails[0] &&
                  reference.supervisorEmail.toLowerCase() ===
                    this.previewPayload.supervisorEmails[0].newEmail.toLowerCase()
                ) {
                  return reference;
                }
              }
            }
          );
          if (duplicateReferenceEmail.length) {
            const text =
              this.tabSelect === "partTwo-references"
                ? "Reference"
                : "Supervisor";
            this.errorMessage = `${text} email id must be different *`;
            return;
          }
        }
        if (this.currentRoute.split("/")[2] === "my-application") {
          const id = this.applicationId;
          this.isLargeLoading = true;
          certificationFormApi
            .updateReviewerPeerPartTwoForm(this.previewPayload, id)
            .then(() => {
              this.isLargeLoading = false;
              this.isEditReviewerForm = false;
              this.disabled = true;
              if (
                (this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails.length) ||
                (this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails.length)
              ) {
                let type;
                let email;
                if (
                  this.previewPayload.referralEmails &&
                  this.previewPayload.referralEmails.length
                ) {
                  type = "Reference";
                  email = this.previewPayload.referralEmails[0].newEmail;
                }
                if (
                  this.previewPayload.supervisorEmails &&
                  this.previewPayload.supervisorEmails.length
                ) {
                  type = "Supervisor";
                  email = this.previewPayload.supervisorEmails[0].newEmail;
                }
                const data = {
                  isUpdateData: false,
                  isUpdateEmail: true,
                  type: type,
                  email: email,
                };
                this.$emit("getPeerUpdatedData", data);
              } else {
                const data = {
                  isUpdateData: true,
                  isUpdateEmail: false,
                };
                this.$emit("getPeerUpdatedData", data);
              }
              this.$emit("isEditForm", false);
              this.$emit("editIndex", this.editIndex);
              this.previewPayload.referralEmails = [];
              this.previewPayload.supervisorEmails = [];
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          this.editApplication = true;
          this.isClickSavebtn = this.changeStateForFieldUpdate(
            this.isClickSavebtn
          );
        }
      } else {
        setTimeout(() => {
          const formLabel = document.getElementsByClassName("form-label");
          const el = document.getElementsByClassName("is-invalid")[0];
          for (let i = 0; i < formLabel.length; i++) {
            if (formLabel[i].contains(el)) {
              formLabel[i].scrollIntoView({ behavior: "smooth" });
            }
          }
        }, 100);
      }
    });
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public scopeOfActivityValidation(event: boolean) {
    this.validSOA = event;
  }

  onCilckEdit(index: number) {
    this.editIndex = index;
    this.isEditReviewerForm = true;
    this.disabled = false;
    this.$emit("isEditForm", true);
  }

  onCancelEdit() {
    this.isEditReviewerForm = false;
    this.editApplication = false;
    this.disabled = true;
    if (this.currentRoute.split("/")[2] === "my-application") {
      this.$emit("getPeerUpdatedData", false);
    } else {
      this.$emit("getUpdatedData", true);
      this.$emit("isEditForm", false);
    }
  }

  public isRevertedChanges(event: boolean) {
    if (event === true) {
      this.$emit("getUpdatedData", true);
      this.$emit("isEditForm", false);
    }
  }

  public editedPreviewPayloadData(event: any) {
    if (event) {
      this.previewPayload = event;
    }
  }

  public isConfirmChanges(event: boolean) {
    this.isConfirm = event;
    if (this.isConfirm) {
      const id = this.$route.path.split("/")[3];
      this.isLargeLoading = true;
      this.checkValidation = false;
      this.previewPayload.certificationPartTwoApplicationForm.fullName = "";
      this.previewPayload.certificationPartTwoApplicationForm.fullAddress = "";
      if (!this.isMigrationForm) {
        for (
          let i = 0;
          i <
          this.previewPayload.certificationPartTwoRefererenceInformation.length;
          i++
        ) {
          this.previewPayload.certificationPartTwoRefererenceInformation[
            i
          ].fullName = "";
        }
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .backupEmail === ""
        ) {
          this.previewPayload.certificationPartTwoApplicationForm.backupEmail =
            null;
        }
      }
      certificationFormApi
        .updateReviewerPartTwoForm(this.previewPayload, id)
        .then(() => {
          this.isLargeLoading = false;
          this.isEditReviewerForm = false;
          this.disabled = true;
          if (
            (this.previewPayload.referralEmails &&
              this.previewPayload.referralEmails.length) ||
            (this.previewPayload.supervisorEmails &&
              this.previewPayload.supervisorEmails.length)
          ) {
            let type;
            let email;
            if (
              this.previewPayload.referralEmails &&
              this.previewPayload.referralEmails.length
            ) {
              type = "Reference";
              email = this.previewPayload.referralEmails[0].newEmail;
            }
            if (
              this.previewPayload.supervisorEmails &&
              this.previewPayload.supervisorEmails.length
            ) {
              type = "Supervisor";
              email = this.previewPayload.supervisorEmails[0].newEmail;
            }
            const data = {
              isUpdateData: false,
              isUpdateEmail: true,
              type: type,
              email: email,
            };
            this.$emit("isUpdateForm", data);
          } else {
            const data = {
              isUpdateData: true,
              isUpdateEmail: false,
            };
            this.$emit("isUpdateForm", data);
          }
          this.$emit("getUpdatedData", true);
          this.$emit("isEditForm", false);
          this.$emit("editIndex", this.editIndex);
          this.previewPayload.referralEmails = [];
          this.previewPayload.supervisorEmails = [];
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
          let emailHeaderMessage;
          let emailMessage;
          if (error.status === 409) {
            emailHeaderMessage = "Conflict Error";
            emailMessage =
              this.tabSelect === "partTwo-supervisor-details"
                ? "Employment summary form"
                : "Reference form" + "already submitted ";
          }
          if (error.status === 401 || error.status === 403) {
            emailHeaderMessage = "Unauthorized Error";
            emailMessage = "Link Expired or invalid";
          }
          const data = {
            isUpdateData: true,
            emailHeaderMessage: emailHeaderMessage,
            emailMessage: emailMessage,
          };
          this.$emit("isUpdateForm", data);
        });
    }
  }

  public closeSidebar() {
    this.editApplication = false;
  }

  editForm(slug: string) {
    this.$emit("applicationSlug", slug);
  }

  updatePreview() {
    this.$emit("certificatePayLoadDataAll", this.previewPayload);
  }

  public references(index: number) {
    this.referenceIndex = index;
  }

  public referenceStatusUpdate(referenceEmail: string, status: string) {
    this.buildReferenceObjcet = Object.assign(
      { [referenceEmail]: status },
      this.buildReferenceObjcet
    );
    this.storeReferenceEmail = Object.keys(this.buildReferenceObjcet);
  }

  public fieldUpdate(inputName: string) {
    if (!this.isEditReviewerForm) {
      const nameNotVisiable = [
        "firstName",
        "lastName",
        "addressLine1",
        "addressLine2",
        "state",
        "city",
        "zip",
        "supervisorFirstName",
        "supervisorLastName",
        "supervisorAddressLine1",
        "supervisorAddressLine2",
        "supervisorState",
        "supervisorCity",
        "supervisorZip",
      ];
      return nameNotVisiable.includes(inputName);
    } else {
      const nameNotVisiable = ["fullName", "supervisorFullName", "fullAddress"];
      return nameNotVisiable.includes(inputName);
    }
  }

  public fullNameAndAddressBuild(data: any) {
    this.previewPayload.certificationPartTwoApplicationForm.fullName = `${data.certificationPartTwoApplicationForm.firstName} ${data.certificationPartTwoApplicationForm.lastName}`;
    const fullAddress = utils.addressBuilder(
      this.previewPayload,
      "certificationPartTwoApplicationForm",
      "default"
    );
    this.previewPayload.certificationPartTwoApplicationForm.fullAddress =
      fullAddress;
    if (this.previewPayload.certificationPartTwoSupervisorInformation?.length) {
      for (
        let idx = 0;
        idx <
        this.previewPayload.certificationPartTwoSupervisorInformation.length;
        idx++
      ) {
        this.previewPayload.certificationPartTwoSupervisorInformation[
          idx
        ].supervisorFullName = `${data.certificationPartTwoSupervisorInformation[idx].supervisorFirstName} ${data.certificationPartTwoSupervisorInformation[idx].supervisorLastName}`;
        const supervisorFullAddress = utils.addressBuilder(
          this.previewPayload,
          "certificationPartTwoSupervisorInformation",
          "supervisor",
          true,
          idx
        );
        this.previewPayload.certificationPartTwoSupervisorInformation[
          idx
        ].supervisorFullAddress = supervisorFullAddress;
      }
    }
    if (!this.isMigrationForm) {
      for (
        let i = 0;
        i <
        this.previewPayload.certificationPartTwoRefererenceInformation.length;
        i++
      ) {
        this.previewPayload.certificationPartTwoRefererenceInformation[
          i
        ].fullName = `${this.previewPayload.certificationPartTwoRefererenceInformation[i].firstName} ${this.previewPayload.certificationPartTwoRefererenceInformation[i].lastName}`;
      }
    }
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public showForm(formName: string) {
    const formData = this.previewPayload[formName];
    if (formData.length) {
      if (formName === "certificationPartTwoRefererenceInformation") {
        this.addReferenceForm = [];
      }
      if (formName === "certificationPartTwoSupervisorInformation") {
        this.supervisorFormFields = [];
      }
      formData.forEach(() => {
        if (formName === "certificationPartTwoRefererenceInformation") {
          this.addReferenceForm.push(this.formData[2].fields.multiForm[0]);
        }
        if (formName === "certificationPartTwoSupervisorInformation") {
          this.supervisorFormFields.push(
            this.applicationData[0].attributes.certification_form_fields[1]
              .fields.formInputs[0]
          );
        }
      });
    }
  }

  public pushForm(formName: string) {
    if (formName === "certificationPartTwoRefererenceInformation") {
      this.addReferenceForm.push(this.formData[2].fields.multiForm[0]);
      this.previewPayload.certificationPartTwoRefererenceInformation.push({
        firstName: "",
        lastName: "",
        agencyOrganization: "",
        yourPosition: "",
        referencePhoneNumber: "",
        referenceEmailAddress: "",
        reference:
          this.previewPayload.certificationPartTwoRefererenceInformation
            .length + 1,
      });
    }
  }

  public duplicateValueIdx(
    formName: string,
    emailFieldName: string,
    isEmail = false
  ) {
    let isCheck = false;
    const data = this.previewPayload[formName];
    if (!data?.length) return false;
    for (let i = 0; i < data.length; i++) {
      for (let j = 1; j < data.length; j++) {
        if (data[i][emailFieldName] && data[j][emailFieldName]) {
          const email_i = isEmail
            ? data[i][emailFieldName].toLowerCase()
            : data[i][emailFieldName];

          const email_j = isEmail
            ? data[j][emailFieldName].toLowerCase()
            : data[j][emailFieldName];
          if (i !== j && email_i === email_j) {
            isCheck = true;
            this.emailDuplicateIndexs.push(i);
          } else {
            if (isCheck != true) {
              this.emailDuplicateIndexs = [];
            }
          }
        }
      }
    }
  }

  public fieldShowAndHide(groupName = "", initial = false) {
    switch (groupName) {
      case "stillEmployed":
        if (
          this.previewPayload.certificationPartTwoApplicationForm
            .stillEmployed === "No"
        ) {
          if (this.hideDiv.indexOf("endDate") > -1) {
            this.hideDiv.splice(this.hideDiv.indexOf("endDate"), 1);
          }
        } else {
          if (!this.hideDiv.includes("endDate") && !initial) {
            this.hideDiv.push("endDate");
            this.previewPayload.certificationPartTwoApplicationForm.endDate =
              "";
          } else {
            return;
          }
        }
        break;
      default:
        break;
    }
  }

  @Watch("checkFormValidation")
  public validation() {
    this.checkValidation = true;
    this.checkSOAValid = !this.checkSOAValid;
    this.$validator.validateAll().then((validate: any) => {
      if (!this.isMigrationForm) {
        if (validate && this.validSOA) {
          this.$emit("isValidForm", true);
        } else {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
          this.$emit("isValidForm", false);
        }
      }
    });
  }

  // This method for change status word for class

  public statusWordChange(status: string) {
    return utils.statusCaseChange(status);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
  public onEditReference(currentIndex: any) {
    this.isEditReference = true;
    this.editIndex = currentIndex ? currentIndex : 0;
    this.isEditReviewerForm = true;
    this.disabled = false;
    this.$emit("isEditForm", true);
  }
}
