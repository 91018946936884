export const APPLICANT_CONTINUING_EDUCATION = `UserContinueEducation{
    userContinueEducations {
        data {
            id
            attributes {
                parent_slug
                form_fields
                form_name
            }
        }
    }
}`;
