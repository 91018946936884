import { utils } from "@/utils/utils";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-textarea-input",
})
export default class InputTextAreaComponent extends Vue {
  // ----------------------------------------------------------------
  // Properties
  @Prop()
  public value!: string;
  @Prop()
  public textAreaMaxlength!: string;
  @Prop()
  public isTextAreaDisable: any;
  @Prop()
  public formType!: string;
  @Prop()
  public editForm!: boolean;

  public text!: string;
  public isDisable = false;

  public created() {
    this.text = this.value;
    this.isDisable = utils.disableField(this.editForm, this.formType);
  }

  public onChangeInput() {
    this.$emit("input", this.text);
  }
}
