import {
  STATUS,
  User,
  editHistoryData,
  applicationStatusHistory,
  editChangesData,
} from "@/models/common-interface/common.interface";
import { commonService } from "@/providers/services/common-service";
import { utils } from "@/utils/utils";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import ScoreRubicFormComponent from "../score-rubic-form/score-rubic-form.component.vue";
import PDFViewerComponent from "../pdf-viewer/pdf-viewer.component.vue";
import { ReviewerStatetoStateApplicationInterface } from "@/models/reviewer-state-to-state-page/reviewer-state-to-state-page.interface";
import { stateToStateFormApi } from "@/providers/apis/state-to-state-form";
import StateToStatePreviewFormComponent from "../cprs-state-to-state-preview-form/cprs-state-to-state-preview.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "reviewer-state-to-state-preview",
  components: {
    AppLoaderComponent,
    ScoreRubicFormComponent,
    PDFViewerComponent,
    StateToStatePreviewFormComponent,
    ErrorPopupComponent,
  },
})
export default class ReviewerStatetoStatePreview extends Vue {
  @Prop()
  public applicationPreviewForm!: any;

  @Prop()
  public isPeerPreview!: any;

  public statetoStateApplicationId = "";
  public statetoStateData: ReviewerStatetoStateApplicationInterface =
    {} as ReviewerStatetoStateApplicationInterface;
  public isLoading = true;
  public isMobileView = false;
  public formSelected = "";
  public headerHeight = 0;
  public sectionTabHeight = 0;
  public mobileSectionTabHeight = 0;
  public tabSelected = "state-to-state-basic-details";
  public applicantFullName = "";
  public toogleStatus = false;
  public approveStatus = "";
  public historys: applicationStatusHistory[] = [];
  public approveComment = "";
  public userDetails!: User;
  public commentEventNone = false;
  public mostLastRecord: any = null;
  public isFormEdit = false;
  public isApproveStatus = true;
  public isSubmit = false;
  public showCommentBox = false;
  public showCommentBoxWithDisable = false;
  public showRubricTab = false;
  public formType = "";
  public PDFApplicationPrintName = "";
  public source = "";
  public isUpdatePageNumber = false;
  public editedUserDetails: editHistoryData[] = [];
  public tooglebutton = false;
  public collapseIdx = "";
  public filesEdit = ["certificateFiles"];
  public isFormValid = false;
  public validForm = true;
  public isUserApplication = false;
  public applicationReceivedDate = "";
  public isMigrationForm = false;
  public errorMessage = "";
  public finalizedStatus: string[] = [STATUS.accepted, STATUS.notAccepted];

  public async created() {
    this.formType = this.$route.query.formType?.toString();
    this.userDetails = utils.getUserDetails();
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.statetoStateApplicationId = this.$route.path.split("/")[3];
    if (this.$route.path.split("/")[1] === "reviewer-dashboard") {
      this.isUserApplication = false;
      await this.getStatetoStateApplicationData();
      this.formSelection("application-form", true);
    } else {
      this.isUserApplication = true;
      await this.getUserApplicationData();
      this.formSelection("application-form", true);
    }
  }

  public getUserApplicationData() {
    if (this.applicationPreviewForm.submittedMode === "migration") {
      this.isMigrationForm = true;
    }
    this.statetoStateData = this.applicationPreviewForm;
    this.applicantFullName = `${this.statetoStateData.data.stateToStateBasicDetails.firstName} ${this.statetoStateData.data.stateToStateBasicDetails.lastName}`;
    this.statetoStateData.data.stateToStateBasicDetails.fullName =
      this.applicantFullName;
    const fullAddress = utils.addressBuilder(
      this.statetoStateData.data,
      "stateToStateBasicDetails",
      "default"
    );
    this.statetoStateData.data.stateToStateBasicDetails.fullAddress =
      fullAddress;
    this.statetoStateData.data.stateToStateCertificationDetails.contactPersonFullName = `${this.statetoStateData.data.stateToStateCertificationDetails.contactPersonFirstName} ${this.statetoStateData.data.stateToStateCertificationDetails.contactPersonLastName}`;
    this.applicationReceivedDate = moment(
      this.statetoStateData.data.stateToStateSignatureSubmission.submissionDate
    ).format("MM-DD-YYYY");
    this.isLoading = false;
    const previewClass = document.querySelector(
      "state-to-state-form-preview-container"
    );
    if (previewClass) {
      (previewClass as HTMLElement)?.focus();
    }
    setTimeout(() => {
      this.getHeaderHeight();
    }, 1000);
  }

  public getUpdatedData() {
    this.getStatetoStateApplicationData();
  }

  public getApplicantId() {
    this.isUpdatePageNumber = false;
    if (this.formSelected === "application-form") {
      this.PDFApplicationPrintName = `State-to-state-application-${this.statetoStateData.code}`;
    }
  }

  public getStatetoStateApplicationData() {
    this.editedUserDetails = [];
    this.isLoading = true;
    let getAllEditedData: editChangesData[] = [];
    let temp: any;
    this.collapseIdx = "";
    stateToStateFormApi
      .getStateToStateApplicationForm(this.statetoStateApplicationId)
      .then((result: any) => {
        const userId = this.userDetails.userId;
        const userTask = result.data?.userTask;
        this.historys = userTask.sort((a: any, b: any) => {
          a.createdAt = moment(a.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          b.createdAt = moment(b.createdAt).format("MM-DD-YYYY HH:mm:ss:SSS");
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });

        // current userTask and findStatus
        const currentUserTask = userTask.filter(
          (task: any) => task.userId === userId
        );
        const mostRecentHistory = currentUserTask.sort((a: any, b: any) => {
          return moment(a.createdAt, "MM-DD-YYYY HH:mm:ss").diff(
            moment(b.createdAt, "MM-DD-YYYY HH:mm:ss")
          );
        });
        this.mostLastRecord = mostRecentHistory[mostRecentHistory.length - 1];
        result.data.createdAt = moment(result.data.createdAt).format(
          "MM-DD-YYYY"
        );
        this.historys.map((data: any) => {
          data.createdAt = moment
            .utc(data.createdAt)
            .local()
            .format("MM-DD-YYYY HH:mm A");
        });
        this.historys.reverse();
        this.statetoStateData = result.data;
        if (this.statetoStateData.submittedMode === "migration") {
          this.isMigrationForm = true;
        }
        this.applicantFullName = `${this.statetoStateData.data.stateToStateBasicDetails.firstName} ${this.statetoStateData.data.stateToStateBasicDetails.lastName}`;
        this.statetoStateData.data.stateToStateBasicDetails.fullName =
          this.applicantFullName;
        this.statetoStateData.data.stateToStateBasicDetails.otherFullName = `${this.statetoStateData.data.stateToStateBasicDetails.otherFirstName} ${this.statetoStateData.data.stateToStateBasicDetails.otherLastName}`;

        const fullAddress = utils.addressBuilder(
          this.statetoStateData.data,
          "stateToStateBasicDetails",
          "default"
        );
        this.statetoStateData.data.stateToStateBasicDetails.fullAddress =
          fullAddress;
        this.statetoStateData.data.stateToStateCertificationDetails.contactPersonFullName = `${this.statetoStateData.data.stateToStateCertificationDetails.contactPersonFirstName} ${this.statetoStateData.data.stateToStateCertificationDetails.contactPersonLastName}`;
        this.applicationReceivedDate = moment(
          this.statetoStateData.data.stateToStateSignatureSubmission
            .submissionDate
        ).format("MM-DD-YYYY");
        if (
          this.statetoStateData.progressStatus === STATUS.accepted ||
          this.statetoStateData.progressStatus === STATUS.notAccepted ||
          this.statetoStateData.progressStatus === STATUS.needMoreInfo
        ) {
          const mostRecentHistory = this.statetoStateData.userTask.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )[0];
          this.approveComment = this.statetoStateData.comment;
          this.approveStatus = this.statetoStateData.progressStatus;
        }
        this.statusCheck();
        if (
          this.statetoStateData.comment &&
          this.statetoStateData.progressStatus !== "NEED MORE INFO"
        ) {
          this.showCommentBoxWithDisable = true;
        } else {
          this.showCommentBoxWithDisable = false;
          this.showCommentBox = true;
        }
        this.isLoading = false;
        setTimeout(() => {
          this.getHeaderHeight();
        }, 1000);
        for (const property in this.statetoStateData.data) {
          if (this.statetoStateData.data["History"]) {
            this.statetoStateData.data["History"].map((historyData: any) => {
              for (const innerProperty in this.statetoStateData.data[
                property
              ]) {
                if (
                  historyData[innerProperty] &&
                  historyData[innerProperty].length
                ) {
                  temp = JSON.parse(JSON.stringify(historyData[innerProperty]));
                  temp.forEach((element: any) => {
                    if (this.filesEdit.includes(innerProperty)) {
                      element.oldValue = element.oldValue.map((data: any) => {
                        return data.originalFileName;
                      });
                      element.newValue = element.newValue.map((data: any) => {
                        return data.originalFileName;
                      });
                    }
                    if (
                      typeof element.oldValue === "object" &&
                      typeof element.newValue === "object"
                    ) {
                      element.oldValue = element.oldValue.length
                        ? element.oldValue.join(", ")
                        : element.oldValue;
                      element.newValue = element.newValue.length
                        ? element.newValue.join(", ")
                        : element.newValue;
                    }
                    temp = element;
                    switch (property) {
                      case "stateToStateBasicDetails":
                        temp.mainLabel = "Basic Details";
                        break;
                      case "stateToStateCertificationDetails":
                        temp.mainLabel = "Certification Details";
                        break;
                      default:
                        break;
                    }
                    if (getAllEditedData.length) {
                      getAllEditedData.push(temp);
                    } else {
                      getAllEditedData = [temp];
                    }
                  });
                }
              }
            });
          }
        }

        getAllEditedData.filter((currentValue, currentIndex) => {
          return getAllEditedData.indexOf(currentValue) !== currentIndex;
        });
        const unique = [
          ...new Set(
            getAllEditedData.map((a) => {
              return a.editDataTime + "/" + a.editorDetails.userId;
            })
          ),
        ];

        unique.forEach((splitUnique: string) => {
          const dateTime = splitUnique.split("/")[0];
          const userId = splitUnique.split("/")[1];
          getAllEditedData.forEach((data: editChangesData) => {
            if (
              data.editDataTime === dateTime &&
              data.editorDetails.userId === userId
            ) {
              if (this.editedUserDetails.length) {
                const editedIdx = this.editedUserDetails.findIndex(
                  (editDetail) => {
                    return (
                      editDetail.editorUserId === data.editorDetails.userId &&
                      editDetail.dateTime === data.editDataTime
                    );
                  }
                );
                if (editedIdx > -1) {
                  this.editedUserDetails[editedIdx].changesCount =
                    this.editedUserDetails[editedIdx].changesCount + 1;
                  if (this.editedUserDetails[editedIdx].data.length) {
                    const idx = this.editedUserDetails[
                      editedIdx
                    ].data.findIndex((a: any) => {
                      return a.mainLabel === data.mainLabel;
                    });

                    if (idx > -1) {
                      this.editedUserDetails[editedIdx].data[idx].changesData
                        ?.length
                        ? this.editedUserDetails[editedIdx]?.data[
                            idx
                          ]?.changesData?.push(data)
                        : null;
                    } else {
                      this.editedUserDetails[editedIdx].data.push({
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      });
                    }
                  }
                } else {
                  this.editedUserDetails.push({
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.statetoStateData.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  });
                }
              } else {
                this.editedUserDetails = [
                  {
                    editorUserId: data.editorDetails.userId,
                    data: [
                      {
                        mainLabel: data.mainLabel,
                        changesData: [data],
                      },
                    ],
                    dateTime: data.editDataTime,
                    editorName: data.editorDetails.name,
                    changesCount: 1,
                    comment: this.getComment(
                      this.statetoStateData.data,
                      data.editDataTime,
                      data.editorDetails.userId
                    ),
                  },
                ];
              }
            }
          });
        });
        this.editedUserDetails = this.editedUserDetails.sort(
          (a: any, b: any) => {
            a.dateTime = moment(a.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            b.dateTime = moment(b.dateTime).format("MM-DD-YYYY HH:mm:ss:SSS");
            return (
              new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
            );
          }
        );
        this.editedUserDetails.map((data: any) => {
          data.dateTime = moment(data.dateTime).format(
            "MM-DD-YYYY [|] HH:mm A"
          );
        });
        this.editedUserDetails.reverse();
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public getComment(data: any, dateTime: string, editorId: string) {
    let comment = "";
    for (const property in data) {
      if (property === "editedComments") {
        data[property].filter((data: any) => {
          if (
            data.dateTime === dateTime &&
            data.editorDetails.userId === editorId
          ) {
            comment = data.comment;
          }
        });
      }
    }
    return comment;
  }

  public formSelection(formSelect: string, initialLoad = false) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (
        this.formSelected !== formSelect &&
        !this.isFormEdit &&
        this.validForm
      ) {
        this.formSelected = formSelect;
        if (this.formType === "offline") {
          this.isUpdatePageNumber = true;
          if (formSelect === "application-form") {
            this.source =
              "https://dno579h3gzw0n.cloudfront.net/files_upload/ee770a79-a1db-4fee-b1af-ef49c0261c9e.pdf";
          }
        }
      }
    }, 50);
  }

  public tabSelection(tabSelect: string) {
    this.isFormValid = !this.isFormValid;
    setTimeout(() => {
      if (this.tabSelected !== tabSelect && this.validForm) {
        this.tabSelected = tabSelect;
        this.getHeaderHeight();
      }
    }, 50);
  }

  public isValidForm(event: any) {
    this.validForm = event;
  }

  public getHeaderHeight() {
    const getHeaderElement = document.querySelector(".header");
    const getSectionTabElement = document.querySelector(".form-tab");
    this.headerHeight = getHeaderElement?.clientHeight as number;
    if (this.isMobileView) {
      const getMainHeaderElementHeight = document.querySelector(
        ".mobile-nav-container"
      )?.clientHeight as number;
      this.headerHeight = this.headerHeight + getMainHeaderElementHeight;
      const getMobileElement = document.querySelector(".mobile-section-tab");
      this.mobileSectionTabHeight = getMobileElement?.clientHeight as number;
    }
    this.sectionTabHeight = getSectionTabElement?.clientHeight as number;
  }

  public reloadPage(event: any) {
    if (event) {
      this.getStatetoStateApplicationData();
    }
  }

  public statusUpdate(status: string) {
    this.approveStatus = status;
    this.toogleStatus = !this.toogleStatus;
  }

  public approveSubmit() {
    this.isSubmit = true;
    if (this.approveStatus) {
      this.isApproveStatus = true;
      const data = {
        id: this.statetoStateApplicationId,
        status: this.approveStatus.toLocaleUpperCase(),
        comment: this.approveComment,
      };
      commonService
        .approveApplication(data, "statetostate")
        .then(() => {
          this.isSubmit = false;
          this.getStatetoStateApplicationData();
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    } else {
      this.isApproveStatus = false;
    }
  }

  public isEditForm(event: any) {
    this.isFormEdit = event;
  }

  public statusCheck() {
    const status = utils.scoringSectionHideShow(
      this.statetoStateData.progressStatus,
      this.mostLastRecord.status
    );
    this.showCommentBox = status["showCommentBox"];
    this.showRubricTab = status["showRubricTab"];
    this.showCommentBoxWithDisable = status["showCommentBoxWithDisable"];
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public disableCommentandScoringSection(
    currentUserStatus: string,
    progressStatus: string,
    section: string
  ) {
    return utils.disableCommentandScoringSection(
      currentUserStatus,
      progressStatus,
      section
    );
  }
  public backUrl() {
    if (this.$route.query.isReport) {
      let filter = "";
      if (this.$route.query.ethnicity) {
        filter += `&ethnicity=${this.$route.query.ethnicity}`;
      }
      if (this.$route.query.gender) {
        filter += `&gender=${this.$route.query.gender}`;
      }
      if (this.$route.query.region) {
        filter += `&region=${this.$route.query.region}&regionId=${this.$route.query.regionId}`;
      }
      if (this.$route.query.age) {
        filter += `&age=${this.$route.query.age}&fromAge=${this.$route.query.fromAge}&toAge=${this.$route.query.toAge}`;
      }
      if (this.$route.query.education) {
        filter += `&education=${this.$route.query.education}`;
      }
      return `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}&type=${this.$route.query.type}&isReport=${this.$route.query.isReport}&fromDate=${this.$route.query.fromDate}&toDate=${this.$route.query.toDate}${filter}`;
    } else {
      return this.isPeerPreview
        ? `/reviewer-dashboard/peers-preview?id=${this.$route.query.id}`
        : "/reviewer-dashboard/state-to-state";
    }
  }
  public getPeerUpdatedData(event: boolean) {
    this.$emit("getPeerUpdatedData", true);
    if (event) {
      this.$emit("isUpdateForm", event);
    }
  }
}
