import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CheckboxOptions } from "@/models/checkbox-button/checkbox-button.interface";
import { utils } from "@/utils/utils";

@Component({
  name: "b-form-single-checkbox",
})
export default class SingleCheckboxComponent extends Vue {
  @Prop()
  public label!: string;
  @Prop()
  public name!: string;
  @Prop()
  public options!: CheckboxOptions[];
  @Prop()
  public value!: boolean[];
  @Prop()
  public formType!: string;
  @Prop()
  public editForm!: boolean;

  public selectedValue: boolean[] = [];
  public checkedData = "";
  public isDisable = false;

  public checked() {
    this.$emit("input", this.selectedValue);
  }
  public created() {
    this.selectedValue = this.value;
    this.isDisable = utils.disableField(this.editForm, this.formType);
  }
}
