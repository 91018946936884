import { render, staticRenderFns } from "./reviewer-peers-page.component.vue?vue&type=template&id=6ced8572"
import script from "./reviewer-peers-page.component.ts?vue&type=script&lang=ts&external"
export * from "./reviewer-peers-page.component.ts?vue&type=script&lang=ts&external"
import style0 from "./reviewer-peers-page.component.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports