import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CprsApplicationsData } from "@/models/cprs-applications/cprs-applications.interface";
import { CPRS_APPLICATIONS_QUERY } from "@/graphql/cprs-applications-query";

@Component({})
export default class CprsApplicationsComponent extends Vue {
  public cprsApplicationsData: CprsApplicationsData[] = [];
  public certificationIcon = {
    name: "",
    url: "",
  };
  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${CPRS_APPLICATIONS_QUERY}`,
      })
      .then((result) => {
        this.cprsApplicationsData = result.data.cprsApplications.data;
      });
  }

  public navigateForm(data: CprsApplicationsData) {
    this.$router.push(data.attributes.url);
  }
}
