import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import {
  CPRS_MAIN_CONTENT,
  OUR_INTRODUTION,
} from "@/graphql/main-content-and-introduction-query";
import {
  IntroductionData,
  MainContentData,
} from "@/models/main-content-and-introduction/main-content-and-introduction.interface";
import { utils } from "@/utils/utils";
import { docLibraryData } from "@/models/common-interface/common.interface";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";

@Component({
  components: {
    PreviewPopupComponent,
  },
})
export default class MainContentAndIntroductionComponent extends Vue {
  public mainContent: MainContentData[] = [];
  public ourIntroductionData: IntroductionData[] = [];
  public ourIntroductionImage = {
    name: "",
    url: "",
  };
  public ourIntroductionSubImage = {
    name: "",
    url: "",
  };
  public getAllDoc: docLibraryData[] = [];
  public showPreviewPopup = false;
  public previewFile: any[] = [];

  public async created() {
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });
    await this.$apollo
      .query({
        query: gql`query ${CPRS_MAIN_CONTENT}`,
      })
      .then((result) => {
        this.mainContent = result.data.cprsMainContents.data;
      });
    await this.$apollo
      .query({
        query: gql`query ${OUR_INTRODUTION}`,
      })
      .then((result) => {
        this.ourIntroductionData = result.data.ourIntroductions.data;
        this.ourIntroductionImage = {
          name: this.ourIntroductionData[0].attributes.image.data.attributes
            .name,
          url: this.ourIntroductionData[0].attributes.image.data.attributes.url,
        };
        this.ourIntroductionSubImage = {
          name: this.ourIntroductionData[0].attributes.sub_image.data.attributes
            .name,
          url: this.ourIntroductionData[0].attributes.sub_image.data.attributes
            .url,
        };
      });
  }

  public updated() {
    window.document.onclick = (e) => {
      if (e.target !== null) {
        const getElement: HTMLElement = e.target as HTMLElement;
        const tagId = getElement.id;
        const getDocElement: HTMLAnchorElement = document.getElementById(
          tagId
        ) as HTMLAnchorElement;
        if (getDocElement) {
          const docDetials = utils.filterDoc(this.getAllDoc, tagId);
          if (docDetials) {
            this.showPreviewPopup = true;
            this.previewFile = [
              {
                fileName: "",
                width: 0,
                height: 0,
                type: docDetials.mime,
                originalFileName: docDetials.name,
                url: docDetials.url,
              },
            ];
          }
        }
      }
    };
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }
}
