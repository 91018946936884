import { Component, Prop, Vue } from "vue-property-decorator";
import { flagApplicantApi } from "@/providers/apis/peers-flag-applicant";
import { filters } from "@/models/reviewer-part-one-page/reviewer-part-one.interface";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
@Component({
  name: "b-preview-form",
  components: {
    AppLoaderComponent,
  },
})
export default class FlagApplicantPopupComponent extends Vue {
  @Prop()
  public flagApplicantGrapql: any = {};
  @Prop()
  public flagPageData: any;
  @Prop() public getPeerApplicationLists: any;
  @Prop() public flagApplicantPopup: any;
  @Prop() public getPeerReportsAndApplicationList: any;
  @Prop() public peersData: any;
  public flagApplicantFormData: any = [];
  public flagApplicantNameGet: any = [];
  public flags: any = [];
  public messageInput: any = "";
  public errorMsgCheckBox = false;
  public flagAdminAction = false;
  public flagLengthEmpty = false;
  public disabledFlagButton = false;
  public nullcheckData = false;
  public flagHistoryData: any = [];
  public FlagCertificateRevoke!: number;
  public applicantFlagged = false;
  public flagApplicantModal = true;
  public revokeSuccessful = false;
  public FlagIsLoading = false;
  public peersDisableName = "";
  public created() {
    this.nullcheckData =
      this.flagPageData.flags == null || this.flagPageData.flags.lenght == 0
        ? true
        : false;
    this.flags =
      this.flagPageData.flags == null ? this.flags : this.flagPageData.flags;
    this.flagApplicantFormData =
      this.flagApplicantGrapql.data.peerFlagApplicant.data.attributes.form_fields.formInputs;
    this.flagHistoryApi();
    this.flagApplicantNameGet =
      this.flagApplicantFormData[0][0].propBind.options.filter((person: any) =>
        this.flags.includes(person.item)
      );
    if (this.flagPageData.flags == null && this.flags.length == 0) {
      this.disabledFlagButton = false;
    } else {
      this.disabledFlagButton = true;
    }
  }

  public revokeCertification(revokeCertification: any) {
    this.peersDisableName = revokeCertification;
    this.$bvModal.show("revoke-conformation-popup");
  }
  public flagHistoryApi() {
    const hhhh = this.flagPageData.id;
    flagApplicantApi.peersFlagHistory({ id: hhhh }).then((res) => {
      this.flagHistoryData = [];
      for (let index = 0; index < res.data.flags.length; index++) {
        res.data.flags[index]["flagsData"] =
          this.flagApplicantFormData[0][0].propBind.options.filter(
            (person: any) => res.data.flags[index].flags.includes(person.item)
          );
        this.flagHistoryData.push(res.data.flags[index]);
      }
    });
  }

  public flagApplicantPopupClose() {
    this.$bvModal.hide("flag-applicant-modal");
  }
  public checkBoxGroupValidation() {
    if (this.flagPageData.flags == null && this.flags.length == 0) {
      this.errorMsgCheckBox = true;
    } else {
      this.errorMsgCheckBox = false;
    }
  }
  public flagApplicantCheckBox() {
    this.flagApplicantNameGet =
      this.flagApplicantFormData[0][0].propBind.options.filter((person: any) =>
        this.flags.includes(person.item)
      );
    this.checkBoxGroupValidation();
    const flagAplicantEvery =
      this.flags.length === this.flagPageData.flags.length &&
      this.flags.every((val: any) => this.flagPageData.flags.includes(val));

    if (flagAplicantEvery) {
      this.disabledFlagButton = true;
    } else {
      this.disabledFlagButton = false;
    }
  }
  public cancelRevokePopup() {
    this.$bvModal.hide("revoke-conformation-popup");
  }
  public submitRevokeCetrificate() {
    this.$bvModal.hide("revoke-conformation-popup");
    this.FlagIsLoading = true;
    if (this.peersDisableName == "peersDisabled") {
      this.FlagIsLoading = true;
      flagApplicantApi
        .peersDisable({
          id: this.flagPageData.id,
          flagId: this.FlagCertificateRevoke,
          disable: !this.flagPageData.isDisabled,
        })
        .then((res) => {
          this.getPeerReportsAndApplicationList();
          if (res.status == 204) {
            setTimeout(() => {
              const peersDataIsDisabled = this.peersData.find(
                (item: any) => item.id === this.flagPageData.id
              );
              this.flagPageData.isDisabled = peersDataIsDisabled.isDisabled;
              this.FlagIsLoading = false;
            }, 3000);
          }
        })
        .catch((error) => {
          this.FlagIsLoading = false;
        });
    } else {
      flagApplicantApi
        .FlagCertificateRevoke({
          certificateNumber: this.flagPageData.certificateNumber,
          flagId: this.FlagCertificateRevoke,
        })
        .then((res) => {
          if (res.status == 204) {
            this.FlagIsLoading = false;
            this.revokeSuccessful = true;
            this.getPeerReportsAndApplicationList();
          }
        })
        .catch((error) => {
          this.FlagIsLoading = false;
        });
    }
  }
  public flahHistorybutton() {
    this.flagLengthEmpty = !this.flagLengthEmpty;
  }

  public flagApplicant() {
    this.checkBoxGroupValidation();
    this.$validator.validate().then((validate: any) => {
      if (validate) {
        if (this.flagApplicantModal) {
          if (this.flagPageData.flags == null && this.flags.length == 0) {
            this.errorMsgCheckBox = true;
          } else {
            this.FlagIsLoading = true;
            flagApplicantApi
              .peersFlag({
                id: this.flagPageData.id,
                flags: this.flags,
                comment: this.messageInput,
              })
              .then((res) => {
                if (res.status == 201) {
                  this.FlagIsLoading = false;
                  this.FlagCertificateRevoke = parseInt(res.headers.location);
                  this.flagApplicantModal = false;
                  this.applicantFlagged = true;
                  this.flagHistoryApi();
                }
                if (res.response.status == 500) {
                  this.FlagIsLoading = false;
                }
              })
              .catch((error) => {
                this.FlagIsLoading = false;
              });
          }
        }
      }
    });
  }

  public peersDisable(identifyName: any) {
    this.$bvModal.show("revoke-conformation-popup");
    this.peersDisableName = identifyName;
  }
}
