import { Component, Vue } from "vue-property-decorator";
import { loginApi } from "@/providers/apis/login";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { User } from "@/models/common-interface/common.interface";
import { utils } from "@/utils/utils";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import { appConfigService } from "@/providers/services/app/app-config";
@Component({ name: "login-page", components: { AppLoaderComponent } })
export default class LoginPageComponent extends Vue {
  public loginData = {
    username_email: "",
    password: "",
  };
  public forgetPassword = {
    oldPassword: "",
    newPassword: "",
  };
  public isLoading = false;
  public isInvalidUser = false;
  public activeField = "";
  public checkValidation = false;
  public loginType: any = "";
  public confirmPassword = "";
  public isconfirmPassValid = true;
  public isDuplicatePass = false;
  public oldPassIncorrect = false;
  public showandHidePassword = {
    loginPassword: false,
    oldPassword: false,
    newPassword: false,
    confirmPass: false,
  };
  public isEmailNotVerified = false;
  public isResend = false;

  constructor() {
    super();
  }

  public created() {
    this.loginType = this.$route.query ? this.$route.query.loginType : "";
    const getUserDetail: User = utils.getUserDetails();
    if (
      getUserDetail &&
      !this.$route.params.isTokenExpiry &&
      this.loginType !== "changePassword"
    ) {
      loginApi.getUser().then((userData: User) => {
        if (userData) {
          if (getUserDetail.roles && getUserDetail.roles.includes("REVIEWER")) {
            this.$router.push("/reviewer-dashboard/home");
          }
          if (getUserDetail.roles === null) {
            this.$router.push("/user-dashboard/home");
          }
        }
      });
    }
    if (localStorage.getItem("anonymousToken") == null) {
      this.anonymousTokenNull();
    }
  }
  public anonymousTokenNull() {
    loginApi
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  public forgetpasswordcheck() {
    this.$router.push({
      path: "/forgot-password",
    });
  }
  public onSubmit() {
    this.checkValidation = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.checkValidation = false;
        if (this.isResend && !this.isEmailNotVerified) {
          this.isResend = false;
          this.loginData = {
            username_email: "",
            password: "",
          };
          return;
        }
        this.isLoading = true;
        if (this.isEmailNotVerified) {
          const data = {
            email: this.loginData.username_email,
          };
          applicantDetialsApi.resendProfile(data).then(() => {
            this.isLoading = false;
            this.isEmailNotVerified = false;
            this.isResend = true;
          });
        } else {
          if (this.loginType === "changePassword") {
            if (
              this.forgetPassword.oldPassword ===
              this.forgetPassword.newPassword
            ) {
              this.isLoading = false;
              this.isDuplicatePass = true;
            } else {
              this.isDuplicatePass = false;
              if (this.forgetPassword.newPassword !== this.confirmPassword) {
                this.isconfirmPassValid = false;
                this.isLoading = false;
              } else {
                this.isconfirmPassValid = true;
              }
            }
            if (this.isconfirmPassValid && !this.isDuplicatePass) {
              loginApi
                .passwordUpdate(this.forgetPassword)
                .then(() => {
                  this.loginType = "";
                  this.$router.push("/login");
                  this.isLoading = false;
                  utils.deleteLocalStorage();
                })
                .catch(() => {
                  this.oldPassIncorrect = true;
                  this.isLoading = false;
                });
            }
          } else {
            loginApi
              .logInWithCredential(
                this.loginData.username_email,
                this.loginData.password,
                "credential"
              )
              .then((res) => {
                localStorage.setItem("access_token", res.access_token);
                localStorage.setItem(
                  "user_details",
                  JSON.stringify(res.userDetails)
                );
                this.isLoading = false;
                if (this.$route.params.isTokenExpiry) {
                  this.$router.go(-1);
                } else {
                  if (
                    this.$route.query.application ===
                    "certification-part-two-form"
                  ) {
                    this.$router.go(-1);
                  } else if (this.$route.query.application === "renewal-form") {
                    this.$router.go(-1);
                  } else if (
                    res.userDetails.roles === null ||
                    res.userDetails.roles === ""
                  ) {
                    this.$router.push("/user-dashboard");
                  } else if (res.userDetails.roles.includes("REVIEWER")) {
                    this.$router.push("/reviewer-dashboard");
                  }
                }
              })
              .catch((error) => {
                this.isLoading = false;
                if (
                  error?.response?.status === 404 ||
                  error?.response?.status === 400
                ) {
                  this.isInvalidUser = true;
                }
                if (
                  error?.response?.data?.status === 401 &&
                  error?.response?.data?.message === "Email not verified"
                ) {
                  this.isEmailNotVerified = true;
                }
              });
          }
        }
      }
    });
  }

  public activeInput(field: string) {
    this.activeField = field;
  }

  public valueCheck(field: string) {
    if (field === "email" && !this.loginData.username_email) {
      this.activeField = "";
    } else if (field === "password" && !this.loginData.password) {
      this.activeField = "";
    } else if (field === "old-password" && !this.forgetPassword.oldPassword) {
      this.activeField = "";
    } else if (field === "new-password" && !this.forgetPassword.newPassword) {
      this.activeField = "";
    } else if (field === "confirm-password" && !this.confirmPassword) {
      this.activeField = "";
    }
  }

  public showAndHide(inputField: string) {
    switch (inputField) {
      case "login":
        this.showandHidePassword.loginPassword = utils.UIUpdateUsingKey(
          this.showandHidePassword.loginPassword
        );
        break;
      case "old":
        this.showandHidePassword.oldPassword = utils.UIUpdateUsingKey(
          this.showandHidePassword.oldPassword
        );
        break;
      case "new":
        this.showandHidePassword.newPassword = utils.UIUpdateUsingKey(
          this.showandHidePassword.newPassword
        );
        break;
      case "confirm":
        this.showandHidePassword.confirmPass = utils.UIUpdateUsingKey(
          this.showandHidePassword.confirmPass
        );
        break;
      default:
        break;
    }
    const passwsordElement = document.getElementsByClassName(
      `password-${inputField}`
    )[0];

    const password =
      passwsordElement.getElementsByClassName("password-input")[0];
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
  }
}
