import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { AboutusData } from "./about-us.interface";
import { ABOUT_US_QUERY } from "@/graphql/about-us-query";

@Component
export default class AboutUsComponent extends Vue {
  public aboutUsData: AboutusData[] = [];
  public aboutusStoryImage = { name: "", url: "" };
  public aboutusHeaderImage = { name: "", url: "" };

  public async created() {
    this.$apollo
      .query({
        query: gql`query ${ABOUT_US_QUERY}`,
      })
      .then((result) => {
        this.aboutUsData = result.data.aboutUses.data;
        this.aboutusStoryImage = {
          name: this.aboutUsData[0].attributes.story_image.data.attributes.name,
          url: this.aboutUsData[0].attributes.story_image.data.attributes.url,
        };
        this.aboutusHeaderImage = {
          name: this.aboutUsData[0].attributes.header_image.data.attributes
            .name,
          url: this.aboutUsData[0].attributes.header_image.data.attributes.url,
        };
      });
  }
}
