import { LETTER_OF_REFERENCE_QUERY } from "@/graphql/letter-of-reference-query";
import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import {
  CprsLetterOfReference,
  FormFieldContents,
  FormNavbar,
} from "@/models/letter-of-reference/letter-of-reference.interface";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import { letterOfReferenceFormApi } from "@/providers/apis/letter-of-reference";
import { utils } from "@/utils/utils";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import MultiRadioButton from "../multi-radio-button/multi-radio-button.component.vue";
import moment from "moment";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "letter-of-reference",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-multi-radio": MultiRadioButton,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class LetterOfReference extends Vue {
  public applicationDetials: any;
  public applicationData: Array<CprsLetterOfReference> = [];
  public applicationNavData: FormNavbar[] = [];
  public formData: FormFieldContents[] = [];
  public formPagination = 0;
  public formName = "letterOfReferenceReferenceDetails";
  public referenceOfInformation: any;
  public isNavLoad = false;
  public isValid = false;
  public signatureInvalid = false;
  public isScroll = false;
  public tokenEmailArray: any = [];
  public token: any;
  public routeQuery: any;
  public currentForm = "";
  public referenceData: any;
  public saveDataDraft = false;
  public partOneEmailError: any;
  public formTitle = {
    referenceTitle: "",
    referenceSubTitle: "",
  };
  public letterOfReferencePayload: any = {
    letterOfReferenceReferenceDetails: {
      referenceFirstName: "",
      referenceLastName: "",
      phoneNumber: "",
      emailAddress: "",
      agencyOrganization: "",
      title: "",
      credentials: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
    },
    letterOfReferenceApplicantDetails: {
      applicantFirstName: "",
      applicantLastName: "",
      relationshipWithApplicant: "",
      natureOfWork: "",
      applicantKnowledge: "",
      applicantAdditionalInformation: "",
    },
    letterOfReferenceSignatureAndSubmission: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public isLoading = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidForm = false;
  public errorMessage = "";

  public async created() {
    let payloadStorage: any = localStorage.getItem("letterOfReferencePayload");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.letterOfReferencePayload = { ...payloadStorage };
    }
    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.routeQuery = this.$route.query;
    this.token = this.routeQuery?.token;
    if (this.token) {
      this.isLoading = true;
      letterOfReferenceFormApi
        .getRenewalApplicationForm(this.token)
        .then((result) => {
          // Applicant Details From APU
          this.applicationDetials =
            result.data.data.certificationPartTwoApplicationForm;
          if (this.applicationDetials) {
            this.letterOfReferencePayload.letterOfReferenceApplicantDetails.applicantFirstName =
              this.applicationDetials.firstName;
            this.letterOfReferencePayload.letterOfReferenceApplicantDetails.applicantLastName =
              this.applicationDetials.lastName;
          }
          // Refrence Detial from API
          const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
          const emailData = decodeToken.email;
          this.referenceOfInformation =
            result.data.data.certificationPartTwoRefererenceInformation;
          this.referenceOfInformation.map((data: any) => {
            if (data.referenceEmailAddress == emailData) {
              this.referenceData = data;
              this.letterOfReferencePayload.letterOfReferenceReferenceDetails.referenceFirstName =
                this.referenceData.firstName;
              this.letterOfReferencePayload.letterOfReferenceReferenceDetails.referenceLastName =
                this.referenceData.lastName;
              this.letterOfReferencePayload.letterOfReferenceReferenceDetails.emailAddress =
                this.referenceData.referenceEmailAddress;
              this.letterOfReferencePayload.letterOfReferenceReferenceDetails.agencyOrganization =
                this.referenceData.agencyOrganization;
              this.letterOfReferencePayload.letterOfReferenceReferenceDetails.phoneNumber =
                this.referenceData.referencePhoneNumber;
              localStorage.setItem(
                "letterOfReferencePayload",
                JSON.stringify(this.letterOfReferencePayload)
              );
            }
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error, true);
          this.isLoading = false;
        });
    }
    this.$apollo
      .query({
        query: gql`query ${LETTER_OF_REFERENCE_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.cprsLetterOfReferences.data;
        this.formTitle = {
          referenceTitle: this.applicationData[0].attributes.referenceTitle,
          referenceSubTitle:
            this.applicationData[0].attributes.referenceSubTitle,
        };
        this.applicationNavData =
          this.applicationData[0].attributes.referenceNavBar;
        this.formData = this.applicationData[0].attributes.referenceFormField;
      });
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "letterOfReferencePayload",
      JSON.stringify(this.letterOfReferencePayload)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  onChangeInput() {
    localStorage.setItem(
      "letterOfReferencePayload",
      JSON.stringify(this.letterOfReferencePayload)
    );
  }

  public previousButton() {
    if (this.formPagination !== 0) {
      window.scrollTo(0, 0);
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "letterOfReferenceReferenceDetails";
      } else if (this.formPagination == 1) {
        this.formName = "letterOfReferenceApplicantDetails";
      } else if (this.formPagination == 2) {
        this.formName = "letterOfReferenceSignatureAndSubmission";
      }
    }
  }

  public fieldUpdateReference(inputName: string) {
    const nameNotVisiable = [
      "referenceFullName",
      "applicantFullName",
      "fullAddress",
    ];
    return nameNotVisiable.includes(inputName);
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.$validator.validate().then((validate) => {
      let payloadStorage: any = localStorage.getItem(
        "letterOfReferencePayload"
      );
      this.letterOfReferencePayload.letterOfReferenceReferenceDetails.state =
        this.letterOfReferencePayload.letterOfReferenceReferenceDetails.state.toLocaleUpperCase();
      if (
        this.letterOfReferencePayload.letterOfReferenceSignatureAndSubmission
          .isSignature
      ) {
        this.signatureInvalid = false;
      } else {
        this.signatureInvalid = true;
      }
      if (payloadStorage) {
        payloadStorage = JSON.parse(payloadStorage);
        this.letterOfReferencePayload = { ...payloadStorage };
      }
      if (validate && !this.signatureInvalid) {
        this.checkValidForm = false;
        this.isLoading = true;
        window.scrollTo(0, 0);
        letterOfReferenceFormApi
          .postRenewalApplicationForm(this.token, this.letterOfReferencePayload)
          .then(() => {
            const someData = {
              title: "Certified Peer Recovery Specialist Application",
              SubTitle: "Letter Of Reference",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            window.scrollTo(0, 0);
            localStorage.removeItem("letterOfReferencePayload");
            this.isLoading = false;
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          const yOffset = 350;
          const scrollTop =
            el.getBoundingClientRect().top + window.pageYOffset - yOffset;
          window.scrollTo({ top: scrollTop, behavior: "smooth" });
        }
      }
    });
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
}
