var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"admin-home-main-container"},[_c('div',{staticClass:"admin-home-container"},[_c('h3',{staticClass:"heading"},[_vm._v("CPRS Dashboard")]),_c('div',{staticClass:"application-container card-container row"},_vm._l((_vm.applicationDashboard),function(applicationStatus,index){return _c('div',{key:index,staticClass:"card application-status-card col-5"},[_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(applicationStatus.applicationName === "partOne" ? "Part One Applications" : applicationStatus.applicationName === "partTwo" ? "Part Two Applications" : applicationStatus.applicationName === "renewal" ? "Renewal Applications" : "State to State Applications")+" ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"status-div received-container",on:{"click":function($event){return _vm.filterData(applicationStatus.applicationName, 'RECEIVED')}}},[_vm._m(0,true),_c('div',{staticClass:"status-count status-Received"},[_c('span',[_vm._v(_vm._s(applicationStatus.received))])])]),_c('div',{staticClass:"status-div need-more-container",on:{"click":function($event){return _vm.filterData(applicationStatus.applicationName, 'NEED MORE INFO')}}},[_vm._m(1,true),_c('div',{staticClass:"status-count status-info"},[_c('span',[_vm._v(_vm._s(applicationStatus.needMoreInfo))])])]),(
              applicationStatus.applicationName !== 'partTwo' &&
              applicationStatus.applicationName !== 'renewal' &&
              applicationStatus.applicationName !== 'stateToState'
            )?_c('div',{staticClass:"status-div graded-container",on:{"click":function($event){return _vm.filterData(applicationStatus.applicationName, 'PROCESSING')}}},[_vm._m(2,true),_c('div',{staticClass:"status-count status-greded"},[_c('span',[_vm._v(_vm._s(applicationStatus.processing))])])]):_vm._e(),_c('div',{staticClass:"status-div accepted-container",on:{"click":function($event){return _vm.filterData(applicationStatus.applicationName, 'ACCEPTED')}}},[_vm._m(3,true),_c('div',{staticClass:"status-count status-accepted"},[_c('span',[_vm._v(_vm._s(applicationStatus.accepted))])])]),_c('div',{staticClass:"status-div not-accepted-container",on:{"click":function($event){return _vm.filterData(applicationStatus.applicationName, 'NOT ACCEPTED')}}},[_vm._m(4,true),_c('div',{staticClass:"status-count status-rejected"},[_c('span',[_vm._v(_vm._s(applicationStatus.notAccepted))])])])])])}),0)]),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'reviewer-home-error-popup',"errorMessage":_vm.errorMessage},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"status-label"},[_c('span',[_c('img',{staticClass:"received-icon",attrs:{"src":require("../../assets/icons/received-icon.svg")}}),_c('p',[_vm._v("Received")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"status-label"},[_c('span',[_c('img',{staticClass:"need-more-icon",attrs:{"src":require("../../assets/icons/need-more-info.svg")}}),_c('p',[_vm._v("Need More Info")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"status-label"},[_c('span',[_c('img',{staticClass:"graded-icon",attrs:{"src":require("../../assets/icons/graded-icon.svg")}}),_c('p',[_vm._v("Processing")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"status-label"},[_c('span',[_c('img',{staticClass:"accepted-icon",attrs:{"src":require("../../assets/icons/accepted-icon.svg")}}),_c('p',[_vm._v("Accepted")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"status-label"},[_c('span',[_c('img',{staticClass:"not-acceted-icon",attrs:{"src":require("../../assets/icons/not-accepted-icon.svg")}}),_c('p',[_vm._v("Not Accepted")])])])
}]

export { render, staticRenderFns }