import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { FOOTER_METADATA_QUERY, FOOTER_QUERY } from "@/graphql/footer-query";
import { FooterMenus, FooterMetadata } from "./footer.interface";

@Component({})
export default class CprsFooterComponent extends Vue {
  public footerMenus: FooterMenus[] = [];
  public footerMetadata: FooterMetadata[] = [];
  public footerTNCertifiedLogoUrl = "";
  public footerlogoUrl = "";
  public footerLogo = {
    name: "",
    url: "",
  };
  public footerTNCertifiedLogo = {
    name: "",
    url: "",
  };

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${FOOTER_QUERY}`,
      })
      .then((result) => {
        this.footerMenus = result.data.footerMenus.data;
      });

    await this.$apollo
      .query({
        query: gql`query ${FOOTER_METADATA_QUERY}`,
      })
      .then((result) => {
        this.footerMetadata = result.data.footerMetadatas.data;
        this.footerlogoUrl = this.footerMetadata[0].attributes?.logo_url;
        this.footerTNCertifiedLogoUrl =
          this.footerMetadata[0].attributes?.TN_certified_logo_url;
        if (this.footerMetadata[0].attributes.logo.data) {
          this.footerLogo = {
            name: this.footerMetadata[0].attributes.logo.data.attributes.name,
            url: this.footerMetadata[0].attributes.logo.data.attributes.url,
          };
        }
        if (this.footerMetadata[0].attributes.TN_certified_logo.data) {
          this.footerTNCertifiedLogo = {
            name: this.footerMetadata[0].attributes.TN_certified_logo.data
              .attributes.name,
            url: this.footerMetadata[0].attributes.TN_certified_logo.data
              .attributes.url,
          };
        }
      });
  }

  public navigator(url: string, slug: string) {
    if (this.$router.currentRoute.path === url) {
      window.scrollTo(0, 0);
    } else {
      if (url.includes("https://")) {
        window.location.href = url;
      } else {
        this.$router.push(url).catch(() => {
          //todo
        });
      }
    }
  }
}
