export const CPRS_CERTIFICATION_APPLICATION_QUERY = `CprsCertificationApplicationForms {  
    cprsCertificationApplicationForms(sort: "id:ASC"){
        data{
            id
            attributes{
                certificationTitle
                certificationSubTitle
                certificationNavbar{
                    id
                    name
                    slug
                }
                certification_form_fields{
                    id
                    parent_slug
                    fields
                }
            }
        }
    }
}`;
